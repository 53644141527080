const isSmoothScrollSupported = () => {
    var supports = false
    try {
        var div = document.createElement("div")
        div.scrollTo({
            top: 0,
            get behavior() {
                supports = true
                return "smooth"
            },
        })
    } catch (err) {}
    return supports
}

const scrollTo = id => {
    if (!document.getElementById(id)) return

    const y =
        window.scrollY +
        document.getElementById(id).getBoundingClientRect().top -
        (document.getElementById("header")?.getBoundingClientRect().height || 0)
    if (isSmoothScrollSupported()) {
        window.scrollTo({ top: y, behavior: "smooth" })
    } else window.scrollTo(0, y)
}

export { scrollTo, isSmoothScrollSupported }
