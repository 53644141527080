import {
    Background,
    BackgroundColor,
    Container,
    ShadowCard,
    Spacer,
    SpacerVariant,
    backgroundGreyScaleColorsOptions,
} from "../components/layout"
import { Repeater, types } from "react-bricks/frontend"
import Typography, { TypographyElement } from "../Typography"

import React from "react"

interface TextLinksShadowCardProps {
    background: { color: BackgroundColor } | BackgroundColor
    text: TypographyElement[]
}

const TextLinksShadowCard: types.Brick<TextLinksShadowCardProps> = ({ background }) => {
    const bgColor = typeof background === "object" ? background.color : background

    return (
        <Background color={bgColor}>
            <Container>
                <Spacer variant={SpacerVariant.Medium} />
                <ShadowCard className="space-y-5 sm:space-y-10">
                    <Typography allowList={["h4", "bold"]} />
                    <div className="space-y-5 sm:space-y-7">
                        <Repeater propName="repeater" />
                    </div>
                </ShadowCard>
                <Spacer variant={SpacerVariant.Medium} />
            </Container>
        </Background>
    )
}

TextLinksShadowCard.schema = {
    name: "TextLinksShadowCard",
    label: "Text Links Shadow Card",
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "TextLink",
                    label: "Text Link",
                    min: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "background",
            label: "Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: backgroundGreyScaleColorsOptions,
                display: types.OptionsDisplay.Color,
            },
        },
    ],
    getDefaultProps: (): TextLinksShadowCardProps => ({
        background: { color: BackgroundColor.White },
        text: [
            {
                type: "h4",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt:",
                        bold: true,
                    },
                ],
            },
        ],
    }),
}

export default TextLinksShadowCard
