import client from "../../utils/client"
import { useClientState } from "../../context/client"
import { useMutationWithErrorHandling } from "../use-query-with-error-handling"
import { useQueryClient } from "@tanstack/react-query"
import { useQueryLoading } from "../use-query-loading"

const useQueryCustomerId = loadingOptions => {
    const { customer_id, customerWeakToken } = useClientState()

    return useQueryLoading(
        ["getCustomerId", { token: customerWeakToken }],
        () => client.getCustomerId(),
        {
            enabled: !!customerWeakToken && !customer_id,
        },
        loadingOptions
    )
}

const useQueryCustomer = (queryOptions, loadingOptions) => {
    const { customer_id } = useClientState()

    return useQueryLoading(
        ["getCustomer", { id: customer_id }],
        () => client.getCustomer(customer_id),
        {
            enabled: !!customer_id,
            onError: error => {
                if (customer_id === "false") client.logout() // if customer is archived, API returns "false" as customer_id
                if (error.status === 403) client.config.customer_id = undefined
            },
            ...queryOptions,
        },
        loadingOptions
    )
}

const useMutationCustomer = token => {
    const queryClient = useQueryClient()
    const { customer_id } = useClientState()

    return useMutationWithErrorHandling(body => client.updateCustomer(customer_id, body, token), {
        onSuccess: data => queryClient.setQueryData(["getCustomer", { id: customer_id }], data),
    })
}

const usePostBankAccount = () => {
    const queryClient = useQueryClient()
    const { customer_id } = useClientState()

    return useMutationWithErrorHandling(body => client.createBankAccount(customer_id, body), {
        onSuccess: () => queryClient.invalidateQueries(["getCustomer", { id: customer_id }]),
    })
}

const useMutationBankAccount = () => {
    const queryClient = useQueryClient()
    const { customer_id } = useClientState()

    return useMutationWithErrorHandling(({ id, body }) => client.updateBankAccount(customer_id, id, body), {
        onSuccess: () => queryClient.invalidateQueries(["getCustomer", { id: customer_id }]),
    })
}

const useRemoveBankAccount = () => {
    const queryClient = useQueryClient()
    const { customer_id } = useClientState()

    return useMutationWithErrorHandling(id => client.removeBankAccount(customer_id, id), {
        onSuccess: () => queryClient.invalidateQueries(["getCustomer", { id: customer_id }]),
    })
}

const useRequestPaymentToken = () => {
    const { customer_id } = useClientState()

    return useMutationWithErrorHandling(body => client.requestPaymentToken(customer_id, body))
}

const useConfirmPaymentToken = () => {
    const { customer_id } = useClientState()

    return useMutationWithErrorHandling(body => client.confirmPaymentToken(customer_id, body))
}

export {
    useQueryCustomerId,
    useQueryCustomer,
    useMutationCustomer,
    usePostBankAccount,
    useMutationBankAccount,
    useRemoveBankAccount,
    useRequestPaymentToken,
    useConfirmPaymentToken,
}
