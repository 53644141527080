const waitFor = (test, callback) => {
    let interval

    const check = () => {
        if (test()) {
            clearInterval(interval)
            callback()
        }
    }

    check()
    interval = setInterval(check, 10)
}

export { waitFor }
