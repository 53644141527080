import { Color, TypographyColor } from "../components/typography"
import { Container, Spacer, SpacerVariant } from "../components/layout"
import ImageEditor, { AspectRatio } from "../Image"
import Typography, { TypographyElement } from "../Typography"

import { Pictograms } from "../../types"
import React from "react"
import { types } from "react-bricks/frontend"

interface QuoteBrickProps {
    headline: TypographyElement[]
    text: TypographyElement[]
}

const QuoteBrick: types.Brick<QuoteBrickProps> = () => {
    return (
        <div className="relative">
            <div className="hidden sm:block">
                <ImageEditor aspectRatio={AspectRatio["5/2"]} maxWidth={1920} className="w-full" propName="image" />
            </div>
            <div className="sm:hidden">
                <ImageEditor
                    aspectRatio={AspectRatio["5/4"]}
                    maxWidth={768}
                    className="w-full"
                    propName="image_mobile"
                />
            </div>
            <Container>
                <div className="absolute hidden sm:top-9 sm:block md:top-[46px] lg:top-[106px] xl:top-[239px]">
                    <div className="sm:flex">
                        <i className="lavita-icon md:text-10 text-[35px] text-white lg:text-[50px]">
                            {Pictograms.quote_outline}
                        </i>
                        <div className="sm:ml-3 sm:mt-3 sm:max-w-[350px] md:max-w-[462px] lg:max-w-[611px] xl:max-w-[644px]">
                            <Color color={TypographyColor.White}>
                                <Typography block="quote-text" allowList={["italic"]} propName="headline" />
                                <Typography block="quote-text-small" allowList={["bold"]} propName="text" />
                            </Color>
                        </div>
                    </div>
                </div>
            </Container>
            <div className="sm:hidden">
                <Container>
                    <Spacer variant={SpacerVariant.Small} />
                    <i className="lavita-icon mb-[14px] text-[35px] leading-none text-border-copperfield">
                        {Pictograms.quote_outline}
                    </i>
                    <Typography block="quote-text" allowList={["italic", "small"]} propName="headline" />
                    <Typography block="quote-text-small" allowList={["bold"]} propName="text" />
                    <Spacer />
                </Container>
            </div>
        </div>
    )
}

QuoteBrick.schema = {
    name: "QuoteBrick",
    label: "Quote Gerd Truntsschka",
    getDefaultProps: (): QuoteBrickProps => ({
        headline: [
            {
                type: "h3",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consetetur sadipscing.",
                    },
                ],
            },
        ],
        text: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc placerat sagittis faucibus.",
                    },
                ],
            },
        ],
    }),
}

export default QuoteBrick
