const localStorageMock = () => {
    let store = {}

    return {
        getItem(key) {
            return store[key]
        },

        setItem(key, value) {
            store[key] = value
        },

        clear() {
            store = {}
        },

        removeItem(key) {
            delete store[key]
        },

        getAll() {
            return store
        },

        length: Object.keys(store).length,

        key(index) {
            return Object.keys(store)[index]
        },
    }
}

export { localStorageMock }
