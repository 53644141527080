import React, { ReactNode } from "react"

import { PageContext as PageContextInterface } from "../types/react-bricks"

const PageContext = React.createContext<PageContextInterface | undefined>(undefined)

const usePageContext = () => {
    const context = React.useContext(PageContext)
    if (context === undefined) {
        throw new Error("usePageContext must be used within a PageProvider")
    }

    return context
}

interface PageProviderProps {
    children: ReactNode
    value: PageContextInterface
}

const PageProvider = ({ children, value }: PageProviderProps) => {
    return <PageContext.Provider value={value}>{children}</PageContext.Provider>
}

export { PageProvider, usePageContext }
