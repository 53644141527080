import React from "react"
import { RenderElementProps } from "slate-react"

const H4 = ({ children, attributes }: RenderElementProps) => {
    return (
        <h4
            {...attributes}
            className="mb-4 text-xl last:mb-0 sm:mb-5 sm:text-3xl sm:leading-3xl lg:text-5xl lg:leading-5xl"
        >
            {children}
        </h4>
    )
}

export default H4
