const initialState = {
    showOverlay: false,
    content: null,
    header: null,
    isVideo: false,
    error: null,
    overlay: null,
    disableClose: false,
}

const OverlayReducer = (initialState, action) => {
    switch (action.type) {
        case "OPEN_OVERLAY":
            return {
                ...initialState,
                showOverlay: !action.payload.overlay,
                content: action.payload.content,
                header: action.payload.header,
                isVideo: !!action.payload.isVideo,
                disableOnClickOutside: !!action.payload.disableOnClickOutside,
                overlay: action.payload.overlay || null,
                disableClose: !!action.payload.disableClose,
            }
        case "CLOSE_OVERLAY":
            return {
                ...initialState,
                showOverlay: false,
                content: null,
                header: null,
                overlay: null,
                disableClose: false,
            }
        case "UPDATE_HEADER":
            return {
                ...initialState,
                header: action.payload.header,
            }
        case "ERROR":
            return {
                ...initialState,
                error: action.error,
            }

        default:
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}

export { OverlayReducer, initialState }
