import React from "react"
import { RenderElementProps } from "slate-react"

const QuoteText = ({ children, attributes }: RenderElementProps) => {
    return (
        <p
            {...attributes}
            className="mb-7 text-xl sm:text-lg md:text-3xl md:leading-3xl lg:mb-10 lg:text-5xl lg:leading-5xl"
        >
            {children}
        </p>
    )
}

export default QuoteText
