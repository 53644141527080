import ImageEditor, { AspectRatio } from "../Image"
import Typography, { TypographyElement } from "../Typography"

import { Color } from "../components/typography"
import { GridColumns } from "../components/layout"
import { IconText } from "../components/icon-text"
import { Pictograms } from "../../types"
import React from "react"
import classNames from "classnames"
import { types } from "react-bricks/frontend"

export enum ColumnQuoteBackgroundColor {
    "White" = "#fff",
    "Grey" = "#f2f0ee",
}

export interface ColumnQuoteProps {
    quoteTextTop: TypographyElement[]
    quoteTextBottom: TypographyElement[]
    showImage: boolean
    columns: GridColumns
    background?: ColumnQuoteBackgroundColor
    index?: number
}

const ColumnQuote: types.Brick<ColumnQuoteProps> = ({
    index,
    showImage,
    columns,
    background = ColumnQuoteBackgroundColor.Grey,
}) => {
    let isOneColumn = columns === GridColumns.One

    return (
        <div
            key={index}
            className={classNames("rounded-base p-3.75", {
                "bg-background-tide": background === ColumnQuoteBackgroundColor.Grey,
                "bg-white": background === ColumnQuoteBackgroundColor.White,
                "grid grid-cols-[60px_1fr] gap-x-3.5": showImage,
                "sm:grid-cols-[200px_1fr] sm:grid-rows-[1fr_auto_auto_auto_1fr] sm:gap-x-10 sm:p-7.5 md:grid-cols-[250px_1fr] md:gap-x-16 lg:grid-cols-[300px_1fr] lg:gap-x-20 xl:p-15":
                    showImage && isOneColumn,
                "sm:grid-cols-[80px_1fr] sm:gap-x-6 lg:grid-cols-[100px_1fr]": showImage && !isOneColumn,
                "sm:p-15 xl:p-25": !showImage && isOneColumn,
                "sm:p-5 lg:p-7.5 xl:p-15": !isOneColumn,
            })}
        >
            <IconText
                icon={Pictograms.quote_outline}
                className={classNames("", {
                    "col-span-2": showImage,
                    "sm:col-span-1 sm:col-start-2 sm:row-start-2": showImage && isOneColumn,
                })}
            />
            <div
                className={classNames("mb-6 mt-3.5 sm:mb-7 sm:mt-5", {
                    "col-span-2": showImage,
                    "sm:col-span-1 sm:col-start-2 sm:row-start-3": showImage && isOneColumn,
                })}
            >
                <Color>
                    <Typography
                        block="copytext-small"
                        allowList={["small", "sup", "link", "bold", "italic"]}
                        propName="quoteTextTop"
                    />
                </Color>
            </div>
            {showImage && (
                <div
                    className={classNames({
                        "sm:col-start-1 sm:row-span-5": isOneColumn,
                    })}
                >
                    <div className="aspect-h-1 aspect-w-1">
                        <ImageEditor aspectRatio={AspectRatio["1/1"]} maxWidth={300} className="block rounded-full" />
                    </div>
                </div>
            )}
            <div
                className={classNames({
                    "flex items-center sm:col-start-2": showImage,
                    "sm:row-start-4 sm:items-start": showImage && isOneColumn,
                })}
            >
                <Color>
                    <Typography
                        block="copytext-small"
                        allowList={["small", "sup", "link", "bold"]}
                        propName="quoteTextBottom"
                    />
                </Color>
            </div>
        </div>
    )
}

ColumnQuote.schema = {
    name: "ColumnQuote",
    label: "Column Quote",
    hideFromAddMenu: true,
    sideEditProps: [
        {
            name: "showImage",
            label: "Image",
            type: types.SideEditPropType.Boolean,
        },
    ],
    getDefaultProps: (): Omit<ColumnQuoteProps, "columns"> => ({
        showImage: false,
        quoteTextTop: [
            {
                type: "paragraph",
                children: [
                    {
                        italic: true,
                        text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
                    },
                ],
            },
        ],
        quoteTextBottom: [
            {
                type: "paragraph",
                children: [
                    {
                        bold: true,
                        text: "Lorem ipsum",
                    },
                    {
                        text: " dolor sit amet",
                    },
                ],
            },
        ],
    }),
}

export default ColumnQuote
