import React from "react"
import { RenderLeafProps } from "slate-react"

const Big = ({ children, attributes }: RenderLeafProps) => {
    return (
        <span {...attributes} className="text-xl font-semibold sm:text-3xl md:text-5xl">
            {children}
        </span>
    )
}

export default Big
