import { API } from "./api"
import { Configuration } from "./config"

export class SDK {
    constructor(options, reAuth) {
        this.reAuthFn = reAuth
        this.config = new Configuration(options, options?.storage)
        this.api = new API(options, this.config, reAuth)
    }

    async login(body, language) {
        return await this.api.auth.login(body, language)
    }

    logout() {
        return this.api.auth.logout()
    }

    async reAuth() {
        return await this.reAuthFn()
    }

    async getCustomerId() {
        const response = await this.api.get("/auth/customer", "customerWeakToken")
        this.config.customer_id = response.customer_id
        return response
    }

    async createCustomer(body, language) {
        return await this.api.post("/customer", "consumerToken", language, body)
    }

    async getCustomer(customer_id, params, language) {
        return await this.api.get(
            `/customer/${customer_id}`,
            this.config.customerWeakToken ? "customerWeakToken" : "consumerToken",
            language,
            params
        )
    }

    async updateCustomer(customer_id, body, token, params, language) {
        if (params?.access_token && this.config.customerStrongToken) {
            params.access_token = undefined
        }
        return await this.api.patch(
            `/customer/${customer_id}`,
            token || (this.config.customerStrongToken ? "customerStrongToken" : "consumerToken"),
            language,
            body,
            params
        )
    }

    async getCustomerReferrer(customer_id, params, language) {
        try {
            await this.api.get(
                `/customer/${customer_id}/referrer`,
                this.config.customerWeakToken ? "customerWeakToken" : "consumerToken",
                language,
                params
            )
            return { referrer: true }
        } catch (error) {
            if (error.status === 404) {
                return { referrer: false }
            }
            throw error
        }
    }

    async updateCustomerReferrer(customer_id, body, token, params, language) {
        return await this.api.put(
            `/customer/${customer_id}/referrer`,
            token || (this.config.customerWeakToken ? "customerWeakToken" : "consumerToken"),
            language,
            body,
            params
        )
    }

    async createAddress(customer_id, body, params, language) {
        return await this.api.post(
            `/customer/${customer_id}/address`,
            this.config.customerStrongToken ? "customerStrongToken" : "consumerToken",
            language,
            body,
            params
        )
    }

    async getAddress(customer_id, address_id, params, language) {
        return await this.api.get(
            `/customer/${customer_id}/address/${address_id}`,
            this.config.customerWeakToken ? "customerWeakToken" : "consumerToken",
            language,
            params
        )
    }

    async updateAddress(customer_id, address_id, body, params, language) {
        return await this.api.put(
            `/customer/${customer_id}/address/${address_id}`,
            this.config.customerStrongToken ? "customerStrongToken" : "consumerToken",
            language,
            body,
            params
        )
    }

    async removeAddress(customer_id, address_id, params, language) {
        return await this.api.delete(
            `/customer/${customer_id}/address/${address_id}`,
            this.config.customerStrongToken ? "customerStrongToken" : "consumerToken",
            language,
            params
        )
    }

    async createBankAccount(customer_id, body, params, language) {
        return await this.api.post(
            `/customer/${customer_id}/payment/bank_account`,
            this.config.customerStrongToken ? "customerStrongToken" : "consumerToken",
            language,
            body,
            params
        )
    }

    async updateBankAccount(customer_id, bank_account_id, body, params, language) {
        return await this.api.patch(
            `/customer/${customer_id}/payment/bank_account/${bank_account_id}`,
            this.config.customerStrongToken ? "customerStrongToken" : "consumerToken",
            language,
            body,
            params
        )
    }

    async removeBankAccount(customer_id, bank_account_id, params, language) {
        return await this.api.delete(
            `/customer/${customer_id}/payment/bank_account/${bank_account_id}`,
            this.config.customerStrongToken ? "customerStrongToken" : "consumerToken",
            language,
            params
        )
    }

    async requestPaymentToken(customer_id, body, params, language) {
        return await this.api.post(
            `/customer/${customer_id}/payment/token/request`,
            "customerWeakToken",
            language,
            body,
            params
        )
    }

    async confirmPaymentToken(customer_id, body, params, language) {
        return await this.api.post(
            `/customer/${customer_id}/payment/token/confirm`,
            "customerWeakToken",
            language,
            body,
            params
        )
    }

    async createCustomerTracking(body, language) {
        return await this.api.post("/customer/tracking", "consumerToken", language, body)
    }

    async getCustomerAggregation(body) {
        return await this.api.post("/customer/aggregation", "consumerToken", undefined, body)
    }

    async getCustomerExpression(customer_id, expression_id) {
        return await this.api.get(`/customer/${customer_id}/expression/${expression_id}`, "customerWeakToken")
    }

    async getCart(cart_id, params, language) {
        try {
            return await this.api.get(`/cart/${cart_id}`, "consumerToken", language, params)
        } catch (error) {
            if (error.status === 404 || error.status === 403) {
                this.config.cart_id = undefined
                this.config.cart_access_token = undefined
            }
            throw error
        }
    }

    async createCart(body, params, language, retry = 1) {
        try {
            const cart = await this.api.post("/cart", "consumerToken", language, body, params)
            this.config.cart_id = cart.id
            this.config.cart_access_token = cart.meta.access_token
            return cart
        } catch (error) {
            if (error.status !== 403) throw error

            this.config.cart_id = undefined
            this.config.cart_access_token = undefined
            if (retry <= 0) throw error

            delete body.order_id
            delete body.access_token
            return this.createCart(body, params, language, retry - 1)
        }
    }

    async createCartRedeem(body, language) {
        const cart = await this.api.post("/cart/redeem", "consumerToken", language, body)
        this.config.cart_id = cart.id
        this.config.cart_access_token = cart.meta.access_token
        return cart
    }

    async checkCartRedeem(body, language) {
        try {
            await this.api.post("/cart/redeem", "consumerToken", language, body)
            return true
        } catch (error) {
            if (error.status === 404) return false
            throw error
        }
    }

    async putCartRedeem(cart_id, body, params, language) {
        return await this.api.put(`/cart/${cart_id}/redeem`, "consumerToken", language, body, params)
    }

    async getCartShippingCarriers(cart_id, address_id, params, language) {
        return await this.api.get(`/cart/${cart_id}/shipping/${address_id}`, "consumerToken", language, params)
    }

    async getCartFinanceProviders(cart_id, params, language) {
        return await this.api.get(`/cart/${cart_id}/payment`, "consumerToken", language, params)
    }

    async updateCartTrackings(cart_id, body, params, language) {
        return await this.api.patch(`/cart/${cart_id}/tracking`, "consumerToken", language, body, params)
    }

    async postCartCheckout(cart_id, body, params, language) {
        return await this.api.post(`/cart/${cart_id}/checkout`, "consumerToken", language, body, params)
    }

    clearCart() {
        this.config.historic_cart_id = this.config.cart_id
        this.config.cart_id = undefined
        this.config.historic_cart_access_token = this.config.cart_access_token
        this.config.cart_access_token = undefined
        return
    }

    async getSubscriptions(params, language) {
        return await this.api.get("/subscription", "customerWeakToken", language, params)
    }

    async getSubscription(subscription_id, language) {
        return await this.api.get(`/subscription/${subscription_id}`, "customerWeakToken", language)
    }

    async updateSubscription(subscription_id, body, language) {
        return await this.api.patch(`/subscription/${subscription_id}`, "customerWeakToken", language, body)
    }

    async getSubscriptionTokenLink(token, language) {
        return await this.api.get(`/subscription/token_link/${token}`, "consumerToken", language)
    }

    async updateSubscriptionTokenLink(token, body, language) {
        return await this.api.patch(`/subscription/token_link/${token}`, "consumerToken", language, body)
    }

    async getSubscriptionShippingCarriers(params, language) {
        return await this.api.get("/subscription/shipping", "customerWeakToken", language, params)
    }

    async getSubscriptionPaymentMethods(params, language) {
        return await this.api.get("/subscription/payment", "customerWeakToken", language, params)
    }

    async getOrders(params, language) {
        return await this.api.get("/order", "customerWeakToken", language, params)
    }

    async getOrder(order_id, language) {
        return await this.api.get(`/order/${order_id}`, "customerWeakToken", language)
    }

    async updateOrder(order_id, body, language) {
        return await this.api.patch(`/order/${order_id}`, "customerWeakToken", language, body)
    }

    validateToken(token, interval) {
        return this.api.auth.validateToken(token, interval)
    }

    async createAccount(body, language) {
        const response = await this.api.post("/account", "consumerToken", language, body)
        this.config.customerWeakToken = response.token.weak.value
        this.config.customerStrongToken = response.token.strong.value
        this.config.customer_id = response.customer_id
        return response
    }

    async checkAccount(body, language) {
        return await this.api.post("/account/check", "consumerToken", language, body)
    }

    async updateEmail(body, language) {
        return await this.api.patch("/account/email", "customerStrongToken", language, body)
    }

    async updatePassword(body, language) {
        return await this.api.patch("/account/password", "customerStrongToken", language, body)
    }

    async requestPasswordReset(email, language) {
        return await this.api.post(`/account/password/reset/request/${email}`, "consumerToken", language)
    }

    async confirmPasswordReset(token, language) {
        const response = await this.api.post(`/account/password/reset/${token}`, "consumerToken", language)
        this.config.customerWeakToken = response.token.weak.value
        this.config.customerStrongToken = response.token.strong.value
        this.config.customer_id = response.customer_id
        return response
    }

    async requestSmsOneTimePassword(body, language) {
        return await this.api.post("/account/smsotp", "consumerToken", language, body)
    }

    async register(body, language) {
        const clone = { ...body }
        delete clone.password
        const customer = await this.createCustomer(clone, language)

        const response = await this.createAccount(
            {
                customer_id: customer.id,
                access_token: customer.access_token,
                password: body.password,
            },
            language
        )
        this.config.customerWeakToken = response.token.weak.value
        this.config.customerStrongToken = response.token.strong.value
        this.config.customer_id = response.customer_id

        return response
    }

    async getProducts(params, language) {
        return await this.api.get("/product", "consumerToken", language, params)
    }

    async getGeoCountry() {
        return await this.api.get("/geo/country")
    }

    async postContact(body, language) {
        return await this.api.post("/contact", "consumerToken", language, body)
    }

    async getContactCategories(language) {
        return await this.api.get("/contact/category", "consumerToken", language)
    }

    async getCommissionResearches(params, language) {
        return await this.api.get("/commission/researches", "customerWeakToken", language, params)
    }

    async createCommissionResearch(body, language) {
        return await this.api.post("/commission/researches", "customerWeakToken", language, body)
    }

    async getCommissionPromotionalGroups(params, language) {
        return await this.api.get("/promotional/group", "customerWeakToken", language, params)
    }

    async getCommissionPromotionalCouponsAggregate(aggregation_function, field, params, language) {
        return await this.api.get(
            `/promotional/coupon/aggregate/${aggregation_function}/${field}`,
            "customerWeakToken",
            language,
            params
        )
    }

    async getCommissionRules(params, language) {
        return await this.api.get("/commission/rules", "customerWeakToken", language, params)
    }

    async getCommissionRule(id, params, language) {
        return await this.api.get(`/commission/rules/${id}`, "customerWeakToken", language, params)
    }

    async getCommissionBookings(params, language) {
        return await this.api.get("/commission/bookings", "customerWeakToken", language, params)
    }

    async getCommissionBookingAggregate(aggregation_function, field, params, language) {
        return await this.api.get(
            `/commission/bookings/aggregate/${aggregation_function}/${field}`,
            "customerWeakToken",
            language,
            params
        )
    }

    async getCommissionStatements(params, language) {
        return await this.api.get("/commission/statement", "customerWeakToken", language, params)
    }

    async getCommissionStatement(id, params, language) {
        return await this.api.get(`/commission/statement/${id}`, "customerWeakToken", language, params)
    }

    async getCommissionStatementPdf(id, language) {
        return await this.api.get(`/commission/statement/${id}/pdf`, "customerWeakToken", language)
    }

    async getActivityEvents(params, language) {
        return await this.api.get("/activity/events", "customerWeakToken", language, params)
    }

    async getActivityEvent(id, params, language) {
        return await this.api.get(`/activity/events/${id}`, "customerWeakToken", language, params)
    }

    async getActivityCategories(params, language) {
        return await this.api.get("/activity/categories", "customerWeakToken", language, params)
    }

    async getActivityAttendances(params, language) {
        return await this.api.get("/activity/attendances", "customerWeakToken", language, params)
    }

    async createActivityAttendance(body, language) {
        return await this.api.post("/activity/attendances", "customerWeakToken", language, body)
    }

    async deleteActivityAttendance(id, params, language) {
        return await this.api.delete(`/activity/attendances/${id}`, "customerWeakToken", language, params)
    }

    async getExponeaReport(analysis_id) {
        return await this.api.post(`/analyses/report`, "consumerToken", undefined, { analysis_id })
    }
}
