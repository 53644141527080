import { Text, types } from "react-bricks/frontend"

import React from "react"

export interface GreenTextProps {
    index?: number
}

const GreenText: types.Brick<GreenTextProps> = ({ index }) => {
    return (
        <div key={index}>
            <Text
                propName="text"
                renderBlock={({ children }) => <p className="text-xs md:text-sm font-bold text-swamp-green text-center">{children}</p>}
                placeholder="Lorem ipsum dolor sit amet"
            />
        </div>
    )
}

GreenText.schema = {
    name: "GreenText",
    label: "Green Text",
    hideFromAddMenu: true,
}

export default GreenText
