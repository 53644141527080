import { Background, BackgroundColor, Container, Spacer, SpacerVariant } from "../components/layout"
import { Repeater, types } from "react-bricks/frontend"

import { ColumnCustomProductProps } from "./ColumnCustomProduct"
import { EqualHeight } from "react-equal-height/clean"
import React from "react"
import classNames from "classnames"
import { options } from "../../types"
import { twMerge } from "tailwind-merge"

export enum GridColumns {
    "Three" = "3",
    "Two" = "2",
}

export interface ThreeColumnCustomProductsProps {
    repeater?: {
        id: string
        props: ColumnCustomProductProps
        type: "ProductCustomTR"
    }[]
    spacerTop: boolean
    spacerBottom: boolean
    id?: string
    columns?: GridColumns
}

const ThreeColumnCustomProducts: types.Brick<ThreeColumnCustomProductsProps> = ({
    spacerTop,
    spacerBottom,
    id,
    columns = GridColumns.Three,
}) => {
    return (
        <Background color={BackgroundColor.Grey}>
            <Container>
                <Spacer variant={!spacerTop ? SpacerVariant.None : undefined} />
                <EqualHeight>
                    <div
                        className={twMerge(
                            classNames("grid grid-cols-1 gap-x-3.5 gap-y-5 sm:grid-cols-3 md:gap-x-5 lg:gap-x-6", {
                                "sm:grid-cols-2": columns === GridColumns.Two,
                            })
                        )}
                        id={id}
                    >
                        <Repeater
                            propName="repeater"
                            itemProps={
                                {
                                    wide: columns === GridColumns.Two,
                                } as ColumnCustomProductProps
                            }
                        />
                    </div>
                </EqualHeight>
                <Spacer variant={!spacerBottom ? SpacerVariant.None : undefined} />
            </Container>
        </Background>
    )
}

ThreeColumnCustomProducts.schema = {
    name: "ThreeColumnCustomProducts",
    label: "3 Column Custom Products",
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "ProductCustomTR",
                    label: "Product",
                    min: 2,
                    max: 3,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "id",
            label: "ID",
            type: types.SideEditPropType.Text,
        },
        {
            name: "columns",
            label: "Columns",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: options<typeof GridColumns>(GridColumns),
                display: types.OptionsDisplay.Select,
            },
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: (): ThreeColumnCustomProductsProps => ({
        spacerTop: true,
        spacerBottom: true,
        columns: GridColumns.Three,
    }),
}

export default ThreeColumnCustomProducts
