import { useDebounceCallback, useIsomorphicLayoutEffect } from "usehooks-ts"
import { useEffect, useState } from "react"

import { useAdminContext } from "react-bricks/frontend"

type WindowSize<T extends number | undefined = number | undefined> = {
    width: T
    height: T
}

type UseWindowSizeOptions<InitializeWithValue extends boolean | undefined> = {
    initializeWithValue: InitializeWithValue
    debounceDelay?: number
}

const IS_SERVER = typeof window === "undefined"

// SSR version of useWindowSize.
export function useWindowSize(options: UseWindowSizeOptions<false>): WindowSize
// CSR version of useWindowSize.
export function useWindowSize(options?: Partial<UseWindowSizeOptions<true>>): WindowSize<number>
export function useWindowSize(options: Partial<UseWindowSizeOptions<boolean>> = {}): WindowSize | WindowSize<number> {
    let { initializeWithValue = true } = options
    if (IS_SERVER) {
        initializeWithValue = false
    }

    const { isAdmin } = useAdminContext()

    const [scrollSize, setWindowSize] = useState<WindowSize>(() => {
        if (initializeWithValue) {
            const adminFrameDocument = (document.getElementById("rb-admin-frame") as HTMLIFrameElement | null)
                ?.contentWindow?.document
            const adminContentFrameWindow = (
                adminFrameDocument?.getElementById("rb-content-frame") as HTMLIFrameElement | null
            )?.contentWindow?.window

            return {
                width: (isAdmin && adminContentFrameWindow ? adminContentFrameWindow : window).innerWidth,
                height: (isAdmin && adminContentFrameWindow ? adminContentFrameWindow : window).innerHeight,
            }
        }
        return {
            width: undefined,
            height: undefined,
        }
    })

    const debouncedSetWindowSize = useDebounceCallback(setWindowSize, options.debounceDelay)

    const adminFrameDocument = !IS_SERVER ? (document.getElementById("rb-admin-frame") as HTMLIFrameElement | null)?.contentWindow
        ?.document : undefined
    const adminContentFrameWindow = (adminFrameDocument?.getElementById("rb-content-frame") as HTMLIFrameElement | null)
        ?.contentWindow?.window

    function handleSize() {
        const setSize = options.debounceDelay ? debouncedSetWindowSize : setWindowSize

        setSize({
            width: (isAdmin && adminContentFrameWindow ? adminContentFrameWindow : window).innerWidth,
            height: (isAdmin && adminContentFrameWindow ? adminContentFrameWindow : window).innerHeight,
        })
    }

    useEffect(() => {
        // Define the listening target
        const targetElement = isAdmin && adminContentFrameWindow ? adminContentFrameWindow : window

        if (!(targetElement && targetElement.addEventListener)) return

        targetElement.addEventListener("resize", handleSize)

        // Remove event listener on cleanup
        return () => {
            targetElement.removeEventListener("resize", handleSize)
        }
    }, [isAdmin])

    // Set size at the first client-side load
    useIsomorphicLayoutEffect(() => {
        handleSize()
    }, [])

    return scrollSize
}
