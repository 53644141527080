import React, { ReactNode } from "react"

import classNames from "classnames"
import { colorOptions } from "../../../types"
import { twMerge } from "tailwind-merge"

export enum CardBackgroundColor {
    "White" = "#fff",
    "Green" = "#919a76",
}

interface ShadowCardProps {
    children: ReactNode
    background?: CardBackgroundColor
    className?: string
}

const ShadowCard = ({ children, className, background = CardBackgroundColor.White }: ShadowCardProps) => {
    return (
        <div
            className={twMerge(
                classNames(
                    "mx-auto flex w-full flex-col rounded-base p-5 shadow-base sm:p-7.5 lg:w-[80%] lg:p-15 lg:shadow-lg",
                    {
                        "bg-white": background === CardBackgroundColor.White,
                        "bg-gradient-to-r from-green-dark via-green-light to-green-dark":
                            background === CardBackgroundColor.Green,
                    },
                    className
                )
            )}
        >
            {children}
        </div>
    )
}

export const cardBackgroundOptions = colorOptions<typeof CardBackgroundColor>(CardBackgroundColor)

export default ShadowCard
