import { Icons, Pictograms, iconOptions, pictogramOptions } from "../../types"
import Typography, { TypographyElement } from "../Typography"

import { Color } from "../components/typography"
import { Icon } from "../components/icon"
import React from "react"
import { types } from "react-bricks/frontend"

export interface IconTextListProps {
    icon: Pictograms | Icons
    listText: TypographyElement[]
}

const IconTextList: types.Brick<IconTextListProps> = ({ icon }) => {
    return (
        <div className="flex gap-x-3">
            <Icon icon={icon} className="w-[20px] text-center text-md leading-none lg:w-[24px] lg:text-lg" />
            <Color>
                <Typography propName="listText" allowList={["link", "sup"]} block="copytext-small" />
            </Color>
        </div>
    )
}

IconTextList.schema = {
    name: "IconTextList",
    label: "Icon Text List",
    hideFromAddMenu: true,
    sideEditProps: [
        {
            name: "icon",
            label: "Icon",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: [...pictogramOptions, ...iconOptions],
                display: types.OptionsDisplay.Select,
            },
        },
    ],
    getDefaultProps: (): IconTextListProps => ({
        icon: Icons.check,
        listText: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc placerat sagittis faucibus.",
                    },
                ],
            },
        ],
    }),
}

export default IconTextList
