import React, { ReactNode } from "react"

interface GridSmColProps {
    children: ReactNode
}

const GridSmCol = ({ children }: GridSmColProps) => {
    return (
        <div className="grid grid-cols-1 gap-x-10 gap-y-7 sm:grid-cols-2 md:gap-x-16 lg:gap-x-24 xl:gap-x-40">
            {children}
        </div>
    )
}

export default GridSmCol
