const hashValue = value => {
    return JSON.stringify(value, (_, val) =>
        Object.prototype.toString.call(val) === "[object Object]"
            ? Object.keys(val)
                  .sort()
                  .reduce((result, key) => {
                      result[key] = val[key]
                      return result
                  }, {})
            : val
    )
}

const updateQueryStatus = (dispatch, { key, status, fetchStatus }) => {
    dispatch({ type: "UPDATE_STATUS", payload: { [hashValue(key)]: { status, fetchStatus } } })
    return
}

const startLoading = dispatch => {
    dispatch({ type: "START_LOADING" })
    return
}

const endLoading = dispatch => {
    dispatch({ type: "END_LOADING" })
    return
}

export { updateQueryStatus, startLoading, endLoading }
