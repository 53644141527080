const initialState = {
    loading: false,
    queryStatus: {},
}

const LoadingReducer = (state, action) => {
    switch (action.type) {
        case "UPDATE_STATUS":
            return {
                ...state,
                queryStatus: { ...state.queryStatus, ...action.payload },
            }
        case "START_LOADING":
            return {
                ...state,
                loading: true,
            }
        case "END_LOADING":
            return {
                ...state,
                loading: false,
            }

        default:
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}

export { LoadingReducer, initialState }
