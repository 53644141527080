import ErrorToastStyles from "./error-toast.module.css"
import React from "react"
import { useTranslation } from "react-i18next"

const ErrorToast = props => {
    const { t, i18n } = useTranslation("shared")
    const { error } = props

    return (
        <>
            {i18n.exists(`shared:${error.code}`) ? t(error.code, {...error.detail}) : error.msg || t("generic_error")}
            {error.id && (
                <>
                    <br />
                    <span className={ErrorToastStyles.code}>
                        {t("error_code")} <code>{error.id}</code>
                    </span>
                </>
            )}
        </>
    )
}

export default ErrorToast
