const addSearchParams = (href) => {
    let newHref = href

    if (typeof window !== "undefined") {
        let search = window.location.search

        if (search) {
            const pathnameWithSearch = href.split("#")[0]
            search = search.replace("?", "")
            let hash = href.split("#")[1]
            hash = hash ? "#" + hash : ""
            if (pathnameWithSearch.includes("?")) {
                newHref = pathnameWithSearch + "&" + search + hash
            } else {
                newHref = pathnameWithSearch + "?" + search + hash
            }
        }
    }

    return newHref
}

export { addSearchParams }
