import React from "react"

const Sup = (
    <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 100 100"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M90.478 31.418a.99.99 0 00-.703-.291v-.011h-5.208c3.816-2.52 5.064-4.32 5.064-6.504 0-3.216-2.712-5.521-6.865-5.521-2.138 0-4.685.695-6.521 2.481-.043.031-.092.048-.13.087-.027.027-.038.063-.062.093-.018.018-.038.033-.055.052l.012.014a.965.965 0 00.005 1.095l-.016.01 1.367 1.995h.001a.982.982 0 001.265.093l.007.009c.036-.03.072-.056.108-.085.007-.007.017-.009.025-.016l.005-.007c1.184-.965 2.446-1.548 4.088-1.548 1.032 0 1.848.48 1.848 1.248 0 1.2-.696 1.8-8.017 6.96v.022c-.067.041-.139.073-.197.131a.97.97 0 00-.267.578h-.024v2.609c0 .241.195.437.437.437.018 0 .034-.008.052-.01v.004h13.082a.992.992 0 00.994-.994v-2.225a1.013 1.013 0 00-.295-.706zm-20.199 2.049l-3.389-3.39a2.604 2.604 0 00-3.683 0l-.016-.016-13.034 13.034-13.033-13.034a2.604 2.604 0 00-3.683 0l-3.389 3.389a2.604 2.604 0 000 3.683l-.016.016 13.033 13.033-13.034 13.035.016.016a2.604 2.604 0 000 3.683l3.389 3.389a2.604 2.604 0 003.683 0l13.033-13.033 13.033 13.033.016-.016a2.604 2.604 0 003.683 0l3.389-3.389a2.604 2.604 0 000-3.683L57.246 50.183 70.279 37.15a2.604 2.604 0 000-3.683z"></path>
    </svg>
)

export default Sup
