import { Icons, Pictograms } from "../../../types/icons"

import IconUniversal from "../icon/icon"
import React from "react"
import classNames from "classnames"
import { twMerge } from "tailwind-merge"

export interface IconTextProps {
    icon: Pictograms | Icons
    className?: string
}

const IconText = ({ icon, className }: IconTextProps) => {
    return (
        <IconUniversal
            className={twMerge(
                classNames("text-[35px] leading-none text-border-copperfield sm:text-[40px] lg:text-[50px]", className)
            )}
            icon={icon}
        />
    )
}

export default IconText
