import React from "react"
import { RenderElementProps } from "slate-react"

const CheckList = ({ children, attributes }: RenderElementProps) => {
    return (
        <li
            {...attributes}
            data-before=""
            className="relative mb-4 pl-8 before:absolute before:left-0 before:top-0 before:z-2 before:font-['lavita-icon-font'] before:text-md before:content-[attr(data-before)] last:mb-0 sm:mb-5 sm:pl-9.5 before:sm:text-lg lg:pl-10.5 before:lg:text-xl"
        >
            {children}
        </li>
    )
}

export default CheckList
