import { Spacer, SpacerVariant } from "../components/layout"

import React from "react"
import { types } from "react-bricks/frontend"

export interface DividerProps {
    spacerTop: boolean
    spacerBottom: boolean
}

const Divider: types.Brick<DividerProps> = ({ spacerTop, spacerBottom }) => {
    return (
        <>
            <Spacer variant={!spacerTop ? SpacerVariant.None : undefined} />
            <div className="h-px sm:h-0.5 bg-border-tide w-full" />
            <Spacer variant={!spacerBottom ? SpacerVariant.None : undefined} />
        </>
    )
}

Divider.schema = {
    name: "Divider",
    label: "Divider",
    sideEditProps: [
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: (): DividerProps => ({
        spacerTop: true,
        spacerBottom: true,
    }),
}

export default Divider
