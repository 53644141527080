import { Background, BackgroundColor, Container, Spacer, SpacerVariant } from "../components/layout"
import { Badges, ProductImage } from "../components/product"
import React, { useEffect, useState } from "react"
import { Repeater, Text, types, usePageValues } from "react-bricks/frontend"

import type { Product } from "@lavita-io/international-sdk"
import { TabProductProps } from "./TabProduct"
import classNames from "classnames"
import client from "../../shared/utils/client"
import { useClientState } from "../../shared/context/client"
import { useLocalesState } from "../../shared/context/locales"
import { usePageContext } from "../../context/page"
import { useQuery } from "@tanstack/react-query"

export interface TabbedProductsProps {
    sku: string
    repeater?: {
        id: string
        props: TabProductProps
        type: "SingleProduct"
    }[]
    spacerTop: boolean
    spacerBottom: boolean
    id?: string
}

const TabbedProducts: types.Brick<TabbedProductsProps> = ({ repeater, sku, spacerTop, spacerBottom, id }) => {
    const { region } = useLocalesState()
    const { products: productsBuildTime, page: pageContext } = usePageContext()
    const { consumerToken } = useClientState()

    const [pageValues] = usePageValues()

    const page = pageContext || pageValues

    const { data } = useQuery<Product[]>(
        ["products", ...(repeater?.map(r => r.props.sku).filter(r => r) || []), region, page?.language],
        () =>
            client.getProducts(
                { sku: repeater?.map(r => r.props.sku).filter(r => r), country: region },
                page?.language
            ),
        {
            enabled: !!(repeater?.length && page && consumerToken),
        }
    )

    const products = data || productsBuildTime

    const [activeSku, setActiveSku] = useState(sku)

    const clickTab = (sku: string) => {
        setActiveSku(sku)
    }

    useEffect(() => {
        if (!repeater?.length) return
        if (repeater.find(r => r.props.sku === sku)) return setActiveSku(sku)

        setActiveSku(repeater[0].props.sku)
    }, [repeater, sku])

    const findProduct = (sku: string) => {
        return products?.find(p => p.sku === sku)
    }

    return (
        <Background color={BackgroundColor.Grey}>
            <Container>
                <Spacer variant={!spacerTop ? SpacerVariant.None : undefined} />
                <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-5 lg:gap-x-6" id={id}>
                    <div className="hidden sm:block">
                        <div className="sticky top-20 rounded-base bg-white p-5 lg:top-[104px] lg:p-7.5">
                            <ProductImage product={findProduct(activeSku)} />
                            {(repeater?.find(r => r.props.sku === activeSku)?.props.badge ||
                                repeater?.find(r => r.props.sku === activeSku)?.props.secondaryBadge) && (
                                <Badges
                                    badge={repeater?.find(r => r.props.sku === activeSku)?.props.badge}
                                    secondaryBadge={
                                        repeater?.find(r => r.props.sku === activeSku)?.props.secondaryBadge
                                    }
                                />
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col">
                        {repeater && repeater.length > 1 && (
                            <div className="flex flex-row gap-2.5 rounded-t-lg bg-calypso px-2.5 pt-2.5 text-white sm:px-3.75 md:px-5">
                                {repeater.map(r => {
                                    const product = findProduct(r.props.sku)
                                    return (
                                        <div
                                            key={r.id}
                                            className={classNames(
                                                "flex w-1/2 items-center justify-center rounded-t-base p-2.5 text-center text-base font-semibold sm:p-3.75 sm:text-md",
                                                {
                                                    "bg-white text-blue": activeSku === r.props.sku,
                                                }
                                            )}
                                        >
                                            <Text
                                                propName={`title-${r.props.sku}`}
                                                renderBlock={({ children }) => (
                                                    <button
                                                        className="h-full w-full"
                                                        onClick={() => clickTab(r.props.sku)}
                                                    >
                                                        {children}
                                                    </button>
                                                )}
                                                renderPlaceholder={({ children }) => (
                                                    <button
                                                        className="h-full w-full"
                                                        onClick={() => clickTab(r.props.sku)}
                                                    >
                                                        {children}
                                                    </button>
                                                )}
                                                placeholder={product?.short_name || ""}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                        <div
                            className={classNames({
                                "rounded-b-lg bg-white": repeater && repeater.length > 1,
                            })}
                        >
                            <Repeater
                                propName="repeater"
                                itemProps={
                                    {
                                        activeSku,
                                        products,
                                    } as TabProductProps
                                }
                            />
                        </div>
                    </div>
                </div>
                <Spacer variant={!spacerBottom ? SpacerVariant.None : undefined} />
            </Container>
        </Background>
    )
}

TabbedProducts.schema = {
    name: "TabbedProducts",
    label: "Tabbed Products",
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "SingleProduct",
                    label: "Product",
                    min: 1,
                    max: 2,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "sku",
            label: "aktive SKU",
            type: types.SideEditPropType.Text,
        },
        {
            name: "id",
            label: "ID",
            type: types.SideEditPropType.Text,
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: (): TabbedProductsProps => ({
        sku: "LV500",
        spacerTop: true,
        spacerBottom: true,
    }),
}

export default TabbedProducts
