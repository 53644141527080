import type { Product } from "@lavita-io/international-sdk"
import { formatPrice } from "../shared/utils/format"
import i18n from "../i18n/config"

const additional = (language: string, product?: Product) => {
    if (!product) return

    return product.sku === "LV2START"
        ? i18n.t("lvstart_pricing_addition", {
              splits: (product.splitted?.parts || 1),
              price: formatPrice(product.price.amount / (product.splitted?.parts || 1), product.price.currency, language),
          })
        : undefined
}

export { additional }
