import { Icons, Pictograms, iconOptions, pictogramOptions } from "../../../types"
import { Repeater, Text, types } from "react-bricks/frontend"

import { Icon } from "../../components/icon"
import React from "react"
import { TypographyElement } from "../../Typography"

export interface ListItemProps {
    icon: Pictograms | Icons
    text: TypographyElement[]
}

export const ListItem: types.Brick<ListItemProps> = ({ icon }) => {
    return (
        <div className="flex gap-x-1.5 md:gap-x-3 items-center">
            <Icon icon={icon} className="w-3.5 text-center text-xs leading-none md:w-4.5 md:text-base" />
            <Text
                propName="text"
                renderBlock={({ children }) => <li className="text-sm md:text-base">{children}</li>}
                placeholder="Lorem ipsum dolor sit"
            />
        </div>
    )
}

ListItem.schema = {
    name: "ListItem",
    label: "Icon Text List",
    hideFromAddMenu: true,
    sideEditProps: [
        {
            name: "icon",
            label: "Icon",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: [...pictogramOptions, ...iconOptions],
                display: types.OptionsDisplay.Select,
            },
        },
    ],
    getDefaultProps: (): ListItemProps => ({
        icon: Icons.check,
        text: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet",
                    },
                ],
            },
        ],
    }),
}

export interface ListProps {
    index?: number
}

const List: types.Brick<ListProps> = ({ index }) => {
    return (
        <ul key={index} className="flex flex-col sm:flex-row flex-wrap gap-x-5 gap-y-3 justify-center">
            <Repeater propName="repeater" />
        </ul>
    )
}

List.schema = {
    name: "List",
    label: "List",
    hideFromAddMenu: true,
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "ListItem",
                    label: "Icon Text",
                    min: 1,
                },
            ],
        },
    ],
}

export default List
