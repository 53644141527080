import { Button, ButtonStyle } from "../components/button"
import { additional as additionalHelper, smallprint as smallprintHelper } from "../../util"
import { types, useAdminContext, usePageValues } from "react-bricks/frontend"

import { Badges } from "../components/product"
import { EqualHeightElement } from "react-equal-height/clean"
import { Icons } from "../../types"
import { LazyImage } from "../components/universal"
import type { Product as ProductType } from "@lavita-io/international-sdk"
import React from "react"
import ReactMarkdown from "react-markdown"
import classNames from "classnames"
import { formatPrice } from "../../shared/utils/format"
import { twMerge } from "tailwind-merge"
import { usePageContext } from "../../context/page"
import { useTranslation } from "react-i18next"
import { useWindowSize } from "../../hooks/use-window-size-admin"

export interface ColumnProductProps {
    sku: string
    badge?: string
    secondaryBadge?: string
    products?: ProductType[]
    index?: number
    disabled?: boolean
    wide?: boolean
}

const ColumnProduct: types.Brick<ColumnProductProps> = ({
    index,
    sku,
    badge,
    secondaryBadge,
    products,
    disabled,
    wide,
}) => {
    const { t } = useTranslation("translations")
    const { width } = useWindowSize({ initializeWithValue: false })

    const { products: productsBuildTime, page: pageContext } = usePageContext()

    const [pageValues] = usePageValues()

    const page = pageContext || pageValues

    const product = products ? products.find(p => p.sku === sku) : productsBuildTime?.find(p => p.sku === sku)

    const { isAdmin, previewMode } = useAdminContext()

    if ((!product && !isAdmin && !previewMode) || !page) return null

    const smallprint = smallprintHelper(page.language, product)

    const additional = additionalHelper(page.language, product)

    return (
        <div
            key={index}
            className={twMerge(
                classNames("relative rounded-base bg-white p-3.75 sm:p-5 lg:p-7.5", {
                    "sm:px-7.5 lg:px-15": wide,
                })
            )}
        >
            <div className="mx-auto mb-5 w-[200px] max-w-full sm:w-[181px] lg:w-[235px] xl:w-[300px]">
                <div className="aspect-h-1 aspect-w-1">
                    <LazyImage
                        path={
                            product?.images
                                .find(i => i.type === "side")
                                ?.href.replace(process.env.IMG_CDN_URL_ASSETS, "") || ""
                        }
                        srcSet={[{ w: 464, default: true }, { w: 717 }, { w: 936 }, { w: 1434 }]}
                        sizes="(max-width: 767px) calc(100vw - 50px), 464px"
                        className="block"
                    />
                </div>
            </div>
            <div>
                {/* @ts-ignore */}
                <EqualHeightElement name="name" disable={width < 768}>
                    <h4 className="mb-3 text-center text-md font-semibold text-blue md:text-lg lg:text-xl">
                        {product?.short_name}
                    </h4>
                    <p className="text-center text-base text-light-blue md:text-md">{product?.short_description}</p>
                </EqualHeightElement>
                {/* @ts-ignore */}
                <EqualHeightElement name="price" disable={width < 768}>
                    <span className="mb-5 mt-5 block text-center text-2xl font-semibold lg:mb-10">
                        {product?.price.unit && (
                            <small className="mr-1.5 text-sm leading-none">{product.price.unit}</small>
                        )}
                        {product?.price.regular && product.price.regular !== product.price.amount && (
                            <del className="relative mr-2.5 text-lg font-normal leading-none text-light-grey no-underline after:absolute after:-left-1 after:top-2 after:h-0.5 after:w-11 after:rotate-[-27deg] after:bg-dark-grey">
                                {formatPrice(product.price.regular, product.price.currency, page.language)}
                            </del>
                        )}
                        {product && formatPrice(product.price.amount, product.price.currency, page.language)}
                        {/* @ts-ignore */}
                        <EqualHeightElement name="additional" disable={width < 768}>
                            {additional && <small className="block text-sm leading-none">{additional}</small>}
                        </EqualHeightElement>
                        <small className="mt-2.5 block whitespace-pre-wrap text-xs font-normal text-light-grey lg:text-sm">
                            {smallprint}
                        </small>
                    </span>
                </EqualHeightElement>
                <Button
                    href={
                        !product?.subscription
                            ? `${process.env.SHOP_URL}/cart?add_products=[{"sku":"${sku}","quantity":1}]`
                            : `${process.env.SHOP_URL}/configure/subscription?sku=${sku}`
                    }
                    title={
                        disabled
                            ? t("icon_action_sold_out")
                            : !product?.subscription
                            ? t("icon_action_add_to_cart")
                            : t("icon_action_configure")
                    }
                    icon={!product?.subscription ? Icons.cart : Icons.interval}
                    className="sm:w-full"
                    disabled={disabled}
                    style={disabled ? ButtonStyle.Disabled : undefined}
                />
                {!!(product?.description || product?.features.length) && (
                    <div className="mt-5 lg:mt-10">
                        <ul className="mb-5 list-none pl-6 last:mb-0 lg:mb-10">
                            {product.features.map((f: string) => (
                                <li
                                    key={f}
                                    data-before=""
                                    className="relative mb-3 p-0 text-sm before:absolute before:-left-6 before:top-1 before:font-['lavita-icon-font'] before:text-sm before:leading-none before:content-[attr(data-before)] last:mb-0 lg:mb-5 lg:text-md"
                                >
                                    {f}
                                </li>
                            ))}
                        </ul>
                        {product.description && (
                            <ReactMarkdown className="mb-6 whitespace-pre-wrap text-sm font-semibold text-blue last:mb-0 lg:text-md">
                                {product.description}
                            </ReactMarkdown>
                        )}
                    </div>
                )}
            </div>
            {(badge || secondaryBadge) && <Badges badge={badge} secondaryBadge={secondaryBadge} />}
        </div>
    )
}

ColumnProduct.schema = {
    name: "Product",
    label: "Column Product",
    hideFromAddMenu: true,
    sideEditProps: [
        {
            name: "sku",
            label: "SKU",
            type: types.SideEditPropType.Text,
        },
        {
            name: "badge",
            label: "Badge",
            type: types.SideEditPropType.Text,
        },
        {
            name: "secondaryBadge",
            label: "Secondary badge",
            type: types.SideEditPropType.Text,
        },
        {
            name: "disabled",
            label: "Disabled",
            type: types.SideEditPropType.Boolean,
        },
    ],
    getDefaultProps: (): ColumnProductProps => ({
        sku: "LV500",
        disabled: false,
    }),
}

export default ColumnProduct
