import { closeOverlay, useOverlayDispatch } from "../shared/context/overlay"

import { A } from "../shared/utils/router"
import React from "react"
import SitemapOverlayStyles from "./sitemap-overlay.module.css"
import classNames from "classnames"
import { findTranslation } from "../shared/utils/find-translation"
import { sitemapMenu } from "../config/sitemap"
import { useLocalesState } from "../shared/context/locales"
import { useScrollPosition } from "../shared/hooks/use-scroll-position"

const SitemapOverlay = props => {
    const { language } = useLocalesState()
    const overlayDispatch = useOverlayDispatch()

    const { scrollTop } = useScrollPosition()

    return (
        <ul
            id="sitemap"
            className={classNames(SitemapOverlayStyles.container, {
                [SitemapOverlayStyles.hidden]: props.hidden && scrollTop !== 0,
            })}
        >
            {sitemapMenu
                .filter(s => findTranslation(s, language))
                .sort((a, b) =>
                    (
                        findTranslation(a, language).title.toLowerCase() +
                        findTranslation(a, language).addition?.toLowerCase()
                    ).localeCompare(
                        findTranslation(b, language).title.toLowerCase() +
                            findTranslation(b, language).addition?.toLowerCase()
                    )
                )
                .map(s => (
                    <li key={s.id}>
                        <A href={findTranslation(s, language).url} onClick={() => closeOverlay(overlayDispatch)}>
                            {findTranslation(s, language).title}
                        </A>
                        {findTranslation(s, language)?.addition && " "}
                        {findTranslation(s, language)?.addition}
                    </li>
                ))}
        </ul>
    )
}

export default SitemapOverlay
