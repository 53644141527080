import { Repeater, types } from "react-bricks/frontend"
import { Spacer, SpacerVariant } from "../components/layout"

import React from "react"

export interface TableProps {
    spacerTop: boolean
    spacerBottom: boolean
}

const Table: types.Brick<TableProps> = ({ spacerTop, spacerBottom }) => {
    return (
        <div className="mx-auto box-border w-full px-0 sm:px-6.5 md:px-10 lg:max-w-[1560px] lg:px-15">
            <Spacer variant={!spacerTop ? SpacerVariant.None : undefined} />
            <table className="w-full overflow-hidden rounded-lg text-xs sm:text-md">
                <Repeater propName="rows" />
            </table>
            <Spacer variant={!spacerBottom ? SpacerVariant.None : undefined} />
        </div>
    )
}

Table.schema = {
    name: "Table",
    label: "Table",
    repeaterItems: [
        {
            name: "rows",
            itemType: "TableRow",
            min: 1,
        },
    ],
    sideEditProps: [
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: () => ({
        spacerTop: true,
        spacerBottom: true,
    }),
}

export default Table
