import { Color, TypographyColor, colorOptions } from "../components/typography"
import { Container, Spacer, SpacerVariant } from "../components/layout"
import ImageEditor, { AspectRatio } from "../Image"
import { Repeater, types } from "react-bricks/frontend"
import Typography, { TypographyElement } from "../Typography"

import React from "react"

interface ProductTeaserProps {
    text: TypographyElement[]
    color: { color: TypographyColor } | TypographyColor
    spacerTop: boolean
    spacerBottom: boolean
    hideText?: boolean
}

const ProductTeaser: types.Brick<ProductTeaserProps> = ({ color, spacerTop, spacerBottom, hideText }) => {
    const txtColor = typeof color === "object" ? color.color : color

    return (
        <div className="w-full px-0 lg:text-center xl:m-auto xl:max-w-[1728px] xl:px-24">
            <Spacer variant={!spacerTop ? SpacerVariant.None : undefined} />
            <div className="relative flex h-[569px] sm:items-center overflow-hidden sm:h-auto">
                <ImageEditor
                    maxWidth={1728}
                    aspectRatio={AspectRatio["16/9"]}
                    className="hidden w-full lg:block xl:rounded-base"
                    propName="image"
                />
                <ImageEditor
                    maxWidth={1366}
                    aspectRatio={AspectRatio["16/9"]}
                    className="hidden w-full sm:block lg:hidden"
                    propName="image_tablet"
                />
                <ImageEditor
                    maxWidth={767}
                    aspectRatio={AspectRatio["5/4"]}
                    className="block h-full object-cover object-right sm:hidden "
                    propName="image_mobile"
                    sizes="767px"
                />
                {!hideText && (
                    <div className="absolute z-2 w-full">
                        <div className="p-6.5 py-10 text-center sm:mb-16 sm:text-left md:pl-10 lg:pl-40">
                            <Color color={txtColor}>
                                <Typography block="copytext-extra-large" allowList={["h3", "bold"]} propName="text" />
                                <Repeater
                                    propName="repeater"
                                    renderWrapper={children => (
                                        <div className="mt-8 flex flex-col space-y-7 sm:mt-10 sm:flex-row sm:space-x-7 sm:space-y-0 md:mt-16 md:space-x-10 lg:mt-20">
                                            {children}
                                        </div>
                                    )}
                                />
                            </Color>
                        </div>
                    </div>
                )}
            </div>
            <Spacer variant={!spacerBottom ? SpacerVariant.None : undefined} />
        </div>
    )
}

ProductTeaser.schema = {
    name: "ProductTeaser",
    label: "Product Teaser",
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "ProductTeaserItem",
                    label: "Text",
                    min: 3,
                    max: 3,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "color",
            label: "Color",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: colorOptions,
                display: types.OptionsDisplay.Color,
            },
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
        {
            name: "hideText",
            label: "Hide Text for Image",
            type: types.SideEditPropType.Boolean,
        },
    ],
    getDefaultProps: (): ProductTeaserProps => ({
        text: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet",
                    },
                ],
            },
            {
                type: "h3",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet",
                    },
                ],
            },
        ],

        color: TypographyColor.Darkgrey,
        spacerTop: true,
        spacerBottom: true,
        hideText: false,
    }),
}

export default ProductTeaser
