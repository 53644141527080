import Spacer, { SpacerVariant } from "../layout/spacer"

import { Icons } from "../../../types/icons"
import React from "react"
import classNames from "classnames"

interface SliderNavigationProps {
    lastIndex: number
    length: number
    goTo: (index: number) => void
}

const SliderNavigation = ({ lastIndex, length, goTo }: SliderNavigationProps) => {
    return (
        <div>
            <Spacer variant={SpacerVariant.Small} />
            <div className="flex h-full items-center justify-center sm:justify-end">
                <button
                    className={classNames("mr-3.5 hidden select-none text-blue sm:block lg:mr-5", {
                        "pointer-events-none text-disabled": lastIndex < 1,
                    })}
                    type="button"
                    onClick={() => goTo(lastIndex - 1)}
                >
                    <i className="lavita-icon text-[42px] lg:text-[52px]">{Icons.back}</i>
                </button>
                <button
                    className={classNames("hidden select-none text-blue sm:block", {
                        "pointer-events-none text-disabled": lastIndex + 1 >= length,
                    })}
                    type="button"
                    onClick={() => goTo(lastIndex + 1)}
                >
                    <i className="lavita-icon scale-x-[-1] text-[42px] lg:text-[52px]">{Icons.back}</i>
                </button>
                {[...Array(length)].map((_, i) => (
                    // eslint-disable-next-line
                    <button
                        type="button"
                        className={classNames("mr-1.5 h-[9px] w-[9px] rounded-full bg-disabled last:mr-0 sm:hidden", {
                            "bg-light-grey": lastIndex === i,
                        })}
                        onClick={() => goTo(i)}
                        key={i}
                    ></button>
                ))}
            </div>
        </div>
    )
}

export default SliderNavigation
