import React, { useRef } from "react"
import { allLanguages, languages } from "../../config/languages"
import { changeLanguageAndRegion, useLocalesDispatch, useLocalesState } from "../../context/locales"
import { closeOverlay, toggleOverlay } from "../../context/locales/actions"

import LocalesMenuStyles from "./locales.module.css"
import classNames from "classnames"
import { regions } from "../../config/regions"
import { useOnClickOutside } from "../../hooks/use-on-click-outside"

const LocalesMenuItem = props => {
    const { region, language } = useLocalesState()
    const { r, translations } = props

    const localesDispatch = useLocalesDispatch()

    return (
        <div className={LocalesMenuStyles.languages}>
            {r.redirect ? (
                r.languages.map((l, i) => (
                    <a href={r.redirect + (r.languagePaths?.[i] ? r.languagePaths[i] : "")} key={l}>
                        {allLanguages.find(language => language.langCode === l).name}
                    </a>
                ))
            ) : (
                <>
                    {
                        r.languages
                            .filter(l => languages.some(lang => lang.langCode === l))
                            .map(l => {
                                const languagePath =
                                    l === process.env.DEFAULT_LANGUAGE
                                        ? ""
                                        : process.env.IGNORE_REGION_IN_PATH
                                        ? `${l}`
                                        : `${l}-${r.countryCode.toLowerCase()}`
                                return (
                                    <a
                                        className={classNames({
                                            [LocalesMenuStyles.activeLanguage]:
                                                l === language && r.countryCode === region,
                                        })}
                                        href={`/${languagePath}${
                                            translations?.find(t => t.language === languagePath)?.slug
                                                ? `${languagePath ? "/" : ""}${
                                                      translations.find(t => t.language === languagePath).slug
                                                  }`
                                                : ""
                                        }`}
                                        key={languagePath}
                                        onClick={async e => {
                                            e.preventDefault()
                                            await changeLanguageAndRegion(localesDispatch, {
                                                language: l,
                                                region: r.countryCode,
                                            })
                                            window.location.href = `/${languagePath}${
                                                translations?.find(t => t.language === languagePath)?.slug
                                                    ? `${languagePath ? "/" : ""}${
                                                          translations.find(t => t.language === languagePath).slug
                                                      }`
                                                    : ""
                                            }`
                                            closeOverlay(localesDispatch)
                                        }}
                                    >
                                        {languages.find(language => language.langCode === l).name}
                                    </a>
                                )
                            })
                    }
                    {
                        r.languages.filter(l => languages.some(lang => lang.langCode === l)).length !==
                            r.languages.length &&
                            !r.languages.includes(process.env.DEFAULT_LANGUAGE) && (
                                <a
                                    className={classNames({
                                        [LocalesMenuStyles.activeLanguage]:
                                            process.env.DEFAULT_LANGUAGE === language && r.countryCode === region,
                                    })}
                                    href={`/${translations?.find(t => t.language === "")?.slug || ""}`}
                                    onClick={async e => {
                                        e.preventDefault()
                                        await changeLanguageAndRegion(localesDispatch, {
                                            language: process.env.DEFAULT_LANGUAGE,
                                            region: r.countryCode,
                                        })
                                        window.location.href = `/${
                                            translations?.find(t => t.language === "")?.slug || ""
                                        }`
                                        closeOverlay(localesDispatch)
                                    }}
                                >
                                    {
                                        languages.find(language => language.langCode === process.env.DEFAULT_LANGUAGE)
                                            .name
                                    }
                                </a>
                            )
                    }
                </>
            )}
        </div>
    )
}

const LocalesMenu = props => {
    const { region, overlayOpen } = useLocalesState()
    const localesDispatch = useLocalesDispatch()
    const { light, translations } = props

    const ref = useRef(null)

    useOnClickOutside(
        isInside => {
            if (isInside || document.getElementsByClassName("ccm-blocked")?.length) return
            closeOverlay(localesDispatch)
        },
        [ref]
    )

    return (
        <div ref={ref}>
            <button
                type="button"
                onClick={() => toggleOverlay(localesDispatch)}
                className={classNames(LocalesMenuStyles.button, {
                    [LocalesMenuStyles.active]: overlayOpen,
                    [LocalesMenuStyles.light]: light,
                })}
            >
                <i className="lavita-icon"></i>
                <div>
                    {
                        (
                            regions.find(r => r.countryCode === region) ||
                            regions.find(r => r.countryCode === process.env.REGION)
                        )?.countryCode
                    }
                </div>
            </button>
            <div className={LocalesMenuStyles.menu}>
                <div className={LocalesMenuStyles.contentWrapper}>
                    <h3 className={LocalesMenuStyles.title}>Please select your region and your language:</h3>
                    <div className={LocalesMenuStyles.options}>
                        {regions
                            .filter(r => r.flag)
                            .map(r => (
                                <div
                                    className={classNames(LocalesMenuStyles.flagItem, {
                                        [LocalesMenuStyles.activeItem]: r.countryCode === region,
                                    })}
                                    key={r.countryCode}
                                >
                                    <div className={LocalesMenuStyles.flag}>{r.flag}</div>
                                    <div>
                                        <strong>{r.name}</strong>
                                        <LocalesMenuItem r={r} translations={translations} />
                                    </div>
                                </div>
                            ))}
                    </div>
                    <div className={LocalesMenuStyles.options}>
                        {regions
                            .filter(r => !r.flag)
                            .map(r => (
                                <div
                                    className={classNames(LocalesMenuStyles.item, {
                                        [LocalesMenuStyles.activeItem]: r.countryCode === region,
                                    })}
                                    key={r.countryCode}
                                >
                                    <strong>{r.name}</strong>
                                    <LocalesMenuItem r={r} translations={translations} />
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LocalesMenu
