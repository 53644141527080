import { login, useAuthDispatch } from "../../context/auth"

import ErrorToast from "../miscellaneous/error-toast"
import IconAction from "../universal/icon-action"
import Input from "../fields/input"
import React from "react"
import client from "../../utils/client"
import { toast } from "react-toastify"
import { useForm } from "react-hook-form"
import { useLocalstorage } from "../../hooks/use-localstorage"
import { useTranslation } from "react-i18next"
import { useUrlSearchParams } from "../../hooks/use-url-search-params"

const LoginForm = props => {
    const { t } = useTranslation("shared")

    const { handleSubmit, control } = useForm({
        mode: "onTouched",
        defaultValues: {
            email: client.config?.email || "",
            password: "",
        },
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
    })

    const dispatch = useAuthDispatch()

    const [, setGuestCustomer] = useLocalstorage("guest_customer")
    const [, setPersistedState] = useLocalstorage("checkout_state")

    const [params] = useUrlSearchParams()

    const onSubmit = async values => {
        const body = {
            email: values.email,
            password: values.password,
        }

        await login(dispatch, body, {
            onSuccess: () => {
                setGuestCustomer(null)
                setPersistedState(null)
                toast.dismiss("credentials_invalid")
                props.onSuccess && props.onSuccess()
            },
            onError: error => {
                if (error.status === 401)
                    return toast.error(<ErrorToast error={{ ...error.info, code: "credentials_invalid" }} />, {
                        toastId: "credentials_invalid",
                    })
                toast.error(<ErrorToast error={error} />, { toastId: error.code })
            },
        })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} name="login-form">
            <Input
                rules={{ required: { value: true, message: t("form_error_required") } }}
                control={control}
                type="text"
                name="email"
                label={t(`login_form_label_email${params?.return_url?.includes("partner") ? "_partner" : ""}`)}
                autocomplete="email"
                onChange={props.setEmailAddress || undefined}
            />
            <Input
                rules={{ required: { value: true, message: t("form_error_required") } }}
                control={control}
                type="password"
                name="password"
                label={t("login_form_label_password")}
                autocomplete="current-password"
            />
            <IconAction submit button fullWidth title={t("login_form_submit")} />
        </form>
    )
}

export default LoginForm
