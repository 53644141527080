import { A } from "../../utils/router"
import FooterMenuStyles from "./footer.module.css"
import React from "react"
import { findTranslation } from "../../utils/find-translation"
import { menu } from "../../config/footer-menu"
import { useLocalesState } from "../../context/locales"

const FooterMenu = () => {
    const { language, region } = useLocalesState()

    return (
        <div className={FooterMenuStyles.wrapper}>
            <div className={FooterMenuStyles.block}>
                {menu.map(m => {
                    if (
                        !findTranslation(m, language)?.title ||
                        findTranslation(m, language)?.hiddenRegion?.includes(region)
                    )
                        return null
                    return (
                        <A key={m.id} href={findTranslation(m, language).url} className={FooterMenuStyles.item}>
                            {findTranslation(m, language).title}
                        </A>
                    )
                })}
            </div>
        </div>
    )
}

export default React.memo(FooterMenu)
