import { LazyImage } from "../universal/image"
import type { Product } from "@lavita-io/international-sdk"
import React from "react"

interface ProductImageProps {
    product?: Product
}

const ProductImage = ({ product }: ProductImageProps) => {
    return (
        <div className="aspect-h-1 aspect-w-1">
            <LazyImage
                path={
                    product?.images.find(i => i.type === "side")?.href.replace(process.env.IMG_CDN_URL_ASSETS, "") || ""
                }
                srcSet={[{ w: 648, default: true }, { w: 1296 }]}
                sizes="648px"
                className="block"
            />
        </div>
    )
}

export default ProductImage
