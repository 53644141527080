import { PopperReducer, initialState } from "./reducer"
import React, { useReducer } from "react"

const PopperStateContext = React.createContext()
const PopperDispatchContext = React.createContext()

const usePopperState = () => {
    const context = React.useContext(PopperStateContext)
    if (context === undefined) {
        throw new Error("usePopperState must be used within a PopperProvider")
    }

    return context
}

const usePopperDispatch = () => {
    const context = React.useContext(PopperDispatchContext)
    if (context === undefined) {
        throw new Error("usePopperDispatch must be used within a PopperProvider")
    }

    return context
}

const PopperProvider = props => {
    const [state, dispatch] = useReducer(PopperReducer, initialState)

    return (
        <PopperStateContext.Provider value={state}>
            <PopperDispatchContext.Provider value={dispatch}>{props.children}</PopperDispatchContext.Provider>
        </PopperStateContext.Provider>
    )
}

export { usePopperState, usePopperDispatch, PopperProvider }
