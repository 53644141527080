import LinkButtonWrapperStyles from "./link-button-wrapper.module.css"
import React from "react"
import classNames from "classnames"

const LinkButtonWrapper = props => {
    return (
        <div
            className={classNames(LinkButtonWrapperStyles.wrapper, { [LinkButtonWrapperStyles.center]: props.center })}
        >
            {props.children}
        </div>
    )
}

export default LinkButtonWrapper
