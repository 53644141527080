import { Link, RichTextExt, markPluginConstructor, plugins, types } from "react-bricks/frontend"
import { Pictograms, pictogramOptions } from "../../types"

import { Icon } from "../components/icon"
import React from "react"
import { RenderElementProps } from "slate-react"
import { SupIcon } from "../components/svg"
import { TypographyElement } from "../Typography"

export interface StripeIconTextProps {
    icon: Pictograms
    text: TypographyElement[]
    index?: number
}

const StripeIconText: types.Brick<StripeIconTextProps> = ({ icon, index }) => {
    const { link, bold } = plugins

    const sup = markPluginConstructor({
        name: "sup",
        label: "Superscript",
        render: ({ children }) => <sup>{children}</sup>,
        icon: SupIcon,
    })

    const allowedPlugins: types.RichTextPlugin[] = [
        {
            ...link,
            renderElement: ({ children, element, attributes }: RenderElementProps) => (
                <Link
                    {...attributes}
                    href={element.data?.url || element.url}
                    target={element.data?.isTargetBlank ? "_blank" : undefined}
                    rel={element.data?.isTargetBlank ? "noopener" : undefined}
                    className="link [&:not(:has(sup))]:underline hover:[&_sup]:underline"
                >
                    {children}
                </Link>
            ),
        },
        {
            ...bold,
            renderLeaf: ({ children, attributes }) => (
                <strong {...attributes} className="text-base sm:text-sm md:text-base lg:text-md">
                    {children}
                </strong>
            ),
        },
        sup,
    ]

    return (
        <div
            key={index}
            className="relative items-center after:absolute after:-bottom-5 after:right-0 after:h-px after:w-full after:bg-border-tide last:after:h-0 sm:flex sm:px-3 sm:after:bottom-0 sm:after:h-full sm:after:w-px sm:first:pl-0 sm:last:pr-0 last:sm:after:w-0 md:px-7 lg:px-10 xl:px-16 xl:first:pl-10 xl:last:pr-10"
        >
            <Icon
                icon={icon}
                className="mb-3 text-3xl leading-none text-copperfield sm:mb-0 sm:mr-3 md:mr-5 md:text-[35px] lg:text-5xl"
            />
            <div>
                <RichTextExt
                    propName="text"
                    placeholder=""
                    renderBlock={({ children }) => (
                        <p className="text-sm sm:text-xs md:text-sm lg:text-base">{children}</p>
                    )}
                    plugins={allowedPlugins}
                />
            </div>
        </div>
    )
}

StripeIconText.schema = {
    name: "TrustStripeIconText",
    label: "Icon Text",
    hideFromAddMenu: true,
    sideEditProps: [
        {
            name: "icon",
            label: "Icon",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: pictogramOptions,
                display: types.OptionsDisplay.Select,
            },
        },
    ],
    getDefaultProps: (): StripeIconTextProps => ({
        icon: Pictograms.vitality,
        text: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc placerat sagittis faucibus.",
                    },
                ],
            },
        ],
    }),
}

export default StripeIconText
