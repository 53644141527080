import React from "react"
import classNames from "classnames"

interface StarsProps {
    rating: number
    size?: "md" | "lg"
}

const Stars = ({ rating, size = "md" }: StarsProps) => {
    return (
        <div className="flex gap-[2px] md:gap-[2px]">
            {[...Array(Math.floor(rating))].map((_, i) => (
                <div
                    className={classNames({
                        ["h-4 w-4"]: size === "md",
                        ["h-3.75 w-3.75 md:h-4.5 md:w-4.5 lg:h-5.5 lg:w-5.5"]: size === "lg",
                    })}
                    key={i}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10.623 0 8.115 7.637 0 7.64l6.566 4.72L4.06 20l6.562-4.72L17.191 20l-2.507-7.64 6.566-4.72-8.115-.003L10.623 0Z"
                            fill="#FFDC0F"
                        />
                    </svg>
                </div>
            ))}
            {rating !== 5 && (
                <div
                    className={classNames("relative", {
                        ["h-4 w-4"]: size === "md",
                        ["h-3.75 w-3.75 md:h-4.5 md:w-4.5 lg:h-5.5 lg:w-5.5"]: size === "lg",
                    })}
                >
                    <div className="absolute h-full w-full">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M10.623 0 8.115 7.637 0 7.64l6.566 4.72L4.06 20l6.562-4.72L17.191 20l-2.507-7.64 6.566-4.72-8.115-.003L10.623 0Z"
                                fill="#CCC"
                            />
                        </svg>
                    </div>
                    <div className="absolute h-full overflow-hidden" style={{ width: (rating % 1) * 100 + "%" }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 22 20"
                            className={classNames({
                                ["w-4"]: size === "md",
                                ["w-3.75 md:w-4.5 lg:w-5.5"]: size === "lg",
                            })}
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M10.623 0 8.115 7.637 0 7.64l6.566 4.72L4.06 20l6.562-4.72L17.191 20l-2.507-7.64 6.566-4.72-8.115-.003L10.623 0Z"
                                fill="#FFDC0F"
                            />
                        </svg>
                    </div>
                </div>
            )}
            {[...Array(5 - Math.floor(rating) - 1)].map((_, i) => (
                <div
                    className={classNames({
                        ["h-4 w-4"]: size === "md",
                        ["h-3.75 w-3.75 md:h-4.5 md:w-4.5 lg:h-5.5 lg:w-5.5"]: size === "lg",
                    })}
                    key={i}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10.623 0 8.115 7.637 0 7.64l6.566 4.72L4.06 20l6.562-4.72L17.191 20l-2.507-7.64 6.566-4.72-8.115-.003L10.623 0Z"
                            fill="#CCC"
                        />
                    </svg>
                </div>
            ))}
        </div>
    )
}

export default Stars
