import LoadingStyles from "./loading.module.css"
import React from "react"
import classNames from "classnames"
import { useAuthState } from "../../context/auth"
import { useDelayedUnmounting } from "../../hooks/use-delayed-unmounting"
import { useIsMutating } from "@tanstack/react-query"
import { useIsSSR } from "../../hooks/use-is-ssr"
import { useLoadingState } from "../../context/loading"
import { useLocalesState } from "../../context/locales"

const Loading = props => {
    const { loading: authLoading, reAuthLoading } = useAuthState()
    const { loading: localesLoading } = useLocalesState()
    const { loading, queryStatus } = useLoadingState()
    const isMutating = useIsMutating()
    const isSSR = useIsSSR()

    const shouldRender = useDelayedUnmounting(
        authLoading ||
            localesLoading ||
            loading ||
            Object.values(queryStatus).some(s => s.status === "loading" && s.fetchStatus !== "idle") ||
            isMutating ||
            (!props.disableReAuth && reAuthLoading) ||
            (!props.disableSSR && isSSR) ||
            props.loading
    )

    if (!shouldRender && !(!props.disableSSR && isSSR)) return null

    return (
        <section className={classNames(LoadingStyles.container, { [LoadingStyles.blur]: props.blur })}>
            <div className={LoadingStyles.spinner} />
        </section>
    )
}

Loading.defaultProps = {
    blur: true,
}

export default Loading
