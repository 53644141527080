import { Align, Color, TypographyAlign, TypographyColor } from "../components/typography"
import { Background, BackgroundColor, Container, Spacer, SpacerVariant, backgroundOptions } from "../components/layout"
import React, { useState } from "react"
import { Repeater, types } from "react-bricks/frontend"
import Typography, { TypographyElement } from "../Typography"

interface FAQsProps {
    background: { color: BackgroundColor } | BackgroundColor
    text: TypographyElement[]
    spacerTop: boolean
    spacerBottom: boolean
}

const FAQs: types.Brick<FAQsProps> = ({ background, spacerTop, spacerBottom }) => {
    const [isOpen, setIsOpen] = useState(0)

    const bgColor = typeof background === "object" ? background.color : background

    return (
        <Background color={bgColor}>
            <Container className="sm:px-20 md:px-28 lg:px-40 xl:max-w-[1008px] xl:px-0">
                <Spacer variant={!spacerTop ? SpacerVariant.None : undefined} />
                <Color
                    color={
                        bgColor === BackgroundColor.Green || bgColor === BackgroundColor.DarkBlumine
                            ? TypographyColor.White
                            : TypographyColor.Darkgrey
                    }
                >
                    <Align align={TypographyAlign["Center + mobile left"]}>
                        <Typography block="copytext-small" allowList={["h3", "small", "sup", "link"]} />
                    </Align>
                    <Spacer variant={SpacerVariant.Small} />
                    <div>
                        <Repeater propName="repeater" itemProps={{ bgColor: bgColor, setIsOpen, isOpen }} />
                    </div>
                </Color>
                <Spacer variant={!spacerBottom ? SpacerVariant.None : undefined} />
            </Container>
        </Background>
    )
}

FAQs.schema = {
    name: "FAQs",
    label: "FAQs",
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "FAQ",
                    label: "FAQ",
                    min: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "background",
            label: "Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: backgroundOptions,
                display: types.OptionsDisplay.Color,
            },
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: (): FAQsProps => ({
        background: { color: BackgroundColor.White },
        text: [
            {
                type: "h3",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet",
                    },
                ],
            },
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc placerat sagittis faucibus.",
                    },
                ],
            },
        ],
        spacerTop: true,
        spacerBottom: true,
    }),
}

export default FAQs
