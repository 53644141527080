import { A } from "../../utils/router"
import Breadcrumbs from "../universal/breadcrumbs"
import FooterStyles from "./footer.module.css"
import { LazyImage } from "../universal/image"
import React from "react"
import ReactMarkdown from "react-markdown"
import classNames from "classnames"
import { useAuthState } from "../../context/auth"
import { useLocalesState } from "../../context/locales"
import { useTranslation } from "react-i18next"

const Footer = props => {
    const { t } = useTranslation("shared")
    const { isPartner } = useAuthState()
    const { region, language } = useLocalesState()

    return (
        <footer className={FooterStyles.footer}>
            <div className={FooterStyles.container}>
                <div className={classNames(FooterStyles.breadcrumbs, { [FooterStyles.flush]: !props.menu })}>
                    <div className={FooterStyles.logo}>
                        <LazyImage
                            url="assets"
                            path="/logo/LaVita_Logo_2020.png"
                            srcSet={[{ w: 70 }, { w: 92, default: true }, { w: 140 }, { w: 184 }]}
                            sizes="(max-width: 1023px) 70px, 92px"
                        />
                    </div>
                    {props.configuration.breadcrumbs && (
                        <>
                            {props.breadcrumbs && <i className="lavita-icon"></i>}
                            <Breadcrumbs breadcrumbs={props.breadcrumbs} monochrome />
                        </>
                    )}
                </div>
                <div className={classNames(FooterStyles.flex, { [FooterStyles.noMenu]: !props.menu })}>
                    {props.menu}
                    <div className={FooterStyles.info}>
                        {props.configuration.contact && (
                            <div className={FooterStyles.info}>
                                <div>
                                    <ReactMarkdown>{t("footer_info_contact")}</ReactMarkdown>
                                </div>
                                <div>
                                    <ReactMarkdown>
                                        {props.configuration.customContact
                                            ? t("footer_info_custom_consulting", {
                                                  opening_hours: t("footer_info_default_opening_hours"),
                                                  ...props.configuration.customContact,
                                              })
                                            : t(
                                                  isPartner && region !== "CH"
                                                      ? "footer_info_consulting_partner"
                                                      : "footer_info_consulting"
                                              )}
                                    </ReactMarkdown>
                                </div>
                            </div>
                        )}
                        <div className={FooterStyles.info}>
                            {props.configuration.socialMedia && (
                                <div>
                                    <p>
                                        <strong>{t("footer_label_social")}</strong>
                                    </p>
                                    <div className={FooterStyles.social}>
                                        <a href={t("footer_social_fb")}>
                                            <i className="lavita-icon"></i>
                                        </a>
                                        <a href={t("footer_social_instagram")}>
                                            <i className="lavita-icon"></i>
                                        </a>
                                        <a href={t("footer_social_youtube")}>
                                            <i className="lavita-icon"></i>
                                        </a>
                                    </div>
                                    {props.configuration.app && (
                                        <div>
                                            <p>
                                                <strong>{t("footer_label_app")}</strong>
                                            </p>
                                            <div className={FooterStyles.app}>
                                                <a
                                                    href="https://play.google.com/store/apps/details?id=de.lavita.lavitaapp"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <LazyImage
                                                        url="assets"
                                                        path="/lavita.de/app/google-play-badge-ai.png"
                                                    />
                                                </a>
                                                <a
                                                    href="https://apps.apple.com/de/app/lavita/id1484793998"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <LazyImage url="assets" path="/lavita.de/app/3327417.svg" />
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                    {props.configuration.trustedShop &&
                                        process.env.TRUSTED_SHOPS_ID &&
                                        region === "DE" &&
                                        language === "de" && (
                                            <div>
                                                <p>
                                                    <strong>{t("footer_label_trusted_shop")}</strong>
                                                </p>
                                                <div className={FooterStyles.trustedShopWrapper}>
                                                    <div
                                                        className={FooterStyles.trustedShop}
                                                        id="trustbadgeFooter"
                                                        style={{ width: 156, height: 78, marginBottom: 10 }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={FooterStyles.divider} />
            <div className={FooterStyles.container}>
                <div className={FooterStyles.legal}>
                    <div className={FooterStyles.links}>
                        {region !== "TR" && (
                            <A href={`${process.env.WEB_URL}/${t("footer_legalnotice_path")}`}>
                                {t("footer_legalnotice")}
                            </A>
                        )}
                        <A href={`${process.env.WEB_URL}/${t("footer_dataprotection_path")}`}>
                            {t("footer_dataprotection")}
                        </A>
                        <A href={`${process.env.WEB_URL}/${t("footer_terms_and_conditions_path")}`}>
                            {t("footer_terms_and_conditions")}
                        </A>
                        {region !== "TR" && (
                            <A href={`${process.env.WEB_URL}/${t("footer_revocation_path")}`}>
                                {t("footer_revocation")}
                            </A>
                        )}
                        {typeof window !== "undefined" && window.CCM && (
                            <button onClick={() => window.CCM.openControlPanel()}>{t("footer_cookie")}</button>
                        )}
                        <A href={`${process.env.WEB_URL}/${t("footer_contact_path")}`}>{t("footer_contact")}</A>
                        {props.sitemap}
                        {region === "DE" && <A href={t("footer_whistleblower_path")}>{t("footer_whistleblower")}</A>}
                    </div>
                    <div className={FooterStyles.copyright}>
                        <p>
                            © {new Date().getFullYear()} {t("footer_copyright")}
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

Footer.defaultProps = {
    configuration: {
        trustedShop: true,
        socialMedia: true,
        app: true,
        contact: true,
        breadcrumbs: true,
    },
    menu: null,
    sitemap: null,
}

export default Footer
