const noop = () => {}

const listeners = {
    popstate: [],
}

const mockWindow = {
    URL: function() {
        this.searchParams = {
            set: noop,
            append: noop,
            delete: noop,
        }
    },
    location: {
        href: "",
        search: "",
    },
    history: {
        pushState: noop,
        replaceState: noop,
    },
    fireEvent: eventName => {
        listeners[eventName].forEach(listener => {
            listener()
        })
    },
    addEventListener: (eventName, listener) => {
        listeners[eventName].push(listener)
    },
    removeEventListener: (eventName, listener) => {
        const eventListeners = listeners[eventName]
        const i = eventListeners.findIndex(l => l === listener)
        eventListeners.splice(i, 1)
    },
}

const getWindow = () => {
    if (typeof window !== "undefined") return window
    return mockWindow
}

export { getWindow }
