import { useEffect, useState } from "react"

const useIsApp = () => {
    const [inApp, setInApp] = useState(false)

    useEffect(() => {
        setInApp(/lavapp/.test(window.navigator.userAgent))
    }, [])

    return inApp
}

export { useIsApp }
