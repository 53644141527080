import { updateQueryStatus, useLoadingDispatch } from "../context/loading"
import { useInfiniteQueryWithErrorHandling, useQueryWithErrorHandling } from "./use-query-with-error-handling"

import { useEffect } from "react"

const useQueryLoading = (queryKey, queryFn, queryOptions, loadingOptions) => {
    const dispatch = useLoadingDispatch()

    const query = useQueryWithErrorHandling(queryKey, queryFn, queryOptions)

    const { status, fetchStatus } = query

    useEffect(() => {
        if (loadingOptions?.background) return

        updateQueryStatus(dispatch, { key: queryKey, status, fetchStatus })

        return () => updateQueryStatus(dispatch, { key: queryKey, status: "removed" })
    }, [status, fetchStatus, dispatch, queryKey, loadingOptions])

    return query
}

const useInfiniteQueryLoading = (queryKey, queryFn, queryOptions, loadingOptions) => {
    const dispatch = useLoadingDispatch()

    const query = useInfiniteQueryWithErrorHandling(queryKey, queryFn, queryOptions)

    const { status, fetchStatus } = query

    useEffect(() => {
        if (loadingOptions?.background) return

        updateQueryStatus(dispatch, { key: queryKey, status, fetchStatus })

        return () => updateQueryStatus(dispatch, { key: queryKey, status: "removed" })
    }, [status, fetchStatus, dispatch, queryKey, loadingOptions])

    return query
}

export { useQueryLoading, useInfiniteQueryLoading }
