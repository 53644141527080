const openPopper = dispatch => {
    dispatch({ type: "OPEN_POPPER" })
    document.body.classList.add("noScrollMobile")
    return
}

const closePopper = dispatch => {
    dispatch({ type: "CLOSE_POPPER" })
    document.body.classList.remove("noScrollMobile")
    return
}

export { openPopper, closePopper }
