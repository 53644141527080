import { Repeater, types } from "react-bricks/frontend"

import React from "react"

export interface TableRowProps {
    cells: {
        text: string
    }[]
    isHeader?: boolean
}

const TableRow: types.Brick<TableRowProps> = ({ isHeader }) => {
    {
        return isHeader ? (
            <thead>
                <tr className="rounded-t-3xl bg-calypso text-white">
                    <Repeater propName="cells" />
                </tr>
            </thead>
        ) : (
            <tr className="border border-solid border-border-tide odd:bg-background-tide sm:last:rounded-b-lg sm:last:shadow-[0_1px_0_#e5e2de,_-1px_0_0_#e5e2de,_1px_0_0_#e5e2de]">
                <Repeater propName="cells" />
            </tr>
        )
    }
}

TableRow.schema = {
    name: "TableRow",
    label: "Row",
    hideFromAddMenu: true,
    getDefaultProps: () => ({
        cells: [
            {
                text: "Cell",
            },
            {
                text: "Cell",
            },
        ],
    }),
    repeaterItems: [
        {
            name: "cells",
            itemType: "TableCell",
            min: 1,
            max: 2,
        },
    ],
    sideEditProps: [
        {
            name: "isHeader",
            label: "Is Header",
            type: types.SideEditPropType.Boolean,
        },
    ],
}

export default TableRow
