import React, { MutableRefObject, useEffect, useRef } from "react"
import { Stream, StreamPlayerApi } from "@cloudflare/stream-react"

interface CloudflarePlayerProps {
    id?: string
    autoplay?: boolean
    mute?: boolean
    loop?: boolean
    onProgress?: (progress: number) => void
    onEnded?: () => void
    streamRef?: MutableRefObject<StreamPlayerApi | undefined>
}

const CloudflarePlayer = ({
    id,
    autoplay = true,
    mute = true,
    loop = true,
    onProgress,
    onEnded,
    streamRef,
}: CloudflarePlayerProps) => {
    const internalRef = useRef<StreamPlayerApi>()
    const ref = streamRef ?? internalRef

    useEffect(() => {
        if (!onProgress) return

        let interval: ReturnType<typeof setInterval>

        interval = setInterval(() => {
            const duration = ref.current?.duration || 1
            const played = ref.current?.currentTime || 0
            onProgress(played / duration)
        }, 100)

        return () => clearInterval(interval)
    }, [ref, onProgress])

    if (!id) return null

    return (
        <Stream
            src={id + "/iframe"}
            customerCode="powg83vp4off8fm9"
            muted={mute}
            loop={loop}
            streamRef={streamRef}
            autoplay={autoplay}
            onEnded={onEnded}
            responsive={false}
            width="100%"
            height="100%"
            letterboxColor="transparent"
            poster=""
        />
    )
}

export default CloudflarePlayer
