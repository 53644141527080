import { options } from "./options"

export enum HeaderBackground {
    White = "#fff",
    Transparent = "transparent",
}

export enum HeaderStyle {
    Positive = "positive",
    Negative = "negative",
}

export const headerBackgroundOptions = options<typeof HeaderBackground>(HeaderBackground)

export const headerStyleOptions = options<typeof HeaderStyle>(HeaderStyle)
