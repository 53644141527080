import React, { useState } from "react"
import { closePopper, openPopper, usePopperDispatch } from "../../context/popper"

import PopperStyles from "./popper.module.css"
import classNames from "classnames"
import { throttle } from "../../utils/throttle"
import { useDelayedUnmounting } from "../../hooks/use-delayed-unmounting"
import { useDimensions } from "../../hooks/use-dimensions"
import { useEffect } from "react"
import { useLayoutEffect } from "react"
import { useOnClickOutside } from "../../hooks/use-on-click-outside"
import { usePopper } from "react-popper"

const Popper = props => {
    const [showPopper, setShowPopper] = useState(false)
    const [referenceElement, setReferenceElement] = useState(null)
    const [popperElement, setPopperElement] = useState(null)
    const [arrowElement, setArrowElement] = useState(null)
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        modifiers: [
            { name: "arrow", options: { element: arrowElement } },
            { name: "offset", options: { offset: [10, 10] } },
            { ...props.modifiers },
        ],
        placement: props.placement || "bottom-end",
    })
    const shouldRenderChild = useDelayedUnmounting(showPopper)

    const dispatch = usePopperDispatch()

    const toggleShowPopper = showPopper => {
        setShowPopper(showPopper)
        if (showPopper) return openPopper(dispatch)

        closePopper(dispatch)
        props.closePopper && props.closePopper()
    }

    useOnClickOutside(isInside => toggleShowPopper(isInside), [referenceElement, popperElement])

    const { width } = useDimensions()
    const [headerBottom, setHeaderBottom] = useState(0)

    useLayoutEffect(() => {
        const measure = () => setHeaderBottom(document.getElementById("header")?.getBoundingClientRect().bottom)

        measure()
        const throttledMeasure = throttle(measure, 100)
        window.addEventListener("resize", throttledMeasure)
        window.addEventListener("scroll", measure)
        return () => {
            window.removeEventListener("resize", throttledMeasure)
            window.removeEventListener("scroll", measure)
        }
    }, [])

    const { closePopperDependency } = props

    useEffect(() => {
        if (!closePopperDependency) return

        toggleShowPopper(false)
    }, [closePopperDependency])

    return (
        <>
            {props.customInput instanceof Function ? (
                props.customInput({ ref: setReferenceElement, onClick: () => toggleShowPopper(!showPopper) })
            ) : (
                <button
                    type="button"
                    ref={setReferenceElement}
                    onClick={() => toggleShowPopper(!showPopper)}
                    className={PopperStyles.button}
                >
                    <i className="lavita-icon"></i>
                </button>
            )}
            {shouldRenderChild && (
                <div
                    ref={setPopperElement}
                    style={width > 1023 ? styles.popper : { "--header-height": `${headerBottom || 0}px` }}
                    className={classNames(PopperStyles.popper, {
                        [PopperStyles.mounted]: showPopper,
                        [PopperStyles.left]: props.left,
                    })}
                    {...attributes.popper}
                >
                    <div ref={setArrowElement} style={styles.arrow} className={PopperStyles.triangle} />
                    <div className={PopperStyles.close}>
                        <button type="button" onClick={() => toggleShowPopper(false)}>
                            <div className={PopperStyles.line} />
                            <div className={PopperStyles.line} />
                        </button>
                    </div>
                    <div
                        className={classNames(PopperStyles.content, {
                            [PopperStyles.showOverflow]: props.showOverflow,
                        })}
                        style={{ width: props.width }}
                    >
                        {props.children instanceof Function
                            ? props.children(() => toggleShowPopper(false))
                            : props.children}
                    </div>
                </div>
            )}
        </>
    )
}

export default Popper
