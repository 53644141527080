import CartItemStyles from "./cart-item.module.css"
import { Image } from "../universal/image"
import React from "react"
import classNames from "classnames"

const CartItem = props => {
    return (
        <div className={classNames(CartItemStyles.block, { [CartItemStyles.error]: props.error })}>
            <div className={CartItemStyles.image}>
                {props.images.find(i => i.type === "main") && (
                    <Image
                        url="assets"
                        path={props.images
                            .find(i => i.type === "main")
                            .href.replace(process.env.IMG_CDN_URL_ASSETS, "")}
                        srcSet={[{ w: 60, default: true }, { w: 120 }]}
                        sizes="60px"
                    />
                )}
            </div>
            <div className={CartItemStyles.item}>
                <p>{props.name}</p>
                <span>{props.quantity}x</span>
            </div>
        </div>
    )
}

export default CartItem
