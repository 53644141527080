import React from "react"
import { RenderElementProps } from "slate-react"

const CopytextExtraLarge = ({ children, attributes }: RenderElementProps) => {
    return (
        <p {...attributes} className="mb-4 text-md last:mb-0 sm:mb-5 sm:text-lg lg:text-xl xl:text-2xl">
            {children}
        </p>
    )
}

export default CopytextExtraLarge
