import { getCookie, setCookie } from "./utils/cookies"

export class Configuration {
    constructor(options, storage) {
        this.options = options
        this.storage = storage

        let dehydratedConfig = {}
        if (storage) {
            dehydratedConfig = this.dehydrate(storage)
        }

        this.internalConfiguration = dehydratedConfig
    }

    // ACCESSORS =================================================================

    get consumerToken() {
        return this.internalConfiguration.consumerToken
    }

    set consumerToken(token) {
        this.partialUpdate({ consumerToken: token })
        window.dispatchEvent(new CustomEvent("legacy_sdk_config_update"))
    }

    get customerWeakToken() {
        return this.getCookieData("customerWeakToken")
    }

    set customerWeakToken(token) {
        if (this.options.crossDomain) {
            setCookie(`${this.options.storageKey}-customerWeakToken`, token, this.options.crossDomain)
        }
        this.partialUpdate({ customerWeakToken: token })
        window.dispatchEvent(new CustomEvent("legacy_sdk_config_update"))
    }

    get customerStrongToken() {
        return this.getCookieData("customerStrongToken")
    }

    set customerStrongToken(token) {
        if (this.options.crossDomain) {
            setCookie(`${this.options.storageKey}-customerStrongToken`, token, this.options.crossDomain)
        }
        this.partialUpdate({ customerStrongToken: token })
        window.dispatchEvent(new CustomEvent("legacy_sdk_config_update"))
    }

    // CUSTOMER ==================================================================

    get customer_id() {
        return this.getCookieData("customer_id")
    }

    set customer_id(id) {
        if (this.options.crossDomain) {
            setCookie(`${this.options.storageKey}-customer_id`, id, this.options.crossDomain)
        }
        this.partialUpdate({ customer_id: id })
        window.dispatchEvent(new CustomEvent("legacy_sdk_config_update"))
    }

    get email() {
        return this.internalConfiguration.email
    }

    set email(email) {
        this.partialUpdate({ email: email })
        window.dispatchEvent(new CustomEvent("legacy_sdk_config_update"))
    }

    // CART ======================================================================

    get cart_id() {
        return this.getCookieData("cart_id")
    }

    set cart_id(id) {
        if (this.options.crossDomain) {
            setCookie(`${this.options.storageKey}-cart_id`, id, this.options.crossDomain)
        }
        this.partialUpdate({ cart_id: id })
        window.dispatchEvent(new CustomEvent("legacy_sdk_config_update"))
    }

    get cart_access_token() {
        return this.getCookieData("cart_access_token")
    }

    set cart_access_token(token) {
        if (this.options.crossDomain) {
            setCookie(`${this.options.storageKey}-cart_access_token`, token, this.options.crossDomain)
        }
        this.partialUpdate({ cart_access_token: token })
        window.dispatchEvent(new CustomEvent("legacy_sdk_config_update"))
    }

    get historic_cart_id() {
        return this.internalConfiguration.historic_cart_id
    }

    set historic_cart_id(id) {
        this.partialUpdate({ historic_cart_id: id })
        window.dispatchEvent(new CustomEvent("legacy_sdk_config_update"))
    }

    get historic_cart_access_token() {
        return this.internalConfiguration.historic_cart_access_token
    }

    set historic_cart_access_token(token) {
        this.partialUpdate({ historic_cart_access_token: token })
        window.dispatchEvent(new CustomEvent("legacy_sdk_config_update"))
    }

    // HELPER METHODS ============================================================

    partialUpdate(config) {
        this.internalConfiguration = {
            ...this.internalConfiguration,
            ...config,
        }

        this.hydrate(this.internalConfiguration)
    }

    getCookieData(key) {
        if (!this.options.crossDomain) return this.internalConfiguration[key]

        const cookieData = getCookie(`${this.options.storageKey}-${key}`)
        if (cookieData !== this.internalConfiguration[key]) {
            this.partialUpdate({ [key]: cookieData })
            return cookieData
        }
        return this.internalConfiguration[key]
    }

    // STORAGE METHODS ===========================================================

    hydrate(config) {
        if (!this.storage) {
            return
        }

        this.storage.setItem(this.options.storageKey, JSON.stringify(config))
    }

    dehydrate = storage => {
        if (!storage) {
            return {}
        }

        const nativeValue = storage.getItem(this.options.storageKey)

        if (!nativeValue) {
            return {}
        }

        try {
            return JSON.parse(nativeValue)
        } catch (err) {
            return {}
        }
    }
}
