import { Link as GatsbyLink } from "gatsby"
import { Icons } from "../../../types/icons"
import { Link } from "react-bricks/frontend"
import React from "react"
import classNames from "classnames"

export enum TextLinkSize {
    Small = "sm",
    Base = "base",
}

export enum TextLinkColor {
    Blue = "blue",
    White = "white",
}

interface TextLinkProps {
    href?: string
    target?: "_blank" | "_self"
    title: string
    icon?: Icons
    iconDisplay?: boolean
    size?: TextLinkSize
    color?: TextLinkColor
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    withOnClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
    to?: string
}

const TextLink = ({
    href,
    target,
    title,
    iconDisplay = true,
    icon = Icons.link,
    size = TextLinkSize.Base,
    color = TextLinkColor.Blue,
    onClick,
    withOnClick,
    to,
}: TextLinkProps) => {
    const classes = classNames("inline-flex items-center", {
        "text-blue": color === TextLinkColor.Blue,
        "text-white": color === TextLinkColor.White,
    })

    const innerContent = (
        <>
            {iconDisplay && (
                <i
                    className={classNames("lavita-icon mr-2.5 text-md sm:mr-3", {
                        "sm:text-lg lg:text-xl": size === TextLinkSize.Base,
                        "lg:text-lg": size === TextLinkSize.Small,
                    })}
                >
                    {icon}
                </i>
            )}
            <span
                className={classNames("underline", {
                    "text-md sm:text-lg lg:text-xl": size === TextLinkSize.Base,
                    "text-base sm:text-md lg:text-lg": size === TextLinkSize.Small,
                })}
            >
                {title}
            </span>
        </>
    )

    return onClick ? (
        <button type="button" className={classes} onClick={onClick}>
            {innerContent}
        </button>
    ) : to ? (
        <GatsbyLink to={to} className={classes} onClick={withOnClick}>
            {innerContent}
        </GatsbyLink>
    ) : href ? (
        <Link
            href={href}
            target={target}
            rel={target === "_blank" ? "noopener noreferrer" : undefined}
            className={classes}
            onClick={withOnClick}
        >
            {innerContent}
        </Link>
    ) : null
}

export default TextLink
