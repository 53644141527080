import React, { useRef } from "react"
import { closeOverlay, useOverlayDispatch, useOverlayState } from "../../context/overlay"

import OverlayStyles from "./overlay.module.css"
import classNames from "classnames"
import { useDimensions } from "../../hooks/use-dimensions"
import { useOnClickOutside } from "../../hooks/use-on-click-outside"

const Overlay = () => {
    const overlayRef = useRef(null)

    const { showOverlay, content, header, isVideo, disableOnClickOutside, disableClose } = useOverlayState()
    const dispatch = useOverlayDispatch()

    const { height, width } = useDimensions()

    useOnClickOutside(
        isInside => !isInside && !disableOnClickOutside && showOverlay && closeOverlay(dispatch),
        [overlayRef],
        [showOverlay]
    )

    /* magic Numbers from CSS mostly paddings from the parents */
    const maxHeight =
        height -
        (width >= 1366 ? 330 : width >= 768 ? 290 : 200) -
        (header ? (width >= 1366 ? 88 : width >= 768 ? 65 : 61) : 0)

    return (
        <div className={OverlayStyles.container} style={{ display: !showOverlay ? "none" : undefined }}>
            <div
                className={classNames({
                    [OverlayStyles.overlay]: !isVideo,
                    [OverlayStyles.withHeader]: header,
                    [OverlayStyles.videoOverlay]: isVideo,
                })}
                ref={overlayRef}
            >
                {showOverlay && (
                    <>
                        {header && (
                            <div className={OverlayStyles.header}>
                                <div>{header}</div>
                            </div>
                        )}
                        {!disableClose && (
                            <button
                                className={OverlayStyles.close}
                                type="button"
                                onClick={() => closeOverlay(dispatch)}
                            >
                                <i className="lavita-icon"></i>
                            </button>
                        )}
                        <div
                            className={classNames({ [OverlayStyles.content]: !isVideo })}
                            style={{ maxHeight: maxHeight || undefined }}
                        >
                            {content}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default Overlay
