const setCookie = (name, value, maxAge = 157680000, domain = process.env.COOKIE_DOMAIN, path = "/", sameSite = "Strict") => {
    document.cookie = `${name}=${value}; max-age=${maxAge}; domain=${domain}; path=${path}; secure; SameSite=${sameSite}`
}

const getCookie = name => {
    return document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || undefined
}

const getCookies = cookies => {
    let cookieValues = {}
    Object.keys(cookies).forEach(key => (cookieValues[key] = getCookie(cookies[key])))
    return cookieValues
}

const deleteCookie = (name, domain = process.env.COOKIE_DOMAIN, path = "/", sameSite = "Strict") => {
    document.cookie = `${name}=${undefined}; max-age=0; domain=${domain}; path=${path}; secure; SameSite=${sameSite}`
}

export { setCookie, getCookie, getCookies, deleteCookie }
