const initialState = {
    overlayOpen: false,
    loading: false,
    error: null,
}

const LocalesReducer = (initialState, action) => {
    switch (action.type) {
        case "PATCH_REGION":
            return {
                ...initialState,
                region: action.payload.region,
            }
        case "PATCH_LANGUAGE":
            return {
                ...initialState,
                loading: true,
            }
        case "PATCH_SLUG":
            return {
                ...initialState,
                slug: action.payload.slug,
            }
        case "LANGUAGE_SUCCESS":
            return {
                ...initialState,
                language: action.payload.language,
                loading: false,
            }
        case "TOGGLE_OVERLAY":
            return {
                ...initialState,
                overlayOpen: !initialState.overlayOpen,
            }
        case "CLOSE_OVERLAY":
            return {
                ...initialState,
                overlayOpen: false,
            }
        case "ERROR":
            return {
                ...initialState,
                error: action.error,
            }

        default:
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}

export { LocalesReducer, initialState }
