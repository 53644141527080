import React from "react"
import { RenderElementProps } from "slate-react"

const QuoteTextImage = ({ children, attributes }: RenderElementProps) => {
    return (
        <p {...attributes} className="mb-5 text-xl last:mb-0 md:mb-7 md:text-3xl lg:text-5xl lg:leading-5xl xl:mb-10">
            {children}
        </p>
    )
}

export default QuoteTextImage
