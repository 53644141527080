import { Background, BackgroundColor, Container, Spacer, SpacerVariant, backgroundGreyScaleColorsOptions, } from "../components/layout"
import { Repeater, types } from "react-bricks/frontend"

import React from "react"

interface SmallprintItemProps {
    background: { color: BackgroundColor} | BackgroundColor
}

const Smallprint: types.Brick<SmallprintItemProps> = ({ background }) => {
    const bgColor = typeof background === "object" ? background.color : background

    return (
        <Background color={bgColor}>
            <Spacer variant={SpacerVariant.Small} />
            <Container>
                <Repeater propName="repeater" />
            </Container>
            <Spacer variant={SpacerVariant.Small} />
        </Background>
    )
}

Smallprint.schema = {
    name: "Smallprint",
    label: "Smallprint",
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "SmallprintItem",
                    label: "Smallprint Item",
                    min: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "background",
            label: "Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: backgroundGreyScaleColorsOptions,
                display: types.OptionsDisplay.Color,
            },
        },
    ],
    getDefaultProps: (): SmallprintItemProps => ({
        background: { color: BackgroundColor.Grey },
    }),
}

export default Smallprint
