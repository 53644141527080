import { RefObject, useCallback, useEffect, useRef, useState } from "react"

const useSlider = (length: number, sliderRef: RefObject<HTMLDivElement | null>, startIndex = 0) => {
    const [index, setIndex] = useState(startIndex)
    const [lastIndex, setLastIndex] = useState(startIndex)
    const [transition, setTransition] = useState(true)
    const dragStart = useRef(0)
    const dragStartTime = useRef(new Date())
    const mouseDown = useRef(false)
    const slideWidth = useRef(0)
    const clientXStart = useRef(0)
    const clientX = useRef(0)

    useEffect(() => {
        setIndex(startIndex)
        setLastIndex(startIndex)
    }, [startIndex])

    const goTo = useCallback(
        (index: number) => {
            if (index < 0) {
                index = 0
            } else if (index >= length) {
                index = length - 1
            }
            setIndex(index)
            setLastIndex(index)
        },
        [length]
    )

    const getDragX = (event: React.TouchEvent | React.MouseEvent) => {
        const mouseEvent = event as React.MouseEvent
        if (mouseEvent.pageX) {
            return mouseEvent.pageX
        }
        return (event as React.TouchEvent).touches[0].pageX
    }

    const handleDragStart = (event: React.TouchEvent | React.MouseEvent) => {
        if (length <= 1) return

        const x = getDragX(event)

        dragStart.current = x
        dragStartTime.current = new Date()
        slideWidth.current = sliderRef.current?.offsetWidth || 0
        setTransition(false)
        if ((event as React.MouseEvent).pageX) mouseDown.current = true
    }

    const handleDragMove = (event: React.TouchEvent | React.MouseEvent) => {
        if (length <= 1) return
        if ((event as React.MouseEvent).pageX && !mouseDown.current) return

        event.preventDefault()

        const x = getDragX(event)
        if (x !== 0) {
            const offset = dragStart.current - x
            const percentageOffset = offset / slideWidth.current
            const newIndex = lastIndex + percentageOffset

            const SCROLL_OFFSET_TO_STOP_SCROLL = 30

            // Stop scrolling if you slide more than 30 pixels
            if (Math.abs(offset) > SCROLL_OFFSET_TO_STOP_SCROLL) {
                event.stopPropagation()
            }

            setIndex(newIndex)
        }
    }

    const handleDragEnd = (event: React.TouchEvent | React.MouseEvent) => {
        if (length <= 1) return
        if ((event as React.MouseEvent).pageX && !mouseDown.current) return

        const timeElapsed = new Date().getTime() - dragStartTime.current.getTime()
        const offset = lastIndex - index
        const velocity = Math.round((offset / timeElapsed) * 10000)

        let newIndex = Math.round(index)

        if (Math.abs(velocity) > 5) {
            newIndex = velocity < 0 ? lastIndex + 1 : lastIndex - 1
        }

        if (newIndex < 0) {
            newIndex = 0
        } else if (newIndex >= length) {
            newIndex = length - 1
        }

        dragStart.current = 0
        setIndex(newIndex)
        setLastIndex(newIndex)
        setTransition(true)
        mouseDown.current = false
    }

    useEffect(() => {
        const touchStart = (event: TouchEvent) => {
            clientXStart.current = event.touches[0].clientX
        }

        const preventTouch = (event: TouchEvent) => {
            const minValue = 30 // threshold

            clientX.current = event.touches[0].clientX - clientXStart.current

            // Vertical scrolling does not work when you start swiping horizontally.
            if (Math.abs(clientX.current) > minValue) {
                event.preventDefault()
                return false
            }
        }

        const slider = sliderRef.current
        if (slider) {
            slider.addEventListener("touchstart", touchStart)
            slider.addEventListener("touchmove", preventTouch, {
                passive: false,
            })
        }
        return () => {
            if (slider) {
                slider.removeEventListener("touchstart", touchStart)
                slider.removeEventListener("touchmove", preventTouch)
            }
        }
    }, [])

    return {
        index,
        lastIndex,
        transition,
        goTo,
        handleDragEnd,
        handleDragMove,
        handleDragStart,
    }
}

export { useSlider }
