import type { Product } from "@lavita-io/international-sdk"
import { formatPrice } from "../shared/utils/format"
import i18n from "../i18n/config"

const smallprint = (language: string, product?: Product) => {
    if (!product) return

    return !["Liter", "litre"].includes(product.basic_price.unit)
        ? i18n.t("price_incl_vat")
        : product.subscription
        ? i18n.t("price_per_litre_subscription", {
              basic_price: formatPrice(product.basic_price.amount, product.price.currency, language),
              amount: product.subscription.max_quantity,
              price: formatPrice(
                  (product.basic_price.amount * (product.subscription.max_quantity - 1)) /
                      product.subscription.max_quantity,
                  product.price.currency,
                  language
              ),
          })
        : i18n.t("price_per_litre", {
              price: formatPrice(product.basic_price.amount, product.price.currency, language),
          })
}

export { smallprint }
