import client from "../../utils/client"
import { setCookie } from "../../utils/cookie"

const changeLanguage = async (dispatch, body) => {
    try {
        dispatch({ type: "PATCH_LANGUAGE" })
        if (client.config.customer_id) {
            await client.updateCustomer(
                client.config.customer_id,
                { lang: body.language },
                "customerWeakToken"
            )
        }
        setCookie("__language__", body.language)
        dispatch({ type: "LANGUAGE_SUCCESS", payload: { language: body.language } })
    } catch (error) {
        dispatch({ type: "ERROR", error: error })
    }
}

const changeRegion = (dispatch, body) => {
    try {
        setCookie("__region__", body.region)
        dispatch({ type: "PATCH_REGION", payload: { region: body.region } })
    } catch (error) {
        dispatch({ type: "ERROR", error: error })
    }
}

const changeLanguageAndRegion = async (dispatch, body) => {
    try {
        changeRegion(dispatch, body)
        await changeLanguage(dispatch, { language: body.language })
    } catch (error) {
        dispatch({ type: "ERROR", error: error })
    }
}

const changeSlug = (dispatch, body) => {
    dispatch({ type: "PATCH_SLUG", payload: { slug: body.slug } })
}

const toggleOverlay = (dispatch) => {
    dispatch({ type: "TOGGLE_OVERLAY" })
}

const closeOverlay = (dispatch) => {
    dispatch({ type: "CLOSE_OVERLAY" })
}

export { changeLanguage, changeRegion, changeLanguageAndRegion, changeSlug, toggleOverlay, closeOverlay }
