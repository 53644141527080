import { useEffect, useState } from "react"

import { throttle } from "../utils/throttle"

const useDimensions = ref => {
    const [height, setHeight] = useState(measureHeight(ref))
    const [width, setWidth] = useState(measureWidth(ref))

    const wasRenderedOnClientAtLeastOnce = useWasRenderedOnClientAtLeastOnce()

    useEffect(() => {
        if (!wasRenderedOnClientAtLeastOnce) return

        const setMeasuredDimensions = () => {
            const measuredHeight = measureHeight(ref)
            const measuredWidth = measureWidth(ref)
            if (height !== measuredHeight) setHeight(measuredHeight)
            if (width !== measuredWidth) setWidth(measuredWidth)
        }

        setMeasuredDimensions()
        const throttledHandleWindowResize = throttle(setMeasuredDimensions, 100)
        window.addEventListener("resize", throttledHandleWindowResize)
        return () => window.removeEventListener("resize", throttledHandleWindowResize)
    }, [height, width, wasRenderedOnClientAtLeastOnce, ref])
    return wasRenderedOnClientAtLeastOnce ? { height, width } : { heigth: null, width: null }
}

const measureHeight = ref => {
    if (!isClient()) return null
    if (typeof ref === "string") return document.getElementById(ref)?.offsetHeight
    if (ref?.current) return ref.current.offsetHeight
    return window.innerHeight
}

const measureWidth = ref => {
    if (!isClient()) return null
    if (typeof ref === "string") return document.getElementById(ref)?.offsetWidth
    if (ref?.current) return ref.current.offsetWidth
    return window.innerWidth
}

// Once we ended up on client, the first render must look the same as on
// the server so hydration happens without problems. _Then_ we immediately
// schedule a subsequent update and return the height measured on the client.
// It's not needed for CSR-only apps, but is critical for SSR.
const useWasRenderedOnClientAtLeastOnce = () => {
    const [wasRenderedOnClientAtLeastOnce, setWasRenderedOnClientAtLeastOnce] = useState(false)

    useEffect(() => {
        if (isClient() && !wasRenderedOnClientAtLeastOnce) {
            setWasRenderedOnClientAtLeastOnce(true)
        }
    }, [wasRenderedOnClientAtLeastOnce])
    return wasRenderedOnClientAtLeastOnce
}

const isClient = () => {
    return typeof window !== "undefined"
}

export { useDimensions }
