import React, { useCallback, useEffect, useRef, useState } from "react"

const Image = props => {
    const imgRef = useRef(null)
    const [intersected, setIntersected] = useState(false)
    const { src, srcSet, sizes, alt, url, path, className, lazy, quality = 75 } = props

    useEffect(() => {
        if (!lazy) return
        const observer = new IntersectionObserver(
            entries => {
                const image = entries[0]
                if (image.isIntersecting) {
                    setIntersected(true)
                    observer.disconnect()
                }
            },
            {
                rootMargin: "256px 256px 256px 256px",
            }
        )

        observer.observe(imgRef.current)
        return () => {
            observer.disconnect()
        }
    }, [lazy])

    const generateSrc = useCallback(
        (url, path, src) => {
            const hostnames = {
                assets: process.env.IMG_CDN_URL_ASSETS
            }
            return `${
                hostnames[url] ? hostnames[url] : url
            }${src ? process.env.IMG_CDN_PATH : ""}${
                src
                    ? `/${src.fit ? `fit=${src.fit}` : "fit=scale-down"},${src.w ? `w=${src.w},` : ""}${
                          src.h ? `h=${src.h},` : ""
                      }q=${quality},f=auto`
                    : ""
            }${path}`
        },
        [quality],
    )

    const generateSrcSet = useCallback(
        (url, path, srcSet) => {
            let srcSets = ""
            srcSet.map(
                (src, i) =>
                    (srcSets += `${generateSrc(url, path, src)}${
                        src.h && !src.w ? ` ${i + 1}x` : src.w ? ` ${src.w}w` : ""
                    }, `)
            )
            return srcSets
        },
        [generateSrc],
    )

    let generatedSrc = ""
    if (Array.isArray(srcSet) && srcSet.find(src => src.default)) {
        generatedSrc = generateSrc(
            url,
            path,
            srcSet.find(src => src.default)
        )
    } else if (!src) {
        generatedSrc = generateSrc(url, path)
    } else {
        generatedSrc = src
    }

    const generatedSrcSet = Array.isArray(srcSet) ? generateSrcSet(url, path, srcSet) : srcSet

    return (
        <img
            src={intersected || !lazy ? generatedSrc : ""}
            srcSet={intersected || !lazy ? generatedSrcSet : ""}
            sizes={sizes}
            alt={alt || ""}
            ref={imgRef}
            className={className}
        />
    )
}

const LazyImage = props => <Image {...props} lazy />

export { Image, LazyImage }
