import { AuthProvider } from "../context/auth"
import { ClientProvider } from "../context/client"
import { LoadingProvider } from "../context/loading"
import { QueryClientProvider } from "@tanstack/react-query"
import ReAuth from "../components/overlay/re-auth"
import React from "react"
import ReactDOM from "react-dom"
import { SDK } from "../sdk"
import { localStorageMock } from "./local-storage-mock"
import queryClient from "./query-client"

const promise = () =>
    new Promise((resolve, reject) => {
        const containerDomNode = document.createElement("div")
        if (!document.getElementById("re-auth")) {
            document.getElementById("gatsby-focus-wrapper").appendChild(containerDomNode)
            ReactDOM.render(
                <ClientProvider client={client}>
                    <QueryClientProvider client={queryClient}>
                        <LoadingProvider>
                            <AuthProvider>
                                <ReAuth />
                            </AuthProvider>
                        </LoadingProvider>
                    </QueryClientProvider>
                </ClientProvider>,
                containerDomNode
            )
        }
        window.addEventListener(
            "token_too_old_for_include_critical",
            event => {
                containerDomNode.remove()
                if (event.detail.success) {
                    resolve()
                    ReactDOM.unmountComponentAtNode(containerDomNode)
                } else {
                    reject()
                    ReactDOM.unmountComponentAtNode(containerDomNode)
                }
            },
            { once: true }
        )
    })

const client = new SDK(
    {
        url: process.env.API_URL,
        version: process.env.API_VERSION,
        storage: typeof window !== "undefined" ? window.localStorage : localStorageMock(),
        storageKey: process.env.STORAGE_KEY,
        crossDomain: process.env.COOKIE_DOMAIN,
        uid: process.env.API_UID,
        password: process.env.API_PASSWORD,
    },
    promise
)

// for dev purpose make client a global variable
if (process.env.NODE_ENV === "development" && typeof window !== "undefined") {
    window.client = client
}

export default client
