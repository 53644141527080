import React, { useEffect, useState } from "react"

import { Link as GatsbyLink } from "gatsby"
import { addSearchParams } from "../shared/utils/add-search-params"
import { types } from "react-bricks/frontend"
import { useLocalesState } from "../shared/context/locales"

const Link: types.RenderLocalLink = ({ href, children, className, target, activeClassName }) => {
    const { region } = useLocalesState()
    const [newHref, setNewHref] = useState<string>(href)

    useEffect(() => {
        if (["TR", "CZ"].includes(region)) {
            setNewHref(href => addSearchParams(href))
        }
    }, [region])

    return (
        <GatsbyLink to={newHref} className={className} activeClassName={activeClassName} target={target}>
            {children}
        </GatsbyLink>
    )
}

export default Link
