import React, { ReactNode } from "react"

import classNames from "classnames"
import { colorOptions as options } from "../../../types/options"

export enum TypographyColor {
    "White" = "#fff",
    "Blue" = "#00497a",
    "Darkgrey" = "#444",
}

export interface TypographyColorProps {
    children: ReactNode
    color?: TypographyColor
    smColor?: TypographyColor
    mdColor?: TypographyColor
    lgColor?: TypographyColor
}

const Color = ({ children, color = TypographyColor.Darkgrey, smColor, mdColor, lgColor }: TypographyColorProps) => {
    return (
        <div
            className={classNames({
                "text-dark-grey [&:not(:has(sup))_.link]:text-blue": color === TypographyColor.Darkgrey,
                "text-white": color === TypographyColor.White,
                "text-blue": color === TypographyColor.Blue,
                "sm:text-dark-grey sm:[&:not(:has(sup))_.link]:text-blue": smColor === TypographyColor.Darkgrey,
                "sm:text-white": smColor === TypographyColor.White,
                "sm:text-blue": smColor === TypographyColor.Blue,
                "md:text-dark-grey md:[&:not(:has(sup))_.link]:text-blue": mdColor === TypographyColor.Darkgrey,
                "md:text-white": mdColor === TypographyColor.White,
                "md:text-blue": mdColor === TypographyColor.Blue,
                "lg:text-dark-grey lg:[&:not(:has(sup))_.link]:text-blue": lgColor === TypographyColor.Darkgrey,
                "lg:text-white": lgColor === TypographyColor.White,
                "lg:text-blue": lgColor === TypographyColor.Blue,
            })}
        >
            {children}
        </div>
    )
}

export const colorOptions = options<typeof TypographyColor>(TypographyColor)

export default Color
