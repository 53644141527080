import { Container, Spacer, SpacerVariant } from "../components/layout"
import { Repeater, types, useAdminContext } from "react-bricks/frontend"

import Divider from "../decorative/Divider"
import { LazyImage } from "../components/universal/image"
import React from "react"
import client from "../../shared/utils/client"
import { useClientState } from "../../shared/context/client"
import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { useUrlSearchParams } from "../../shared/hooks/use-url-search-params"

interface SubscriptionTeaserProps {
    divider: boolean
    spacerTop: boolean
    spacerBottom: boolean
}

const SubscriptionTeaser: types.Brick<SubscriptionTeaserProps> = ({ divider, spacerTop, spacerBottom }) => {
    const { t } = useTranslation("translations")
    const { customer_id } = useClientState()
    const [params] = useUrlSearchParams()

    const { data: subscriptionsData } = useQuery(
        ["getSubscriptions", { customer_id: customer_id }],
        () => client.getSubscriptions({ customer_id: customer_id }),
        { enabled: !!(customer_id && process.env.EXPONEA_SUBSCRIPTION_CREDIT_EXPRESSION) }
    )

    const { data: expressionData } = useQuery(
        [
            "getExpression",
            { customer_id: customer_id, expression_id: process.env.EXPONEA_SUBSCRIPTION_CREDIT_EXPRESSION },
        ],
        () => client.getCustomerExpression(customer_id, process.env.EXPONEA_SUBSCRIPTION_CREDIT_EXPRESSION),
        { enabled: !!(customer_id && process.env.EXPONEA_SUBSCRIPTION_CREDIT_EXPRESSION) }
    )

    const { isAdmin } = useAdminContext()

    if (
        (!subscriptionsData ||
            subscriptionsData.subscriptions.length > 0 ||
            !expressionData ||
            expressionData.value === 0) &&
        // @ts-ignore
        !params?.slba &&
        !isAdmin
    )
        return null

    return (
        <>
            <Spacer variant={!spacerTop ? SpacerVariant.None : undefined} />
            <Container>
                <div className="flex flex-col items-center justify-center sm:flex-row">
                    <div className="flex w-full flex-col items-center justify-center sm:w-auto sm:flex-row">
                        <LazyImage
                            url="assets"
                            path={`/products/SLP500/steps/V4/DE/step-${
                                // @ts-ignore
                                expressionData?.value || params.slba || 0
                            }-DE_2x1.png`}
                            srcSet={[{ w: 400, default: true }, { w: 800 }]}
                            sizes="(min-width: 1560px) 400px, (min-width: 1024px) 380px, 300px"
                            quality={100}
                            className="hidden w-[300px] self-start sm:block md:w-[380px] xl:w-[400px]"
                        />
                        <div className="w-full sm:ml-5 sm:w-auto md:ml-10 xl:ml-16">
                            <div className="mb-7 max-w-full text-center sm:max-w-[500px] sm:text-left md:mb-10 lg:mb-16 lg:max-w-[600px]">
                                <h4 className="mb-5 text-xl font-semibold md:mb-7 md:text-3xl md:leading-3xl lg:text-5xl lg:leading-5xl">
                                    {t("subscription_teaser_headline", { lng: "de" })}
                                </h4>
                                <p className="whitespace-pre-wrap text-md md:text-lg lg:text-xl">
                                    {t("subscription_teaser_text_1", { lng: "de" })}{" "}
                                    <strong className="font-semibold">
                                        {t("subscription_teaser_text_strong", {
                                            // @ts-ignore
                                            count: expressionData?.value || params.slba || 0,
                                            lng: "de",
                                        })}
                                    </strong>{" "}
                                    {t("subscription_teaser_text_2", { lng: "de" })}
                                </p>
                            </div>
                            <LazyImage
                                url="assets"
                                path={`/products/SLP500/steps/V4/DE/step-${
                                    // @ts-ignore
                                    expressionData?.value || params.slba || 0
                                }-DE_2x1.png`}
                                srcSet={[{ w: 300, default: true }, { w: 600 }]}
                                sizes="300px"
                                quality={100}
                                className="mx-auto mb-10 w-full max-w-[300px] px-[5px] sm:hidden"
                            />
                            <Repeater
                                propName="repeater"
                                renderWrapper={children => (
                                    <div className="sm:flex">
                                        <div className="space-y-3.5 child:sm:block child:child:sm:w-full child:sm:w-full md:flex md:space-x-3.5 md:space-y-0 child:md:inline-block child:child:md:w-auto child:md:w-auto lg:space-x-5">
                                            {children}
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                </div>
            </Container>
            {divider && <Divider spacerTop spacerBottom={false} />}
            <Spacer variant={!spacerBottom ? SpacerVariant.None : undefined} />
        </>
    )
}

SubscriptionTeaser.schema = {
    name: "SubscriptionTeaser",
    label: "Subscription Teaser",
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "Button",
                    label: "Button",
                    max: 2,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "divider",
            label: "Divider",
            type: types.SideEditPropType.Boolean,
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: (): SubscriptionTeaserProps => ({
        divider: false,
        spacerTop: true,
        spacerBottom: true,
    }),
}

export default SubscriptionTeaser
