import React, { ReactNode } from "react"

import classNames from "classnames"
import { twMerge } from "tailwind-merge"

interface ContainerProps {
    children: ReactNode
    className?: string
}

const Container = ({ children, className }: ContainerProps) => {
    return (
        <div
            className={twMerge(
                classNames("mx-auto box-border w-full px-6.5 md:px-10 lg:max-w-[1560px] lg:px-15", className)
            )}
        >
            {children}
        </div>
    )
}

export default Container
