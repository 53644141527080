import { TypographyElement } from "../react-bricks/Typography"
import { useAdminContext } from "react-bricks/frontend"
import { useMemo } from "react"

const useCheckForEmptyText = (text?: TypographyElement[]) => {
    const { isAdmin } = useAdminContext()

    const emptyText = useMemo(() => {
        if (!text) return true
        if (text.length === 0) return true
        if (text.length === 1 && text[0].children.length === 0) return true
        if (text.length === 1 && text[0].children.length === 1 && text[0].children[0].text === "") return true

        return false
    }, [text])

    return emptyText && !isAdmin
}

export { useCheckForEmptyText }
