const hideByDateTime = (startDate: string | Date | null, endDate: string | Date | null): boolean => {
    const now = new Date()
    const start = startDate ? new Date(startDate) : null
    const end = endDate ? new Date(endDate) : null

    return start && end ? now > start && now < end : start ? now > start : end ? now < end : false
}

const useHideByDate = (startDate: string | Date | null, endDate: string | Date | null): boolean => {
    return hideByDateTime(startDate, endDate)
}

export { useHideByDate, hideByDateTime }
