import React, { useEffect, useRef } from "react"
import { closeOverlay, useOverlayDispatch, useOverlayState } from "../../../shared/context/overlay"

import { Icons } from "../../../types"
import VideoPlayer from "./video-player"
import { useOnClickOutside } from "usehooks-ts"

interface VideoOverlayProps {
    cloudflareVideoId: string
}

const VideoOverlay = ({ cloudflareVideoId }: VideoOverlayProps) => {
    const root = document.body.classList
    const overlayRef = useRef(null)
    const overlayDispatch = useOverlayDispatch()
    const { overlay } = useOverlayState()

    const close = () => {
        closeOverlay(overlayDispatch)
        root.remove("noScroll")
    }

    useOnClickOutside(overlayRef, close)

    useEffect(() => {
        if (overlay) {
            root.add("noScroll")
        }

        return () => {
            root.remove("noScroll")
        }
    }, [overlay, root])

    useEffect(() => {
        const handleKeypress = (e: KeyboardEvent) => {
            if (e.key !== "Escape") return
            close()
        }

        window.addEventListener("keydown", handleKeypress)

        return () => window.removeEventListener("keydown", handleKeypress)
    }, [])

    return (
        <div className="fixed left-0 top-0 z-7 flex h-full w-full items-center justify-center overflow-y-auto overflow-x-hidden backdrop-blur-sm sm:px-6.5">
            <div
                ref={overlayRef}
                className="relative max-h-[calc(100%-10rem)] w-full max-w-full sm:max-h-[calc(100%-12.5rem)] sm:max-w-[min(1920px,70%)] lg:max-h-[calc(100%-15rem)]"
            >
                <button
                    type="button"
                    onClick={close}
                    className="absolute -top-7 right-3.75 z-2 sm:top-3.75 lg:right-5 lg:top-5"
                >
                    <i className="lavita-icon text-lg leading-none text-dark-grey sm:text-xl sm:text-white">
                        {Icons.close}
                    </i>
                </button>
                <VideoPlayer cloudflareVideoId={cloudflareVideoId} />
            </div>
        </div>
    )
}

export default VideoOverlay
