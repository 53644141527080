import ImageEditor, { AspectRatio } from "../Image"
import { Repeater, types } from "react-bricks/frontend"

import { Link } from "react-bricks/frontend"
import React from "react"
import { TextLinkBrickProps } from "../text/TextLink"
import { TextLinkSize } from "../components/text"

export interface TrustReviewProps {
    index?: number
    href: string
    identifier?: string
    title?: string
}

const TrustReview: types.Brick<TrustReviewProps> = ({ index, href, identifier, title }) => {
    return (
        <div key={index} className="flex flex-col items-center justify-center sm:max-w-[180px] md:max-w-full">
            <Link
                href={href}
                onClick={() => {
                    if (!identifier) return

                    window.exponea?.track("calltoaction", {
                        action: "clicked",
                        title: title,
                        identifier: identifier,
                    })
                }}
                className="mb-5 md:mb-6"
                target="_blank"
                rel="noopener noreferrer"
            >
                <ImageEditor
                    maxWidth={203}
                    aspectRatio={AspectRatio["9/2"]}
                    className="block h-10 w-auto md:h-[45px]"
                />
            </Link>
            <Repeater propName="repeater" itemProps={{ size: TextLinkSize.Small } as TextLinkBrickProps} />
        </div>
    )
}

TrustReview.schema = {
    name: "TrustReviewItem",
    label: "Trust Review Item",
    hideFromAddMenu: true,
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "TextLink",
                    label: "Text Link",
                    max: 1,
                    min: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "href",
            label: "Link",
            type: types.SideEditPropType.Text,
        },
        {
            groupName: "Tracking",
            props: [
                {
                    name: "identifier",
                    label: "Identifier Bloomreach",
                    type: types.SideEditPropType.Text,
                },
                {
                    name: "title",
                    label: "Title",
                    type: types.SideEditPropType.Text,
                },
            ],
        },
    ],
}

export default TrustReview
