import ImageEditor, { AspectRatio } from "../Image"

import React from "react"
import { types } from "react-bricks/frontend"

export interface ProductRepeaterImageProps {
    index?: number
}

const ProductRepeaterImage: types.Brick<ProductRepeaterImageProps> = ({ index }) => {
    return (
        <div key={index} className="aspect-h-1 aspect-w-3">
            <ImageEditor aspectRatio={AspectRatio["3/1"]} maxWidth={647} className="block w-full" />
        </div>
    )
}

ProductRepeaterImage.schema = {
    name: "ProductRepeaterImage",
    label: "Image",
    hideFromAddMenu: true,
}

export default ProductRepeaterImage
