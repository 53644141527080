import { useEffect } from "react"

const useOnClickOutside = (callback, refs, dependencies) => {
    useEffect(() => {
        const handleClickOutside = event => {
            if (refs.length === 0) return
            if (
                refs.every(
                    r =>
                        r &&
                        r.current !== null &&
                        (r.current ? !r.current.contains(event.target) : !r.contains(event.target))
                ) &&
                !(
                    event.target === document.getElementsByTagName("html")[0] &&
                    event.clientX >= document.documentElement.offsetWidth
                )
            ) {
                callback(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [...(refs || []), ...(dependencies || [])])
}

export { useOnClickOutside }
