import { ContainerBackgroundColor, ContainerVariant, containerBackgroundOptions } from "../components/layout"
import { Repeater, types } from "react-bricks/frontend"
import Typography, { TypographyElement } from "../Typography"

import { Color } from "../components/typography"
import React from "react"
import { RoundedBackgroundContainer } from "../components/layout"
import StarsSVG from "../components/icon/stars"
import { TextLinkBrickProps } from "../text/TextLink"
import { TextLinkSize } from "../components/text"
import { useCheckForEmptyText } from "../../hooks/use-check-for-empty-text"

export interface StarsTextProps {
    background: { color: ContainerBackgroundColor } | ContainerBackgroundColor
    text: TypographyElement[]
    variant?: ContainerVariant
    index?: number
}

const StarsText: types.Brick<StarsTextProps> = ({ background, variant, text, index }) => {
    const emptyText = useCheckForEmptyText(text)

    const bgColor = typeof background === "object" ? background.color : background

    return (
        <div key={index}>
            <RoundedBackgroundContainer variant={variant} background={bgColor} className="flex flex-col">
                <StarsSVG />
                {!emptyText && (
                    <Color>
                        <Typography block="copytext-large" allowList={["small", "sup", "link", "bold"]} />
                    </Color>
                )}
                <Repeater propName="repeater" itemProps={{ size: TextLinkSize.Small } as TextLinkBrickProps} />
            </RoundedBackgroundContainer>
        </div>
    )
}

StarsText.schema = {
    name: "StarsText",
    label: "Stars Text",
    hideFromAddMenu: true,
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "TextLink",
                    label: "Text Link",
                    max: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "background",
            label: "Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: containerBackgroundOptions,
                display: types.OptionsDisplay.Color,
            },
        },
    ],
    getDefaultProps: (): StarsTextProps => ({
        background: { color: ContainerBackgroundColor.Grey },
        text: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc placerat sagittis faucibus.",
                    },
                ],
            },
        ],
    }),
}

export default StarsText
