import "./src/styles/global.css"
import "./src/shared/styles/icon-font.css"
import "./src/shared/styles/toast.css"
import "./src/react-bricks/style.css"
import "intersection-observer"

import { getCookie, setCookie } from "./src/shared/utils/cookie"

import WrapPage from "./src/gatsby-api/wrap-page"
import WrapRoot from "./src/gatsby-api/wrap-root"
import client from "./src/shared/utils/client"
import { languages } from "./src/shared/config/languages"
import { loadAsyncScript } from "./src/shared/utils/load-async-script"
import { regions } from "./src/shared/config/regions"
import { waitFor } from "./src/shared/utils/wait-for"

const onRouteUpdate = ({ location }) => {
    setTimeout(() => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({ event: "gatsby-route-change" })
        window.exponea?.track("page_visit", {
            location: location.href,
            path: location.pathname,
        })
    }, 50)

    if (process.env.TRUSTED_SHOPS_ID && typeof trustbadge !== "undefined") {
        loadAsyncScript(`https://widgets.trustedshops.com/js/${process.env.TRUSTED_SHOPS_ID}.js`)
    }

    if (location.pathname.includes("/tr-tr") || location.pathname.includes("/cs-cz")) {
        const params = new URLSearchParams(location.search)

        if (params.toString()) {
            const links = document.querySelectorAll("a")

            links.forEach(link => {
                const url = new URL(link.href)
                params.forEach((value, key) => {
                    url.searchParams.set(key, value)
                })
                link.href = url.toString()
            })
        }
    }
}

const onClientEntry = () => {
    const pathArray = window.location.pathname.split("/")
    const language =
        languages.find(l => l.langCode === pathArray[1].split("-")[0])?.langCode || process.env.DEFAULT_LANGUAGE
    const search = new URLSearchParams(window.location.search)
    const filteredRegions = regions.filter(r => !process.env.NO_SITE_REGIONS.includes(r.countryCode))
    const region =
        filteredRegions.find(r => r.countryCode === pathArray[1].split("-")?.[1]?.toUpperCase())?.countryCode ||
        filteredRegions.find(r => r.countryCode === search.get("region"))?.countryCode ||
        getCookie("__region__") ||
        process.env.REGION
    setCookie("__language__", language)
    setCookie("__region__", region)

    const track = () => {
        const params = new URLSearchParams(window.location.search)

        let body = {
            exponea_id: getCookie("__exponea_etc__"),
        }
        if (document.referrer && !document.referrer.includes(process.env.HOSTNAME)) {
            body.referrer = document.referrer
        }
        if (params.get("utm_source")) {
            body.utm_source = params.get("utm_source")
        }
        if (params.get("utm_medium")) {
            body.utm_medium = params.get("utm_medium")
        }
        if (params.get("utm_campaign")) {
            body.utm_campaign = params.get("utm_campaign")
        }
        if (params.get("gclid")) {
            body.gclid = params.get("gclid")
        }
        if (params.get("__lx")) {
            body.__lx = params.get("__lx")
        }
        if (Object.keys(body).length > 1 && body.exponea_id && !["TR", "CZ"].includes(region)) {
            try {
                client.createCustomerTracking(body)
            } catch (error) {
                return
            }
        }
    }

    window.addEventListener(
        "exponea:load",
        () => waitFor(() => !!(getCookie("__exponea_etc__") && client.config.consumerToken), track),
        { once: true }
    )
}

const wrapRootElement = WrapRoot
const wrapPageElement = WrapPage

export { onRouteUpdate, wrapRootElement, wrapPageElement, onClientEntry }
