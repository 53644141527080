const currencies = [
    {
        id: "EUR",
        symbol: "€",
    },
    {
        id: "CHF",
        symbol: "CHF",
    },
]

export { currencies }
