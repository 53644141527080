import React from "react"

const StarsSVG = () => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="20.297" viewBox="0 0 120 20.297">
                <g id="Gruppe_17296" data-name="Gruppe 17296" transform="translate(-302.465 -3373.195)">
                    <path
                        id="Pfad_11160"
                        data-name="Pfad 11160"
                        d="M1175.988,995.169h-7.037l-2.139-6.508a.771.771,0,0,0-1-.469.782.782,0,0,0-.459.469l-2.139,6.508h-7.081a.776.776,0,0,0-.765.781.587.587,0,0,0,.014.128.754.754,0,0,0,.321.552l5.788,4.157-2.225,6.586a.792.792,0,0,0,.263.878.735.735,0,0,0,.431.19.931.931,0,0,0,.478-.176l5.645-4.107,5.645,4.107a.886.886,0,0,0,.478.176.681.681,0,0,0,.425-.19.778.778,0,0,0,.263-.878l-2.219-6.582,5.735-4.2.138-.128a.755.755,0,0,0,.07-1.052.722.722,0,0,0-.635-.251Z"
                        transform="translate(-852.906 2385.052)"
                        fill="#e4bb50"
                    />
                    <path
                        id="Pfad_11162"
                        data-name="Pfad 11162"
                        d="M1175.988,995.169h-7.037l-2.139-6.508a.771.771,0,0,0-1-.469.782.782,0,0,0-.459.469l-2.139,6.508h-7.081a.776.776,0,0,0-.765.781.587.587,0,0,0,.014.128.754.754,0,0,0,.321.552l5.788,4.157-2.225,6.586a.792.792,0,0,0,.263.878.735.735,0,0,0,.431.19.931.931,0,0,0,.478-.176l5.645-4.107,5.645,4.107a.886.886,0,0,0,.478.176.681.681,0,0,0,.425-.19.778.778,0,0,0,.263-.878l-2.219-6.582,5.735-4.2.138-.128a.755.755,0,0,0,.07-1.052.722.722,0,0,0-.635-.251Z"
                        transform="translate(-828.264 2385.052)"
                        fill="#e4bb50"
                    />
                    <path
                        id="Pfad_11163"
                        data-name="Pfad 11163"
                        d="M1175.988,995.169h-7.037l-2.139-6.508a.771.771,0,0,0-1-.469.782.782,0,0,0-.459.469l-2.139,6.508h-7.081a.776.776,0,0,0-.765.781.587.587,0,0,0,.014.128.754.754,0,0,0,.321.552l5.788,4.157-2.225,6.586a.792.792,0,0,0,.263.878.735.735,0,0,0,.431.19.931.931,0,0,0,.478-.176l5.645-4.107,5.645,4.107a.886.886,0,0,0,.478.176.681.681,0,0,0,.425-.19.778.778,0,0,0,.263-.878l-2.219-6.582,5.735-4.2.138-.128a.755.755,0,0,0,.07-1.052.722.722,0,0,0-.635-.251Z"
                        transform="translate(-803.621 2385.052)"
                        fill="#e4bb50"
                    />
                    <path
                        id="Pfad_11164"
                        data-name="Pfad 11164"
                        d="M1175.988,995.169h-7.037l-2.139-6.508a.771.771,0,0,0-1-.469.782.782,0,0,0-.459.469l-2.139,6.508h-7.081a.776.776,0,0,0-.765.781.587.587,0,0,0,.014.128.754.754,0,0,0,.321.552l5.788,4.157-2.225,6.586a.792.792,0,0,0,.263.878.735.735,0,0,0,.431.19.931.931,0,0,0,.478-.176l5.645-4.107,5.645,4.107a.886.886,0,0,0,.478.176.681.681,0,0,0,.425-.19.778.778,0,0,0,.263-.878l-2.219-6.582,5.735-4.2.138-.128a.755.755,0,0,0,.07-1.052.722.722,0,0,0-.635-.251Z"
                        transform="translate(-778.979 2385.052)"
                        fill="#e4bb50"
                    />
                    <path
                        id="Pfad_11165"
                        data-name="Pfad 11165"
                        d="M1175.988,995.169h-7.037l-2.139-6.508a.771.771,0,0,0-1-.469.782.782,0,0,0-.459.469l-2.139,6.508h-7.081a.776.776,0,0,0-.765.781.587.587,0,0,0,.014.128.754.754,0,0,0,.321.552l5.788,4.157-2.225,6.586a.792.792,0,0,0,.263.878.735.735,0,0,0,.431.19.931.931,0,0,0,.478-.176l5.645-4.107,5.645,4.107a.886.886,0,0,0,.478.176.681.681,0,0,0,.425-.19.778.778,0,0,0,.263-.878l-2.219-6.582,5.735-4.2.138-.128a.755.755,0,0,0,.07-1.052.722.722,0,0,0-.635-.251Z"
                        transform="translate(-754.336 2385.052)"
                        fill="#e4bb50"
                    />
                </g>
            </svg>
        </div>
    )
}

export default StarsSVG
