import React from "react"
import classNames from "classnames"

export enum SpacerVariant {
    "Base" = "base",
    "Medium" = "md",
    "Small" = "sm",
    "None" = "none",
}

export interface SpacerProps {
    variant?: SpacerVariant
}

const Spacer = ({ variant = SpacerVariant.Base }: SpacerProps) => {
    if (variant === SpacerVariant.None) return null

    return (
        <div
            className={classNames({
                "pb-10 sm:pb-16 lg:pb-28 xl:pb-40": variant === SpacerVariant.Base,
                "pb-10 sm:pb-16 lg:pb-24": variant === SpacerVariant.Medium,
                "pb-7 sm:pb-10 lg:pb-16": variant === SpacerVariant.Small,
            })}
        />
    )
}

export default Spacer
