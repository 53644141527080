import { useEffect, useState } from "react"

const useIsSSR = () => {
    const [isSSR, setIsSSR] = useState(true)

    useEffect(() => {
        if (typeof window === "undefined") return

        setIsSSR(false)
    }, [])

    return isSSR
}

export { useIsSSR }
