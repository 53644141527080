import client from "../../shared/utils/client"
import { useLocalesState } from "../../shared/context/locales"
import { useMutationWithErrorHandling } from "../../shared/hooks/use-query-with-error-handling"
import { useQueryLoading } from "../../shared/hooks/use-query-loading"

const useQueryContactCategories = () => {
    const { language } = useLocalesState()

    return useQueryLoading(["getContactCategories"], () => client.getContactCategories(language))
}

const usePostContact = () => {
    return useMutationWithErrorHandling(body => client.postContact(body))
}

export { useQueryContactCategories, usePostContact }
