import { Button, ButtonStyle, buttonStyleOptions } from "../components/button"
import { Icons, iconOptions, pictogramOptions } from "../../types"

import React from "react"
import { TypographyAlign } from "../components/typography"
import classNames from "classnames"
import { types } from "react-bricks/frontend"

export interface ButtonBrickProps {
    href: string
    title: string
    style: ButtonStyle
    showIcon: boolean
    icon?: Icons
    index?: number
    target?: string
    align?: TypographyAlign
    fullWidth?: boolean
    identifier?: string
}

const ButtonBrick: types.Brick<ButtonBrickProps> = ({
    href,
    target,
    title,
    style,
    index,
    icon,
    showIcon,
    fullWidth,
    identifier,
}) => {
    return (
        <div key={index} className={classNames("w-full", { "sm:inline-block sm:w-auto": !fullWidth })}>
            <Button
                href={href}
                withOnClick={() => {
                    if (!identifier) return

                    window.exponea?.track("calltoaction", {
                        action: "clicked",
                        title: title,
                        identifier: identifier,
                    })
                }}
                target={target}
                title={title}
                style={style}
                icon={showIcon ? icon : undefined}
                fullWidth={fullWidth}
            />
        </div>
    )
}

ButtonBrick.schema = {
    name: "Button",
    label: "Button",
    hideFromAddMenu: true,
    sideEditProps: [
        {
            name: "href",
            label: "Button Link",
            type: types.SideEditPropType.Text,
        },
        {
            name: "target",
            label: "Target",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: [
                    {
                        value: "_self",
                        label: "Self",
                    },
                    {
                        value: "_blank",
                        label: "Blank",
                    },
                ],
                display: types.OptionsDisplay.Select,
            },
        },
        {
            name: "title",
            label: "Title",
            type: types.SideEditPropType.Text,
        },
        {
            name: "showIcon",
            label: "Show Icon",
            type: types.SideEditPropType.Boolean,
        },
        {
            name: "icon",
            label: "Icon",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: [...pictogramOptions, ...iconOptions],
                display: types.OptionsDisplay.Select,
            },
            show: props => props.showIcon,
        },
        {
            name: "style",
            label: "Style",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: buttonStyleOptions,
                display: types.OptionsDisplay.Select,
            },
        },
        {
            groupName: "Tracking",
            props: [
                {
                    name: "identifier",
                    label: "Identifier Bloomreach",
                    type: types.SideEditPropType.Text,
                },
            ],
        },
    ],
    getDefaultProps: (): ButtonBrickProps => ({
        href: process.env.SITE_URL,
        title: "LaVita",
        icon: Icons.cart,
        showIcon: true,
        style: ButtonStyle.Primary,
    }),
}

export default ButtonBrick
