import ConditionalWrapper from "../miscellaneous/conditional-wrapper"
import IconActionWrapperStyles from "./icon-action-wrapper.module.css"
import React from "react"
import classNames from "classnames"

const IconActionWrapper = props => {
    return (
        <ConditionalWrapper
            condition={props.grid}
            wrapper={chlidren => (
                <div
                    className={classNames(IconActionWrapperStyles.gridWrapper, {
                        [IconActionWrapperStyles.marginLeftAuto]: props.marginLeftAuto,
                    })}
                >
                    {chlidren}
                </div>
            )}
        >
            <div
                className={classNames({
                    [IconActionWrapperStyles.wrapper]: !props.grid,
                    [IconActionWrapperStyles.grid]: props.grid,
                    [IconActionWrapperStyles.oneColumn]: props.oneColumn,
                    [IconActionWrapperStyles.centered]: props.centered,
                })}
            >
                {props.children}
            </div>
        </ConditionalWrapper>
    )
}

export default IconActionWrapper
