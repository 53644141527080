import FlexWrapper from "../../../shared/components/fields/flex-wrapper"
import IconAction from "../../../shared/components/universal/icon-action"
import Input from "../../../shared/components/fields/input"
import LinkButtonWrapper from "../universal/link-button-wrapper"
import Radio from "../../../shared/components/fields/radio"
import React from "react"
import StartpaketFormStyles from "./startpaket.module.css"
import { genders } from "../../../shared/config/genders"
import { toast } from "react-toastify"
import { useForm } from "react-hook-form"
import { useState } from "react"
import { useTranslation } from "react-i18next"

/* global exponea */

const StartpaketForm = () => {
    const { t } = useTranslation("translations")

    const [isSuccess, setIsSuccess] = useState(false)

    const {
        handleSubmit,
        control,
        reset,
        register,
        formState: { errors },
    } = useForm({
        mode: "onTouched",
        defaultValues: {
            gender: "",
            firstname: "",
            lastname: "",
            email: "",
            choice: "",
        },
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
    })

    const submit = values => {
        const wrapper = document.getElementById("wrapper")

        if (exponea.isLoaded) {
            exponea.track("startpaket_gewinnspiel", {
                action: "new",
                salutation: values.gender,
                firstname: values.firstname,
                lastname: values.lastname,
                email: values.email,
                choice: values.choice,
            })

            toast.success(t("advent_calender_form_success"), { autoClose: 5000 })
            reset()
            setIsSuccess(true)
            wrapper.scrollIntoView({ behavior: "smooth", block: "center" })
        } else {
            toast.error(t("advent_calender_form_error"), { autoClose: 5000 })
            setIsSuccess(false)
        }
    }

    const choices = [
        { label: t("startpaket_form_choice_option_1"), value: t("startpaket_form_choice_option_1") },
        { label: t("startpaket_form_choice_option_2"), value: t("startpaket_form_choice_option_2") },
    ]

    return (
        <div className={StartpaketFormStyles.wrapper} id="wrapper">
            {!isSuccess ? (
                <form onSubmit={handleSubmit(submit)} name="biathlon" className={StartpaketFormStyles.form}>
                    <h3>{t("startpaket_form_contact_details")}</h3>
                    <Radio
                        register={register("gender", {
                            required: { value: true, message: t("form_error_required") },
                        })}
                        error={errors}
                        options={genders.map(g => ({
                            label: t(g.name),
                            value: g.id,
                        }))}
                        label={t("startpaket_form_salutation")}
                        flex
                        required
                    />
                    <FlexWrapper flex={["2 1 auto", "2 1 auto"]} wrap>
                        <Input
                            control={control}
                            rules={{ required: { value: true, message: t("form_error_required") } }}
                            type="text"
                            name="firstname"
                            label={t("contact_form_firstname_label")}
                            autocomplete="given-name"
                            required
                        />
                        <Input
                            control={control}
                            rules={{ required: { value: true, message: t("form_error_required") } }}
                            type="text"
                            name="lastname"
                            label={t("contact_form_lastname_label")}
                            autocomplete="family-name"
                            required
                        />
                    </FlexWrapper>
                    <Input
                        control={control}
                        rules={{ required: { value: true, message: t("form_error_required") } }}
                        type="email"
                        name="email"
                        label={t("contact_form_email_label")}
                        autocomplete="email"
                        required
                    />
                    <div className={StartpaketFormStyles.radio}>
                        <Radio
                            register={register("choice", {
                                required: { value: true, message: t("form_error_required") },
                            })}
                            error={errors}
                            label={t("startpaket_form_choice_label")}
                            flex
                            options={choices.map(c => ({
                                label: c.label,
                                value: c.value,
                            }))}
                            required
                        />
                    </div>
                    <div className={StartpaketFormStyles.contest}>
                        <p dangerouslySetInnerHTML={{ __html: t("startpaket_form_contest_text") }} />
                        <p>
                            <span style={{ color: "#c53929" }}>* </span>
                            {t("startpaket_form_required_filed")}
                        </p>
                    </div>
                    <LinkButtonWrapper center>
                        <IconAction title={t("biathlon_form_submit")} button submit />
                    </LinkButtonWrapper>
                </form>
            ) : (
                <div className={StartpaketFormStyles.success}>{t("startpaket_form_success")}</div>
            )}
        </div>
    )
}

export default StartpaketForm
