import { Color, TypographyColor } from "../components/typography"
import { Pictograms, pictogramOptions } from "../../types"
import Typography, { TypographyElement } from "../Typography"

import { IconText } from "../components/icon-text"
import React from "react"
import classNames from "classnames"
import { types } from "react-bricks/frontend"

export interface GridIconTextProps {
    icon: Pictograms
    color?: TypographyColor
    headline: TypographyElement[]
    text: TypographyElement[]
    index?: number
}

const GridIconText: types.Brick<GridIconTextProps> = ({ icon, color = TypographyColor.Darkgrey, index }) => {
    return (
        <div key={index} className="space-y-4 sm:space-y-5">
            <IconText icon={icon} className={classNames({ "text-white": color === TypographyColor.White })} />
            <Color color={color}>
                <Typography propName="headline" allowList={["sup", "link", "bold"]} />
            </Color>
            <Color color={color}>
                <Typography block="copytext-small" allowList={["small", "sup", "link"]} />
            </Color>
        </div>
    )
}

GridIconText.schema = {
    name: "GridIconText",
    label: "Grid Icon Text",
    hideFromAddMenu: true,
    sideEditProps: [
        {
            name: "icon",
            label: "Icon",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: pictogramOptions,
                display: types.OptionsDisplay.Select,
            },
        },
    ],
    getDefaultProps: (): GridIconTextProps => ({
        icon: Pictograms.vitality,
        headline: [
            {
                type: "paragraph",
                children: [
                    {
                        bold: true,
                        text: "Lorem ipsum dolor sit amet.",
                    },
                ],
            },
        ],
        text: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc placerat sagittis faucibus.",
                    },
                ],
            },
        ],
    }),
}

export default GridIconText
