import React from "react"
import { RenderElementProps } from "slate-react"

const H1 = ({ children, attributes }: RenderElementProps) => {
    return (
        <h1
            {...attributes}
            className="mb-5 text-5xl font-semibold last:mb-0 sm:leading-5xl lg:mb-7 lg:text-7xl xl:text-8xl"
        >
            {children}
        </h1>
    )
}

export default H1
