import { OverlayReducer, initialState } from "./reducer"
import React, { useReducer } from "react"

const OverlayStateContext = React.createContext()
const OverlayDispatchContext = React.createContext()

const useOverlayState = () => {
    const context = React.useContext(OverlayStateContext)
    if (context === undefined) {
        throw new Error("useOverlayState must be used within a OverlayProvider")
    }

    return context
}

const useOverlayDispatch = () => {
    const context = React.useContext(OverlayDispatchContext)
    if (context === undefined) {
        throw new Error("useOverlayDispatch must be used within a OverlayProvider")
    }

    return context
}

const OverlayProvider = props => {
    const [state, dispatch] = useReducer(OverlayReducer, initialState)

    return (
        <OverlayStateContext.Provider value={state}>
            <OverlayDispatchContext.Provider value={dispatch}>{props.children}</OverlayDispatchContext.Provider>
        </OverlayStateContext.Provider>
    )
}

export { useOverlayState, useOverlayDispatch, OverlayProvider }
