import {
    Background,
    BackgroundColor,
    Container,
    Grid,
    GridColumns,
    GridGap,
    Spacer,
    SpacerVariant,
    backgroundGreyScaleColorsOptions,
} from "../../components/layout"
import { Repeater, types } from "react-bricks/frontend"

import React from "react"

interface TwoColumnImageTextProps {
    background: { color: BackgroundColor } | BackgroundColor
    spacerTop: boolean
    spacerBottom: boolean
}

const TwoColumnImageText: types.Brick<TwoColumnImageTextProps> = ({ background, spacerTop, spacerBottom }) => {
    const bgColor = typeof background === "object" ? background.color : background

    return (
        <Background color={bgColor}>
            <Container>
                <Spacer variant={!spacerTop ? SpacerVariant.None : undefined} />
                <Repeater propName="centerText" />
                <Grid columns={GridColumns.Two} gapY={GridGap.Large}>
                    <Repeater propName="repeater" />
                </Grid>
                <Spacer variant={!spacerBottom ? SpacerVariant.None : undefined} />
            </Container>
        </Background>
    )
}

TwoColumnImageText.schema = {
    name: "TwoColumnImageText",
    label: "2 Column Image Text",
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "TwoColumnImageTextItem",
                    label: "Image Text Item",
                    min: 1,
                },
            ],
        },
        {
            name: "centerText",
            items: [
                {
                    type: "CenterText",
                    label: "Text",
                    max: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "background",
            label: "Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: backgroundGreyScaleColorsOptions,
                display: types.OptionsDisplay.Color,
            },
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: (): TwoColumnImageTextProps => ({
        background: { color: BackgroundColor.White },
        spacerTop: true,
        spacerBottom: true,
    }),
}

export default TwoColumnImageText
