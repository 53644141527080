import React, { useEffect } from "react"

import Button from "../universal/button"
import CouponStyles from "./coupon.module.css"
import WrapWithError from "../../../shared/components/fields/wrap-with-error"
import classNames from "classnames"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useUrlSearchParams } from "../../../shared/hooks/use-url-search-params"

const Coupon = props => {
    const { t } = useTranslation("translations")

    const [params, setParams] = useUrlSearchParams()

    const {
        formState: { errors },
        handleSubmit,
        register,
        setValue,
    } = useForm({
        mode: "onTouched",
        defaultValues: {
            code: "",
        },
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
    })

    useEffect(() => {
        if (!params || !(!params.coupon !== !params.code)) return

        setValue("code", params.coupon || params.code)
        setParams({ coupon: undefined, code: undefined })
    }, [params, setParams, setValue])

    const submit = values => {
        window.location = `${process.env.SHOP_URL}/cart?code=${values.code}`
    }

    return (
        <form
            onSubmit={handleSubmit(submit)}
            name="coupon"
            className={classNames(CouponStyles.form, { [CouponStyles.inline]: props.inline })}
        >
            <div>
                <WrapWithError error={errors?.["code"]}>
                    <div className={CouponStyles.inputWrapper}>
                        <input
                            {...register("code", {
                                required: { value: true, message: t("form_error_required") },
                            })}
                            type="text"
                            id="code"
                            placeholder={props.inputPlaceholder || t("new_coupon_form_code_label")}
                            className={classNames(CouponStyles.input, {
                                [CouponStyles.error]: errors?.["code"],
                                [CouponStyles.withoutLabel]: props.inputLabel === false,
                            })}
                        />
                        {props.inputLabel !== false && (
                            <label htmlFor="code" className={CouponStyles.label}>
                                <i className="lavita-icon"></i>
                            </label>
                        )}
                    </div>
                </WrapWithError>
            </div>
            <Button icon={props.submitIcon} title={props.submitTitle || t("new_coupon_form_submit")} submit />
        </form>
    )
}

export default Coupon
