import ImageEditor from "../Image"
import { Link } from "react-bricks/frontend"
import React from "react"
import { types } from "react-bricks/frontend"

export interface ImageButtonProps {
    href: string
    index?: number
    identifier?: string
    title?: string
}

const ImageButton: types.Brick<ImageButtonProps> = ({ index, href, identifier, title }) => {
    return (
        <div key={index}>
            <Link href={href} onClick={() => {
                    if (!identifier) return

                    window.exponea?.track("calltoaction", {
                        action: "clicked",
                        title: title,
                        identifier: identifier,
                    })
                }}>
                <ImageEditor
                    maxWidth={200}
                    className="block h-[37px] w-auto sm:h-[43px] md:h-[57px]"
                />
            </Link>
        </div>
    )
}

ImageButton.schema = {
    name: "ImageButton",
    label: "Image Button",
    hideFromAddMenu: true,
    sideEditProps: [
        {
            name: "href",
            label: "Link",
            type: types.SideEditPropType.Text,
        },
        {
            groupName: "Tracking",
            props: [
                {
                    name: "identifier",
                    label: "Identifier Bloomreach",
                    type: types.SideEditPropType.Text,
                },
                {
                    name: "title",
                    label: "Title",
                    type: types.SideEditPropType.Text,
                },
            ],
        },
    ],
}

export default ImageButton
