import { LoadingReducer, initialState } from "./reducer"
import React, { useReducer } from "react"

const LoadingStateContext = React.createContext()
const LoadingDispatchContext = React.createContext()

const useLoadingState = () => {
    const context = React.useContext(LoadingStateContext)
    if (context === undefined) {
        throw new Error("useLoadingState must be used within a LoadingProvider")
    }

    return context
}

const useLoadingDispatch = () => {
    const context = React.useContext(LoadingDispatchContext)
    if (context === undefined) {
        throw new Error("useLoadingDispatch must be used within a LoadingProvider")
    }

    return context
}

const LoadingProvider = props => {
    const [state, dispatch] = useReducer(LoadingReducer, initialState)

    return (
        <LoadingStateContext.Provider value={state}>
            <LoadingDispatchContext.Provider value={dispatch}>{props.children}</LoadingDispatchContext.Provider>
        </LoadingStateContext.Provider>
    )
}

export { useLoadingState, useLoadingDispatch, LoadingProvider }
