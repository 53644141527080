import { A, Link } from "../../../shared/utils/router"

import ButtonStyles from "./button.module.css"
import React from "react"
import classNames from "classnames"

const Button = React.forwardRef((props, ref) => {
    const {
        secondary,
        small,
        color,
        variant,
        to,
        state,
        id,
        withOnClick,
        url,
        action,
        submit,
        icon,
        title,
        disabled,
        lessPadding,
        ...rest
    } = props

    const colorClasses = {}

    for (const size in color) {
        colorClasses[ButtonStyles[`${color[size]}${size[0].toUpperCase()}${size.slice(1)}`]] = color[size] !== "blue"
    }

    const classes = classNames(ButtonStyles.button, {
        ...colorClasses,
        [ButtonStyles.secondary]: secondary,
        [ButtonStyles.small]: small,
        [variant]: variant,
        [ButtonStyles.disabled]: disabled,
        [ButtonStyles.lessPadding]: lessPadding,
    })


    return disabled ? (
        <button disabled id={id} className={classes} ref={ref} {...rest}>
            {icon && <i className="lavita-icon">{icon}</i>}
            <span>{title}</span>
        </button>
    ) : to ? (
        <Link to={to} state={state} id={id} className={classes} onClick={withOnClick} ref={ref} {...rest}>
            {icon && <i className="lavita-icon">{icon}</i>}
            <span>{title}</span>
        </Link>
    ) : url ? (
        <A href={url} state={state} id={id} className={classes} onClick={withOnClick} ref={ref} {...rest}>
            {icon && <i className="lavita-icon">{icon}</i>}
            <span>{title}</span>
        </A>
    ) : action ? (
        <button type="button" id={id} className={classes} onClick={action} ref={ref} {...rest}>
            {icon && <i className="lavita-icon">{icon}</i>}
            <span>{title}</span>
        </button>
    ) : submit ? (
        <button type="submit" id={id} className={classes} ref={ref} {...rest}>
            {icon && <i className="lavita-icon">{icon}</i>}
            <span>{title}</span>
        </button>
    ) : null
})

export default Button
