import { Icons, Pictograms } from "../../../types/icons"

import React from "react"
import classNames from "classnames"

export interface IconProps {
    icon: Pictograms | Icons
    className?: string
}

const Icon = ({ icon, className }: IconProps) => {
    return <i className={classNames("lavita-icon", className)}>{icon}</i>
}

export default Icon
