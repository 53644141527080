import { Background, BackgroundColor, Container, Spacer, SpacerVariant, backgroundOptions } from "../components/layout"
import { Button, ButtonStyle } from "../components/button"
import React, { useState } from "react"
import { Repeater, types } from "react-bricks/frontend"

import { CenterTextProps } from "../text/CenterText"
import { IconTextGridProps } from "../icon-text/IconTextGrid"
import { TypographyColor } from "../components/typography"
import { useTranslation } from "react-i18next"

export interface ShowMoreProps {
    background: { color: BackgroundColor } | BackgroundColor
    spacerTop: boolean
    spacerBottom: boolean
    initiallyVisible: number
}

const ShowMore: types.Brick<ShowMoreProps> = ({ background, spacerTop, spacerBottom, initiallyVisible }) => {
    const { t } = useTranslation("translations")
    const [showMore, setShowMore] = useState(false)

    const bgColor = typeof background === "object" ? background.color : background

    return (
        <Background color={bgColor}>
            <Container>
                <Spacer variant={!spacerTop ? SpacerVariant.None : undefined} />
                <Repeater
                    propName="centerText"
                    itemProps={
                        {
                            color: bgColor === BackgroundColor.Green ? TypographyColor.White : TypographyColor.Darkgrey,
                        } as CenterTextProps
                    }
                />
                <Repeater
                    propName="repeater"
                    itemProps={
                        {
                            visible: !showMore ? initiallyVisible : undefined,
                        } as IconTextGridProps
                    }
                />
                <Spacer variant={SpacerVariant.Small} />
                <div className="flex justify-center">
                    <Button
                        onClick={() => setShowMore(showMore => !showMore)}
                        title={showMore ? t("icon_action_show_less") : t("icon_action_show_more")}
                        style={ButtonStyle.Secondary}
                    />
                </div>
                <Spacer variant={!spacerBottom ? SpacerVariant.None : undefined} />
            </Container>
        </Background>
    )
}

ShowMore.schema = {
    name: "ShowMore",
    label: "Show More",
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "IconTextGrid",
                    label: "Icon Text Grid",
                    min: 1,
                    max: 1,
                },
            ],
        },
        {
            name: "centerText",
            items: [
                {
                    type: "CenterText",
                    label: "Text",
                    max: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "initiallyVisible",
            label: "Initially Visible",
            type: types.SideEditPropType.Number,
        },
        {
            name: "background",
            label: "Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: backgroundOptions,
                display: types.OptionsDisplay.Color,
            },
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: (): ShowMoreProps => ({
        background: { color: BackgroundColor.White },
        spacerTop: true,
        spacerBottom: true,
        initiallyVisible: 6,
    }),
}

export default ShowMore
