import React from "react"
import { RenderElementProps } from "slate-react"

const SEOH1 = ({ children, attributes }: RenderElementProps) => {
    return (
        <h1 {...attributes} className="mb-3 text-base last:mb-0 sm:text-lg sm:font-semibold lg:mb-5 lg:text-xl">
            {children}
        </h1>
    )
}

export default SEOH1
