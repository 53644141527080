import React, { ReactNode, useRef } from "react"

import Container from "../layout/container"
import SliderNavigation from "./navigation"
import classNames from "classnames"
import { useSlider } from "../../../hooks/use-slider"
import { useWindowSize } from "../../../hooks/use-window-size-admin"

interface FixedWidthSliderProps {
    children: ReactNode
    length: number
}

const FixedWidthSlider = ({ children, length }: FixedWidthSliderProps) => {
    const sliderRef = useRef<null | HTMLDivElement>(null)

    const { handleDragStart, handleDragMove, handleDragEnd, index, lastIndex, transition, goTo } = useSlider(
        length,
        sliderRef,
        0
    )

    const { width } = useWindowSize({ initializeWithValue: false })

    return (
        <div className="overflow-hidden" ref={sliderRef}>
            <Container>
                <div
                    className={classNames("grid gap-x-3.5 sm:gap-x-5", {
                        "transition-transform duration-[250ms] ease-in-out": transition,
                        "cursor-grab active:cursor-grabbing": length > 1,
                    })}
                    style={
                        {
                            width: `calc(var(--child-width) * ${length} + var(--gap) * ${length - 1})`,
                            transform: `translateX(calc(${-1 * index} * (100% + var(--gap)) / ${length}))`,
                            gridTemplateColumns: `repeat(${length}, 1fr)`,
                            "--child-width": `${
                                // @ts-ignore
                                width < 768 ? "100%" : width < 1366 ? "236px" : width < 1560 ? "312px" : "360px"
                            }`,
                            // @ts-ignore
                            "--gap": `${width < 768 ? 0.875 : 1.25}rem`,
                        } as React.CSSProperties
                    }
                    onTouchStart={handleDragStart}
                    onMouseDown={handleDragStart}
                    onTouchMove={handleDragMove}
                    onMouseMove={handleDragMove}
                    onTouchEnd={handleDragEnd}
                    onMouseUp={handleDragEnd}
                    onMouseLeave={handleDragEnd}
                    role="none"
                >
                    {children}
                </div>
                <SliderNavigation length={length} lastIndex={lastIndex} goTo={goTo} />
            </Container>
        </div>
    )
}

export default FixedWidthSlider
