import { Color, TypographyColor, colorOptions } from "../components/typography"
import { Container, Spacer, SpacerVariant } from "../components/layout"
import ImageEditor, { AspectRatio } from "../Image"
import { Repeater, types } from "react-bricks/frontend"
import Typography, { TypographyElement } from "../Typography"

import React from "react"
import classNames from "classnames"
import { options } from "../../types"

enum Size {
    Base = "base",
    Large = "lg",
}

enum Background {
    Image = "image",
    Color = "color",
}

interface MasterTeaserProps {
    text: TypographyElement[]
    size: Size
    color: { color: TypographyColor } | TypographyColor
    background?: Background
    backgroundColor?: string
    additionalImage?: boolean
    spacerTop: boolean
    spacerBottom: boolean
    fullWidthMobile?: boolean
    fullWidthText?: boolean
}

const MasterTeaser: types.Brick<MasterTeaserProps> = ({
    size,
    color,
    background,
    backgroundColor,
    additionalImage,
    spacerTop,
    spacerBottom,
    fullWidthMobile,
    fullWidthText,
}) => {
    const txtColor = typeof color === "object" ? color.color : color

    return (
        <Container className={fullWidthMobile ? "px-0 sm:px-6.5" : undefined}>
            <Spacer variant={!spacerTop ? SpacerVariant.None : undefined} />
            <div
                className={classNames(
                    `overflow-hidden ${fullWidthMobile ? "rounded-none" : "rounded-base"} sm:rounded-base`,
                    {
                        "grid grid-cols-1 grid-rows-1": background === Background.Image,
                        "sm:grid sm:grid-cols-1 sm:grid-rows-1": fullWidthText && background === Background.Color,
                        "sm:grid sm:grid-cols-2 sm:grid-rows-1": !fullWidthText,
                    }
                )}
                style={{ background: backgroundColor }}
            >
                <div
                    className={classNames(
                        "z-2 col-start-1 col-end-3 row-span-full flex flex-col justify-center gap-5 sm:col-end-1 sm:gap-10 lg:gap-16 xl:p-20",
                        {
                            "p-5 sm:p-5 sm:!pr-0 md:p-10 lg:p-15": !fullWidthText,
                            "pb-2.5": background === Background.Color,
                            "px-6.5 py-10 sm:px-10 lg:px-20 lg:py-15": fullWidthText,
                        }
                    )}
                >
                    <Color color={txtColor}>
                        <Typography allowList={["h2", "h3", "ul", "checkList", "small", "sup", "link", "bold"]} />
                    </Color>
                    {additionalImage && (
                        <div className="w-[224px] max-w-full lg:w-[260px]">
                            <div className="aspect-h-2 aspect-w-5">
                                <ImageEditor
                                    maxWidth={260}
                                    aspectRatio={AspectRatio["5/2"]}
                                    className="w-full"
                                    propName="additional_image"
                                />
                            </div>
                        </div>
                    )}
                    <Repeater
                        propName="repeater"
                        renderWrapper={children => <div className="flex space-x-2 md:space-x-2.5">{children}</div>}
                    />
                </div>
                <div
                    className={classNames("aspect-h-4 aspect-w-5 col-span-full row-span-full", {
                        "sm:aspect-h-9 sm:aspect-w-[15] md:aspect-h-2 md:aspect-w-5": size === Size.Base,
                        "sm:aspect-h-9 sm:aspect-w-[15]": size === Size.Large,
                    })}
                >
                    {size === Size.Base && (
                        <ImageEditor
                            maxWidth={1440}
                            aspectRatio={AspectRatio["5/2"]}
                            className="hidden w-full object-cover md:block"
                            propName="image"
                        />
                    )}
                    <ImageEditor
                        maxWidth={1440}
                        aspectRatio={AspectRatio["15/9"]}
                        className={classNames("w-full object-cover sm:block ", {
                            "md:hidden": size === Size.Base,
                            hidden: background === Background.Color,
                            block: background === Background.Image,
                        })}
                        propName="image_tablet"
                    />
                    {background === Background.Color && (
                        <ImageEditor
                            maxWidth={768}
                            aspectRatio={AspectRatio["5/4"]}
                            className="w-full sm:hidden"
                            propName="image_mobile"
                        />
                    )}
                </div>
            </div>
            <Spacer variant={!spacerBottom ? SpacerVariant.None : undefined} />
        </Container>
    )
}

MasterTeaser.schema = {
    name: "MasterTeaser",
    label: "Master Teaser",
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "Button",
                    label: "Button",
                    max: 1,
                },
                {
                    type: "ImageButton",
                    label: "ImageButton",
                    max: 2,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "size",
            label: "Size",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: options<typeof Size>(Size),
                display: types.OptionsDisplay.Select,
            },
        },
        {
            name: "color",
            label: "Color",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: colorOptions,
                display: types.OptionsDisplay.Color,
            },
        },
        {
            name: "additionalImage",
            label: "Additional Image",
            type: types.SideEditPropType.Boolean,
        },
        {
            name: "background",
            label: "Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: options<typeof Background>(Background),
                display: types.OptionsDisplay.Select,
            },
        },
        {
            name: "backgroundColor",
            label: "Background Color",
            type: types.SideEditPropType.Text,
            show: props => props.background === Background.Color,
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
        {
            name: "fullWidthMobile",
            label: "Full Width Mobile",
            type: types.SideEditPropType.Boolean,
        },
        {
            name: "fullWidthText",
            label: "Full Width Text",
            type: types.SideEditPropType.Boolean,
        },
    ],
    getDefaultProps: (): MasterTeaserProps => ({
        text: [
            {
                type: "h2",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet",
                    },
                ],
            },
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                    },
                ],
            },
        ],
        size: Size.Base,
        color: { color: TypographyColor.White },
        background: Background.Color,
        additionalImage: false,
        spacerTop: true,
        spacerBottom: true,
        fullWidthMobile: false,
        fullWidthText: false,
    }),
}

export default MasterTeaser
