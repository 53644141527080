import React from "react"
import { RenderElementProps } from "slate-react"

const H5 = ({ children, attributes }: RenderElementProps) => {
    return (
        <h5 {...attributes} className="mb-4 text-lg last:mb-0 sm:mb-5 sm:text-xl lg:text-2xl">
            {children}
        </h5>
    )
}

export default H5
