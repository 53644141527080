import { Background, BackgroundColor, Container, backgroundOptions } from "../components/layout"
import { Repeater, types } from "react-bricks/frontend"

import Badge from "../components/trust/badge"
import { ConditionalWrapper } from "../components/util"
import React from "react"
import { StripeIconTextProps } from "./StripeIconText"
import TrustedShopBadge from "../components/trust/trusted-shop-badge"
import classNames from "classnames"
import { twMerge } from "tailwind-merge"
import { useLocalesState } from "../../shared/context/locales"

interface TrustStripeBrickProps {
    overlap: boolean
    background?: { color: BackgroundColor } | BackgroundColor
    repeater?: {
        id: string
        props: StripeIconTextProps
        type: "TrustStripeIconText"
    }[]
}

const TrustStripeBrick: types.Brick<TrustStripeBrickProps> = ({ overlap, background, repeater }) => {
    const { language, region } = useLocalesState()

    const bgColor = typeof background === "object" ? background.color : background

    return (
        <ConditionalWrapper
            condition={overlap}
            wrapper={children => <Background color={bgColor}>{children}</Background>}
        >
            <Container>
                <ConditionalWrapper
                    condition={overlap}
                    wrapper={children => (
                        <div className="relative pb-10 sm:pb-[68px] md:pb-[54px] lg:pb-[64px] xl:pb-[74px]">
                            <div className="z-2 flex items-center justify-center rounded-base bg-white shadow-base sm:absolute sm:w-full sm:-translate-y-[30px] sm:rounded-lg md:-translate-y-1/2 lg:shadow-lg">
                                {children}
                            </div>
                        </div>
                    )}
                >
                    <div
                        className={twMerge(
                            classNames(
                                "box-content grid grow grid-cols-1 gap-y-10 p-5 sm:justify-center sm:p-3.5 xl:p-6",
                                { "sm:grid-cols-3": repeater?.length === 2 },
                                { "sm:grid-cols-2": repeater?.length === 1 }
                            )
                        )}
                    >
                        <Repeater propName="repeater" />
                        {language === "de" && region === "DE" ? (
                            <div className="sm:px-3 sm:pr-0 first:sm:px-0 md:pl-7 lg:pl-10 xl:pl-16 xl:pr-10">
                                <TrustedShopBadge />
                            </div>
                        ) : (
                            <div className="sm:px-3 sm:pr-0 first:sm:px-0 md:pl-7 lg:pl-10 xl:pl-16 xl:pr-7.5">
                                <Badge />
                            </div>
                        )}
                    </div>
                </ConditionalWrapper>
            </Container>
        </ConditionalWrapper>
    )
}

TrustStripeBrick.schema = {
    name: "TrustStripe2",
    label: "Trust Stripe",
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "TrustStripeIconText",
                    label: "Icon Text",
                    max: 2,
                    min: 0,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "overlap",
            label: "Overlap",
            type: types.SideEditPropType.Boolean,
        },
        {
            name: "background",
            label: "Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: backgroundOptions,
                display: types.OptionsDisplay.Color,
            },
            show: props => props.overlap,
        },
    ],
    getDefaultProps: (): TrustStripeBrickProps => ({
        overlap: false,
        background: { color: BackgroundColor.White },
    }),
}

export default TrustStripeBrick
