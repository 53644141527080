import React, { ReactNode } from "react"

import classNames from "classnames"
import { options } from "../../../types/options"
import { twMerge } from "tailwind-merge"

export enum TypographyMaxWidth {
    "Full" = "100",
    "70%" = "70",
    "Half" = "50",
}

export interface TypographyMaxWidthProps {
    children: ReactNode
    smMaxWidth?: TypographyMaxWidth
    mdMaxWidth?: TypographyMaxWidth
    lgMaxWidth?: TypographyMaxWidth
    className?: string
}

const MaxWidth = ({ children, smMaxWidth, mdMaxWidth, lgMaxWidth, className }: TypographyMaxWidthProps) => {
    return (
        <div
            className={twMerge(
                classNames("max-w-full", {
                    "sm:max-w-full": smMaxWidth === TypographyMaxWidth.Full,
                    "sm:max-w-[70%]": smMaxWidth === TypographyMaxWidth["70%"],
                    "sm:max-w-[50%]": smMaxWidth === TypographyMaxWidth.Half,
                    "md:max-w-full": mdMaxWidth === TypographyMaxWidth.Full,
                    "md:max-w-[70%]": mdMaxWidth === TypographyMaxWidth["70%"],
                    "md:max-w-[50%]": mdMaxWidth === TypographyMaxWidth.Half,
                    "lg:max-w-full": lgMaxWidth === TypographyMaxWidth.Full,
                    "lg:max-w-[70%]": lgMaxWidth === TypographyMaxWidth["70%"],
                    "lg:max-w-[50%]": lgMaxWidth === TypographyMaxWidth.Half,
                }),
                className
            )}
        >
            {children}
        </div>
    )
}

export const maxWidthOptions = options<typeof TypographyMaxWidth>(TypographyMaxWidth)

export default MaxWidth
