const sitemapMenu = [
    {
        id: "mikronaehrstoffe",
        translations: [
            {
                language: "de",
                title: "Mikronährstoffe",
                url: "https://www.lavita.de/mikronaehrstoffkonzentrat",
            },
        ],
    },
    {
        id: "vitalstoffe",
        translations: [
            {
                language: "de",
                title: "Vitalstoffe",
                url: "https://www.lavita.de/mikronaehrstoffkonzentrat",
            },
        ],
    },
    {
        id: "mikronaehrstoffkonzentrat",
        translations: [
            {
                language: "de",
                title: "Mikronährstoffkonzentrat",
                url: "https://shop.lavita.de",
            },
            {
                language: "en",
                title: "Micronutrient concentrate",
                url: "https://shop.lavita.de",
            },
        ],
    },
    {
        id: "vitalstoffkonzentrat",
        translations: [
            {
                language: "de",
                title: "Vitalstoffkonzentrat",
                url: "https://shop.lavita.de",
            },
            {
                language: "en",
                title: "Vital substance concentrate",
                url: "https://shop.lavita.de",
            },
        ],
    },
    {
        id: "immunsystem-staerken",
        translations: [
            {
                language: "de",
                title: "Immunsystem stärken",
                url: "https://www.lavita.de/gesundheitswelt/immunsystem/immunsystem-staerken",
            },
        ],
    },
    {
        id: "lavita-anwendung",
        translations: [
            {
                language: "de",
                title: "LaVita Anwendung",
                url: "https://www.lavita.de/service/anwendung-zubereitung",
            },
            {
                language: "en",
                title: "LaVita application",
                url: "https://www.lavita.de/service/how-to-use",
            },
        ],
    },
    {
        id: "mikronaehrstoff-aufbaukur",
        translations: [
            {
                language: "de",
                title: "Mikronährstoff Aufbaukur",
                url: "https://www.lavita.de/service/anwendung-zubereitung",
            },
            {
                language: "en",
                title: "Micronutrient build-up cure",
                url: "https://www.lavita.de/service/how-to-use",
            },
        ],
    },
    {
        id: "energie",
        translations: [
            {
                language: "de",
                title: "Energie",
                url: "https://www.lavita.de/gesundheitswelt/muedigkeit",
            },
        ],
    },
    {
        id: "leistungsfaehigkeit",
        translations: [
            {
                language: "de",
                title: "Leistungsfähigkeit",
                url: "https://www.lavita.de/wissen-gesundheit/koerperliche-leistungsfaehigkeit",
            },
        ],
    },
    {
        id: "konzentration",
        translations: [
            {
                language: "de",
                title: "Konzentration",
                url: "https://www.lavita.de/wissen-gesundheit/konzentration",
            },
        ],
    },
    {
        id: "praevention",
        translations: [
            {
                language: "de",
                title: "Prävention",
                url: "https://www.lavita.de/wissen-gesundheit/praevention",
            },
        ],
    },
    {
        id: "haut",
        translations: [
            {
                language: "de",
                title: "Haut",
                url: "https://www.lavita.de/wissen-gesundheit/haut",
            },
        ],
    },
    {
        id: "wechseljahre",
        translations: [
            {
                language: "de",
                title: "Wechseljahre",
                url: "https://www.lavita.de/wissen-gesundheit/hormone",
            },
        ],
    },
    {
        id: "hormone",
        translations: [
            {
                language: "de",
                title: "Hormone",
                url: "https://www.lavita.de/wissen-gesundheit/hormone",
            },
        ],
    },
    {
        id: "bioverfuegbarkeit",
        translations: [
            {
                language: "de",
                title: "Bioverfügbarkeit",
                url: "https://www.lavita.de/mikronaehrstoffkonzentrat/studien/bioverfuegbarkeit",
            },
            {
                language: "en",
                title: "Bioavailability",
                url: "https://www.lavita.de/micronutrient-concentrate/study/bioavailability",
            },
        ],
    },
    {
        id: "schilddruese",
        translations: [
            {
                language: "de",
                title: "Schilddrüse",
                url: "https://www.lavita.de/wissen-gesundheit/schilddruese",
            },
        ],
    },
    {
        id: "herz-kreislauf",
        translations: [
            {
                language: "de",
                title: "Herz-Kreislauf",
                url: "https://www.lavita.de/wissen-gesundheit/herz-kreislauf",
            },
        ],
    },
    {
        id: "stoffwechsel",
        translations: [
            {
                language: "de",
                title: "Stoffwechsel",
                url: "https://www.lavita.de/wissen-gesundheit/stoffwechsel",
            },
        ],
    },
    {
        id: "vitalitaet",
        translations: [
            {
                language: "de",
                title: "Vitalität",
                url: "https://www.lavita.de/wissen-gesundheit/vitalitaet",
            },
        ],
    },
    {
        id: "blutzucker",
        translations: [
            {
                language: "de",
                title: "Blutzucker",
                url: "https://www.lavita.de/wissen-gesundheit/blutzucker",
            },
        ],
    },
    {
        id: "fruchtbarkeit",
        translations: [
            {
                language: "de",
                title: "Fruchtbarkeit",
                url: "https://www.lavita.de/wissen-gesundheit/fruchtbarkeit-kinderwunsch",
            },
        ],
    },
    {
        id: "kinderwunsch",
        translations: [
            {
                language: "de",
                title: "Kinderwunsch",
                url: "https://www.lavita.de/wissen-gesundheit/fruchtbarkeit-kinderwunsch",
            },
        ],
    },
    {
        id: "saeure-basen-Haushalt",
        translations: [
            {
                language: "de",
                title: "Säure-Basen-Haushalt",
                url: "https://www.lavita.de/wissen-gesundheit/saeure-basen-haushalt",
            },
        ],
    },
    {
        id: "knochen",
        translations: [
            {
                language: "de",
                title: "Knochen",
                url: "https://www.lavita.de/wissen-gesundheit/knochen-und-gelenke",
            },
        ],
    },
    {
        id: "gelenke",
        translations: [
            {
                language: "de",
                title: "Gelenke",
                url: "https://www.lavita.de/wissen-gesundheit/knochen-und-gelenke",
            },
        ],
    },
    {
        id: "innere-ruhe",
        translations: [
            {
                language: "de",
                title: "Innere Ruhe",
                url: "https://www.lavita.de/wissen-gesundheit/innere-ruhe",
            },
        ],
    },
    {
        id: "gelassenheit",
        translations: [
            {
                language: "de",
                title: "Gelassenheit",
                url: "https://www.lavita.de/wissen-gesundheit/innere-ruhe",
            },
        ],
    },
    {
        id: "muedigkeit",
        translations: [
            {
                language: "de",
                title: "Müdigkeit",
                url: "https://www.lavita.de/gesundheitswelt/muedigkeit",
            },
        ],
    },
    {
        id: "zellschutz",
        translations: [
            {
                language: "de",
                title: "Zellschutz",
                url: "https://www.lavita.de/wissen-gesundheit/praevention",
            },
        ],
    },
    {
        id: "blutdruck",
        translations: [
            {
                language: "de",
                title: "Blutdruck",
                url: "https://www.lavita.de/wissen-gesundheit/blutdruck",
            },
        ],
    },
    {
        id: "abnehmen",
        translations: [
            {
                language: "de",
                title: "Abnehmen",
                url: "https://www.lavita.de/wissen-gesundheit/abnehmen",
            },
        ],
    },
    {
        id: "uebergewicht",
        translations: [
            {
                language: "de",
                title: "Übergewicht",
                url: "https://www.lavita.de/wissen-gesundheit/abnehmen",
            },
        ],
    },
    {
        id: "schwangerschaft",
        translations: [
            {
                language: "de",
                title: "Schwangerschaft",
                url: "https://www.lavita.de/gesundheitswelt/schwangerschaft",
            },
        ],
    },
    {
        id: "stillzeit",
        translations: [
            {
                language: "de",
                title: "Stillzeit",
                url: "https://www.lavita.de/wissen-gesundheit/stillzeit",
            },
        ],
    },
    {
        id: "lavita-saft",
        translations: [
            {
                language: "de",
                title: "LaVita Saft",
                url: "https://shop.lavita.de",
            },
            {
                language: "en",
                title: "LaVita juice",
                url: "https://shop.lavita.de",
            },
        ],
    },
    {
        id: "vollwertkonzentrat",
        translations: [
            {
                language: "de",
                title: "Vollwertkonzentrat",
                url: "https://www.lavita.de/mikronaehrstoffkonzentrat/warum-lavita",
            },
            {
                language: "en",
                title: "Full value concentrate",
                url: "https://www.lavita.de/micronutrient-concentrate/why-lavita",
            },
        ],
    },
    {
        id: "immunsystem",
        translations: [
            {
                language: "de",
                title: "Immunsystem",
                url: "https://www.lavita.de/gesundheitswelt/immunsystem",
            },
        ],
    },
    {
        id: "lavita-erfahrungen",
        translations: [
            {
                language: "de",
                title: "LaVita Erfahrungen",
                url: "https://www.lavita-erfahrungen.de/",
            },
        ],
    },
    {
        id: "lavita-alternative",
        translations: [
            {
                language: "de",
                title: "LaVita Alternative",
                url: "https://www.lavita.de/alternativen",
            },
        ],
    },
    {
        id: "lavita-auf-youtube",
        translations: [
            {
                language: "de",
                title: "LaVita",
                addition: "auf Youtube",
                url: "https://www.youtube.com/channel/UCK-A3JZXLqoLpq61ccbmVJw",
            },
        ],
    },
    {
        id: "lavita-auf-facebook",
        translations: [
            {
                language: "de",
                title: "LaVita",
                addition: "auf Facebook",
                url: "https://de-de.facebook.com/pg/lavita/reviews/",
            },
        ],
    },
    {
        id: "lavita-auf-instagram",
        translations: [
            {
                language: "de",
                title: "LaVita",
                addition: "auf Instagram",
                url: "https://www.instagram.com/lavita_de/",
            },
        ],
    },
    {
        id: "lavita-auf-pinterest",
        translations: [
            {
                language: "de",
                title: "LaVita",
                addition: "auf Pinterest",
                url: "https://www.pinterest.de/lavita/",
            },
        ],
    },
    {
        id: "lavita-auf-linkedin",
        translations: [
            {
                language: "de",
                title: "LaVita",
                addition: "auf LinkedIn",
                url: "https://www.linkedin.com/company/lavita-gmbh/",
            },
        ],
    },
    {
        id: "lavita-auf-xing",
        translations: [
            {
                language: "de",
                title: "LaVita",
                addition: "auf Xing",
                url: "https://www.xing.com/pages/lavitagmbh",
            },
        ],
    },
    {
        id: "lavita-auf-trusted-shops",
        translations: [
            {
                language: "de",
                title: "LaVita",
                addition: "auf Trusted Shops",
                url: "https://www.trustedshops.de/bewertung/info_X49E710FC236D0FD6604F8259314C8A7F.html",
            },
        ],
    },
    {
        id: "lavita-auf-kununu",
        translations: [
            {
                language: "de",
                title: "LaVita",
                addition: "auf Kununu",
                url: "https://www.kununu.com/de/lavita",
            },
        ],
    },
    {
        id: "lavita-im-app-store",
        translations: [
            {
                language: "de",
                title: "LaVita",
                addition: "im App Store",
                url: "https://apps.apple.com/de/app/lavita/id1484793998",
            },
        ],
    },
    {
        id: "lavita-auf-google-play",
        translations: [
            {
                language: "de",
                title: "LaVita",
                addition: "auf Google Play",
                url: "https://play.google.com/store/apps/details?id=de.lavita.lavitaapp",
            },
        ],
    },
    {
        id: "lavita-test-focus",
        translations: [
            {
                language: "de",
                title: "LaVita Test",
                addition: "(Focus.de)",
                url: "https://unternehmen.focus.de/lavita-vitalstoffkonzentrat-im-test.html",
            },
        ],
    },
    {
        id: "lavita-test-handelsblatt",
        translations: [
            {
                language: "de",
                title: "LaVita Test",
                addition: "(Handelsblatt.com)",
                url: "https://firmen.handelsblatt.com/lavita-im-test-die-wahrheit-vorteile-und-nachteile.html",
            },
        ],
    },
    {
        id: "lavita-test-gesundheit",
        translations: [
            {
                language: "de",
                title: "LaVita Test",
                addition: "(Gesundheit.de)",
                url: "https://www.gesundheit.de/ernaehrung/naehrstoffe/naehrstoffwissen/mikronaehrstoffkonzentrate-test",
            },
        ],
    },
    {
        id: "lavita-alternative-welt",
        translations: [
            {
                language: "de",
                title: "LaVita Alternative",
                addition: "(Welt.de)",
                url: "https://unternehmen.welt.de/essen-trinken/lavita-alternative-vitalstoffkonzentrat.html",
            },
        ],
    },
    {
        id: "mikronaehrstoffkonzentrat-faz",
        translations: [
            {
                language: "de",
                title: "Mikronährstoffkonzentrat",
                addition: "(FAZ)",
                url: "https://firmen.faz.net/lavita-bestellen-mikronaehrstoffkonzentrat-test.html",
            },
        ],
    },
    {
        id: "lavita-erfahrungen-faz",
        translations: [
            {
                language: "de",
                title: "LaVita Erfahrungen",
                addition: "(FAZ)",
                url: "https://firmen.faz.net/lavita-erfahrungen.html",
            },
        ],
    },
    {
        id: "lavita-erfahrungen-eatsmarter",
        translations: [
            {
                language: "de",
                title: "LaVita Erfahrungen",
                addition: "(EatSmarter)",
                url: "https://eatsmarter.de/ernaehrung/gesund-ernaehren/lavita-erfahrungen",
            },
        ],
    },
    {
        id: "lavita-kaufen-faz",
        translations: [
            {
                language: "de",
                title: "LaVita Kaufen",
                addition: "(FAZ)",
                url: "https://firmen.faz.net/lavita-saft-kaufen.html",
            },
        ],
    },
    {
        id: "lavita-kaufen",
        translations: [
            {
                language: "de",
                title: "LaVita kaufen",
                url: "https://lavita.de/wo-kann-ich-lavita-kaufen",
            },
        ],
    },
    {
        id: "gesunde-ernaehrung-mit-lavita-nzz",
        translations: [
            {
                language: "de",
                title: "Gesunde Ernährung mit LaVita",
                addition: "(NZZ)",
                url: "https://bellevue.nzz.ch/promoted-content/gesunde-ernaehrung-mit-lavita-ld.1693672",
            },
        ],
    },
    {
        id: "lavita-alternative-nzz",
        translations: [
            {
                language: "de",
                title: "LaVita Alternative",
                addition: "(NZZ)",
                url: "https://www.nzz.ch/promoted-content/lavita-alternative-vitamintabletten-ld.1697501",
            },
        ],
    },
    {
        id: "lavita-erfahrungen-nzz",
        translations: [
            {
                language: "de",
                title: "LaVita Erfahrungen",
                addition: "(NZZ)",
                url: "https://bellevue.nzz.ch/promoted-content/lavita-erfahrungen-test-ld.1691154",
            },
        ],
    },
    {
        id: "lavita-erfahrungen-swissmom",
        translations: [
            {
                language: "de",
                title: "LaVita Erfahrungen",
                addition: "(Swissmom)",
                url: "https://www.swissmom.ch/de/swissmom-content-creation/lavita-saft-erfahrungen-100807",
            },
        ],
    },
    {
        id: "lavita-kaufen-nzz",
        translations: [
            {
                language: "de",
                title: "LaVita Kaufen",
                addition: "(NZZ)",
                url: "https://www.nzz.ch/promoted-content/lavita-saft-kaufen-ld.1693482",
            },
        ],
    },
    {
        id: "lavita-alternative-swissmom",
        translations: [
            {
                language: "de",
                title: "LaVita Alternative",
                addition: "(EatSmarter)",
                url: "https://eatsmarter.de/ernaehrung/gesund-ernaehren/mikronaehrstoffkonzentrat-lavita-eine-alternative-zu-pillen-co",
            },
        ],
    },
    {
        id: "schwangerschaftsanzeichen",
        translations: [
            {
                language: "de",
                title: "Schwangerschaftsanzeichen",
                url: "https://www.lavita.de/gesundheitswelt/schwangerschaft/schwangerschaftsanzeichen",
            },
        ],
    },
    {
        id: "vitamine-gegen-muedigkeit",
        translations: [
            {
                language: "de",
                title: "Vitamine gegen Müdigkeit",
                url: "https://www.lavita.de/gesundheitswelt/muedigkeit/vitamine-gegen-muedigkeit",
            },
        ],
    },
    {
        id: "basenfasten",
        translations: [
            {
                language: "de",
                title: "Basenfasten",
                url: "https://www.lavita-magazin.de/basenfasten",
            },
        ],
    },
    {
        id: "fruehschwangerschaft",
        translations: [
            {
                language: "de",
                title: "Frühschwangerschaft",
                url: "https://www.lavita.de/gesundheitswelt/schwangerschaft/fruehschwangerschaft",
            },
        ],
    },
    {
        id: "immunsystem-staerken-kinder",
        translations: [
            {
                language: "de",
                title: "Immunsystem stärken Kinder",
                url: "https://www.lavita.de/gesundheitswelt/immunsystem/immunsystem-staerken-kinder",
            },
        ],
    },
    {
        id: "lavita-erfahrungen-test-krone",
        translations: [
            {
                language: "de",
                title: "LaVita Erfahrungen & Test",
                addition: "(Krone)",
                url: "https://www.krone.at/2885791",
            },
        ],
    },
    {
        id: "lavita-erfahrungen-test-falstaff",
        translations: [
            {
                language: "de",
                title: "LaVita Erfahrungen & Test",
                addition: "(Falstaff)",
                url: "https://www.falstaff.at/nd/lavita-erfahrungen-test/",
            },
        ],
    },
    {
        id: "lavita-kaufen-kurier",
        translations: [
            {
                language: "de",
                title: "LaVita kaufen",
                addition: "(Kurier)",
                url: "https://kurier.at/cm/wo-koennen-sie-lavita-kaufen-wir-verraten-es/402260799",
            },
        ],
    },
    {
        id: "lavita-test-sz",
        translations: [
            {
                language: "de",
                title: "LaVita Test",
                addition: "(SZ)",
                url: "https://www.sueddeutsche.de/firmen-eintraege/lavita-vitalstoffkonzentrat-im-test.html",
            },
        ],
    },
    {
        id: "lavita-vitamine-inhaltsstoffe-issgesund",
        translations: [
            {
                language: "de",
                title: "LaVita Vitamine & Inhaltsstoffe",
                addition: "(Issgesund)",
                url: "https://www.issgesund.at/a/lavita-vitamine-inhaltsstoffe",
            },
        ],
    },
    {
        id: "mikronaehrstoffkonzentrat-ecowoman",
        translations: [
            {
                language: "de",
                title: "Mikronährstoffkonzentrat",
                addition: "(Ecowoman)",
                url: "https://www.ecowoman.de/koerper-geist/gesundheit/alles-wichtige-ueber-mikronaehrstoffkonzentrate-inklusive-test-9757",
            },
        ],
    },
    {
        id: "muedigkeit-schwangerschaft",
        translations: [
            {
                language: "de",
                title: "Müdigkeit Schwangerschaft",
                url: "https://www.lavita.de/gesundheitswelt/muedigkeit/muedigkeit-schwangerschaft",
            },
        ],
    },
    {
        id: "was-darf-man-in-der-schwangerschaft-nicht-essen",
        translations: [
            {
                language: "de",
                title: "Was darf man in der Schwangerschaft nicht essen",
                url: "https://www.lavita.de/gesundheitswelt/schwangerschaft/was-darf-man-in-der-schwangerschaft-nicht-essen",
            },
        ],
    },
    {
        id: "was-hilft-gegen-muedigkeit",
        translations: [
            {
                language: "de",
                title: "Was hilft gegen Müdigkeit",
                url: "https://www.lavita.de/gesundheitswelt/muedigkeit/was-tun-gegen-muedigkeit",
            },
        ],
    },
]

export { sitemapMenu }
