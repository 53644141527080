import { Icons, iconOptions, options } from "../../types"
import { TextLink, TextLinkColor, TextLinkSize } from "../components/text"
import { openOverlay, useOverlayDispatch } from "../../shared/context/overlay"

import React from "react"
import { VideoOverlay } from "../components/video"
import { types } from "react-bricks/frontend"

export interface TextLinkBrickProps {
    href: string
    target?: "_blank" | "_self"
    icon: Icons
    iconDisplay: boolean
    title: string
    index?: number
    size?: TextLinkSize
    color?: TextLinkColor
    cloudflareVideoOverlay?: string
    identifier?: string
}

const TextLinkBrick: types.Brick<TextLinkBrickProps> = ({
    index,
    href,
    target,
    icon,
    iconDisplay,
    title,
    size,
    color,
    cloudflareVideoOverlay,
    identifier,
}) => {
    const overlayDispatch = useOverlayDispatch()

    return (
        <div key={index}>
            <TextLink
                href={href}
                target={target}
                onClick={cloudflareVideoOverlay ? () => {
                    if (cloudflareVideoOverlay) {
                        openOverlay(
                            overlayDispatch,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            <VideoOverlay cloudflareVideoId={cloudflareVideoOverlay} />
                        )
                    }
                    if (!identifier) return

                    window.exponea?.track("calltoaction", {
                        action: "clicked",
                        title: title,
                        identifier: identifier,
                    })
                } : undefined}
                withOnClick={() => {
                    if (!identifier) return

                    window.exponea?.track("calltoaction", {
                        action: "clicked",
                        title: title,
                        identifier: identifier,
                    })
                }}
                icon={icon}
                iconDisplay={iconDisplay}
                title={title}
                size={size}
                color={color}
            />
        </div>
    )
}

TextLinkBrick.schema = {
    name: "TextLink",
    label: "Text Link",
    hideFromAddMenu: true,
    sideEditProps: [
        {
            name: "href",
            label: "href",
            type: types.SideEditPropType.Text,
        },
        {
            name: "target",
            label: "Target",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: [
                    {
                        value: "_self",
                        label: "Self",
                    },
                    {
                        value: "_blank",
                        label: "Blank",
                    },
                ],
                display: types.OptionsDisplay.Select,
            },
        },
        {
            name: "iconDisplay",
            label: "show/hide Icon",
            type: types.SideEditPropType.Boolean,
        },
        {
            name: "icon",
            label: "Icon",
            show: props => props.iconDisplay,
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: iconOptions,
                display: types.OptionsDisplay.Select,
            },
        },
        {
            name: "title",
            label: "Title",
            type: types.SideEditPropType.Text,
        },
        {
            name: "color",
            label: "Color",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: options(TextLinkColor),
                display: types.OptionsDisplay.Select,
            },
        },
        {
            name: "size",
            label: "Size",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: options(TextLinkSize),
                display: types.OptionsDisplay.Select,
            },
        },
        {
            name: "cloudflareVideoOverlay",
            label: "Cloudflare Video Overlay",
            type: types.SideEditPropType.Text,
        },
        {
            groupName: "Tracking",
            props: [
                {
                    name: "identifier",
                    label: "Identifier Bloomreach",
                    type: types.SideEditPropType.Text,
                },
            ],
        },
    ],
    getDefaultProps: () => ({
        href: process.env.SITE_URL,
        iconDisplay: true,
        icon: Icons.link,
        title: "LaVita",
        color: TextLinkColor.Blue,
        size: TextLinkSize.Base,
    }),
}

export default TextLinkBrick
