import FlexWrapperStyles from "./flex-wrapper.module.css"
import React from "react"
import classNames from "classnames"

const FlexWrapper = props => {
    return (
        <div className={classNames(FlexWrapperStyles.wrapper, { [FlexWrapperStyles.wrap]: props.wrap })}>
            {props.children.map((child, i) => (
                <div
                    style={{
                        width: props.childWidths ? `${props.childWidths[i]}%` : undefined,
                        flex: props.flex?.[i],
                    }}
                    key={i}
                >
                    {child}
                </div>
            ))}
        </div>
    )
}

export default FlexWrapper
