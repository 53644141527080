import { findCurrencySymbol } from "./find-currency-symbol"

const formatPrice = (value, currency, language) => {
    const priceString = [
        Number.isInteger(value) || !value
            ? value
            : value.toLocaleString(language, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
              }),
        findCurrencySymbol(currency),
    ]

    // join with non-breaking space as unicode \u00a0
    return priceString.join("\u00a0")
}

const formatIban = number => {
    if (!number) return
    return number.replace(/\s/g, "").replace(/∗+/, "∗∗∗∗∗∗∗∗∗")
}

export { formatPrice, formatIban }
