const genders = [
    {
        id: "Frau",
        name: "configuration_gender_female",
        visibleInAddress: true,
    },
    {
        id: "Herr",
        name: "configuration_gender_male",
        visibleInAddress: true,
    },
    {
        id: "Divers",
        name: "configuration_gender_other",
        visibleInAddress: false,
    },
]

export { genders }
