import { Background, BackgroundColor, Container, Spacer, SpacerVariant, backgroundOptions } from "../components/layout"
import { Repeater, types } from "react-bricks/frontend"

import { CenterTextProps } from "../text/CenterText"
import React from "react"
import { TypographyColor } from "../components/typography"

interface MasterColumnIconTextProps {
    background: { color: BackgroundColor } | BackgroundColor
    spacerTop: boolean
    spacerBottom: boolean
}

const MasterColumnIconText: types.Brick<MasterColumnIconTextProps> = ({ background, spacerTop, spacerBottom }) => {
    const bgColor = typeof background === "object" ? background.color : background

    return (
        <Background color={bgColor}>
            <Container>
                <Spacer variant={!spacerTop ? SpacerVariant.None : undefined} />
                <Repeater
                    propName="centerText"
                    itemProps={
                        {
                            color: bgColor === BackgroundColor.Green ? TypographyColor.White : TypographyColor.Darkgrey,
                        } as CenterTextProps
                    }
                />
                <Repeater propName="repeater" />
                <Spacer variant={!spacerBottom ? SpacerVariant.None : undefined} />
            </Container>
        </Background>
    )
}

MasterColumnIconText.schema = {
    name: "MasterColumnIconText",
    label: "Master Column Icon Text",
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "IconTextGrid",
                    label: "Icon Text Grid",
                    min: 1,
                    max: 1,
                },
            ],
        },
        {
            name: "centerText",
            items: [
                {
                    type: "CenterText",
                    label: "Text",
                    max: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "background",
            label: "Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: backgroundOptions,
                display: types.OptionsDisplay.Color,
            },
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: (): MasterColumnIconTextProps => ({
        background: { color: BackgroundColor.White },
        spacerTop: true,
        spacerBottom: true,
    }),
}

export default MasterColumnIconText
