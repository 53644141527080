import ImageEditor, { AspectRatio } from "../../Image"
import { Repeater, types } from "react-bricks/frontend"
import Typography, { TypographyElement } from "../../Typography"

import { Color } from "../../components/typography"
import React from "react"
import { TextLinkBrickProps } from "../../text/TextLink"
import { TextLinkSize } from "../../components/text"
import classNames from "classnames"

export interface ImageTextSliderItemProps {
    text: TypographyElement[]
    subText: TypographyElement[]
    activeIndex: number
    index?: number
}

const ImageTextSliderItem: types.Brick<ImageTextSliderItemProps> = ({ index, activeIndex }) => {
    return (
        <div
            key={index}
            className={classNames("flex flex-col space-y-7 sm:space-y-5 md:space-y-7", {
                "col-span-4 sm:col-span-1": index !== Math.round(activeIndex),
                "col-span-5 sm:col-span-1": index === Math.round(activeIndex),
            })}
        >
            <div
                className={classNames("aspect-w-5", {
                    "aspect-h-5 sm:aspect-h-4": index !== Math.round(activeIndex),
                    "aspect-h-4": index === Math.round(activeIndex),
                })}
            >
                <div>
                    <ImageEditor
                        aspectRatio={AspectRatio["5/4"]}
                        maxWidth={717}
                        className={classNames("overflow-hidden rounded-base", {
                            "absolute top-1/2 -translate-y-1/2": index !== Math.round(activeIndex),
                        })}
                    />
                </div>
            </div>
            <div
                className={classNames("space-y-3.5 text-center child:mx-auto sm:mx-0 sm:space-y-5 sm:text-left", {
                    "hidden sm:block": index !== Math.round(activeIndex),
                })}
            >
                <Color>
                    <Typography block="image-text-slider" allowList={["sup", "link", "bold"]} />
                </Color>
                <Color>
                    <Typography block="copytext-extra-small" allowList={["small", "sup", "link"]} propName="subText" />
                </Color>
                <Repeater propName="repeater" itemProps={{ size: TextLinkSize.Small } as TextLinkBrickProps} />
            </div>
        </div>
    )
}

ImageTextSliderItem.schema = {
    name: "ImageTextSliderItem",
    label: "Image Text Slider Item",
    hideFromAddMenu: true,
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "TextLink",
                    label: "Text Link",
                    max: 1,
                },
            ],
        },
    ],
    getDefaultProps: (): Omit<ImageTextSliderItemProps, "activeIndex"> => ({
        text: [
            {
                type: "paragraph",
                children: [
                    {
                        bold: true,
                        text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
                    },
                ],
            },
        ],
        subText: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum, dolor sit amet",
                    },
                ],
            },
        ],
    }),
}

export default ImageTextSliderItem
