const menu = [
    {
        translations: [
            {
                language: "de",
                title: "Produkt",
            },
            {
                language: "en",
                title: "Product",
            },
            {
                language: "sv",
                title: "Produkt",
            },
            {
                language: "fr",
                title: "Produit",
            },
            {
                language: "it",
                title: "Prodotto",
            },
            {
                language: "nl",
                title: "Product",
            },
            {
                language: "es",
                title: "Producto",
            },
            {
                language: "no",
                title: "Produkt",
            },
            {
                language: "da",
                title: "Produkt",
            },
            {
                language: "tr",
                title: "LaVita Hakkında",
            },
            {
                language: "cs",
                title: "Produkt",
            },
        ],
        id: "produkt",
        url: null,
        options: [
            {
                translations: [
                    {
                        language: "de",
                        title: "Das Mikronährstoffkonzentrat",
                    },
                    {
                        language: "en",
                        title: "The micronutrient concentrate",
                    },
                    {
                        language: "sv",
                        title: "Koncentrat av mikronäringsämnen",
                    },
                    {
                        language: "fr",
                        title: "Le concentré de micronutriments",
                    },
                    {
                        language: "it",
                        title: "Il concentrato di micronutrienti",
                    },
                    {
                        language: "nl",
                        title: "Het concentraat van micronutriënten",
                    },
                    {
                        language: "es",
                        title: "El concentrado de micronutrientes",
                    },
                    {
                        language: "no",
                        title: "Konsentrat av mikronæringsstoffer",
                    },
                    {
                        language: "da",
                        title: "Koncentrat af mikronæringsstoffer",
                    },
                    {
                        language: "tr",
                        title: "Mikro Besin Konsantresi",
                    },
                    {
                        language: "cs",
                        title: "Mikronutriční koncentrát",
                    },
                ],
                id: "das_mikronaehrstoffkonzentrat",
                url: null,
                options: [
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Das ist LaVita",
                                url: "https://www.lavita.de/mikronaehrstoffkonzentrat/das-ist-lavita",
                            },
                            {
                                language: "en",
                                title: "About LaVita",
                                url: "https://www.lavita.de/micronutrient-concentrate/about-lavita",
                            },
                            {
                                language: "sv",
                                title: "Om LaVita",
                                url: "https://www.lavita.de/koncentration-av-mikronaringsamnen/om-lavita",
                            },
                            {
                                language: "fr",
                                title: "C’est LaVita",
                                url: "https://www.lavita.de/concentration-en-micronutriments/a-propos-de-lavita",
                            },
                            {
                                language: "it",
                                title: "Informazioni su LaVita",
                                url: "https://www.lavita.de/micronutrienti-concentrati/informazioni-su-lavita",
                            },
                            {
                                language: "nl",
                                title: "Over LaVita",
                                url: "https://www.lavita.de/micronutrient-concentraat/over-lavita",
                            },
                            {
                                language: "es",
                                title: "Acerca de LaVita",
                                url: "https://www.lavita.de/micronutrientes-concentrados/sobre-lavita",
                            },
                            {
                                language: "no",
                                title: "Om LaVita",
                                url: "https://www.lavita.de/mikronaeringsstoff-konsentrasjon/om-lavita",
                            },
                            {
                                language: "da",
                                title: "Om LaVita",
                                url: "https://www.lavita.de/mikronaeringsstof-koncentration/om-lavita",
                            },
                            {
                                language: "tr",
                                title: "LaVita Hakkında",
                                url: "https://www.lavita.de/mikro-besin-konsantresi/lavita-hakkinda",
                            },
                            {
                                language: "cs",
                                title: "O LaVitě",
                                url: "https://www.lavita.de/mikronutricni-koncentrat/co-je-lavita",
                            },
                        ],
                        id: "das_ist_lavita",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Darum LaVita",
                                url: "https://www.lavita.de/mikronaehrstoffkonzentrat/warum-lavita",
                            },
                            {
                                language: "en",
                                title: "Why we need LaVita",
                                url: "https://www.lavita.de/micronutrient-concentrate/why-lavita",
                            },
                            {
                                language: "sv",
                                title: "Varför LaVita",
                                url: "https://www.lavita.de/koncentration-av-mikronaringsamnen/varfoer-lavita",
                            },
                            {
                                language: "fr",
                                title: "Pourquoi LaVita",
                                url: "https://www.lavita.de/concentration-en-micronutriments/pourquoi-lavita",
                            },
                            {
                                language: "it",
                                title: "Perché LaVita",
                                url: "https://www.lavita.de/micronutrienti-concentrati/perche-lavita",
                            },
                            {
                                language: "nl",
                                title: "Waarom LaVita",
                                url: "https://www.lavita.de/micronutrient-concentraat/waarom-lavita",
                            },
                            {
                                language: "es",
                                title: "Por qué LaVita",
                                url: "https://www.lavita.de/micronutrientes-concentrados/por-que-lavita",
                            },
                            {
                                language: "no",
                                title: "Hvorfor LaVita",
                                url: "https://www.lavita.de/mikronaeringsstoff-konsentrasjon/hvorfor-lavita",
                            },
                            {
                                language: "da",
                                title: "Hvorfor LaVita",
                                url: "https://www.lavita.de/mikronaeringsstof-koncentration/hvorfor-lavita",
                            },
                            {
                                language: "tr",
                                title: "Neden LaVita",
                                url: "https://www.lavita.de/mikro-besin-konsantresi/neden-lavita",
                            },
                            {
                                language: "cs",
                                title: "Proč LaVitu",
                                url: "https://www.lavita.de/mikronutricni-koncentrat/proc-lavitu",
                            },
                        ],
                        id: "darum_lavita",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Wirkungen",
                                url: "https://www.lavita.de/mikronaehrstoffkonzentrat/wirkung",
                            },
                            {
                                language: "en",
                                title: "Effects",
                                url: "https://www.lavita.de/micronutrient-concentrate/effects",
                            },
                            {
                                language: "sv",
                                title: "Effekter",
                                url: "https://www.lavita.de/koncentration-av-mikronaringsamnen/effekter",
                            },
                            {
                                language: "fr",
                                title: "Effets",
                                url: "https://www.lavita.de/concentration-en-micronutriments/effets",
                            },
                            {
                                language: "it",
                                title: "Effetti",
                                url: "https://www.lavita.de/micronutrienti-concentrati/effetti",
                            },
                            {
                                language: "nl",
                                title: "Effecten",
                                url: "https://www.lavita.de/micronutrient-concentraat/effecten",
                            },
                            {
                                language: "es",
                                title: "Efectos",
                                url: "https://www.lavita.de/micronutrientes-concentrados/efectos",
                            },
                            {
                                language: "no",
                                title: "Effekter",
                                url: "https://www.lavita.de/mikronaeringsstoff-konsentrasjon/effekter",
                            },
                            {
                                language: "da",
                                title: "Effekter",
                                url: "https://www.lavita.de/mikronaeringsstof-koncentration/effekter",
                            },
                            {
                                language: "tr",
                                title: "Etkileri",
                                url: "https://www.lavita.de/mikro-besin-konsantresi/etkileri",
                            },
                            {
                                language: "cs",
                                title: "Účinky",
                                url: "https://www.lavita.de/mikronutricni-koncentrat/ucinky",
                            },
                        ],
                        id: "wirkungen",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Das steckt drin",
                                url: "https://www.lavita.de/mikronaehrstoffkonzentrat/zutaten",
                            },
                            {
                                language: "en",
                                title: "Ingredients",
                                url: "https://www.lavita.de/micronutrient-concentrate/ingredients",
                            },
                            {
                                language: "sv",
                                title: "Ingredienser",
                                url: "https://www.lavita.de/koncentration-av-mikronaringsamnen/ingredienser",
                            },
                            {
                                language: "fr",
                                title: "Ingrédients",
                                url: "https://www.lavita.de/concentration-en-micronutriments/ingredients",
                            },
                            {
                                language: "it",
                                title: "Ingredienti",
                                url: "https://www.lavita.de/micronutrienti-concentrati/ingredienti",
                            },
                            {
                                language: "nl",
                                title: "Ingrediënten",
                                url: "https://www.lavita.de/micronutrient-concentraat/ingredienten",
                            },
                            {
                                language: "es",
                                title: "Ingredientes",
                                url: "https://www.lavita.de/micronutrientes-concentrados/ingredientes",
                            },
                            {
                                language: "no",
                                title: "Ingredienser",
                                url: "https://www.lavita.de/mikronaeringsstoff-konsentrasjon/ingredienser",
                            },
                            {
                                language: "da",
                                title: "Ingredienser",
                                url: "https://www.lavita.de/mikronaeringsstof-koncentration/ingredienser",
                            },
                            {
                                language: "tr",
                                title: "İçindekiler",
                                url: "https://www.lavita.de/mikro-besin-konsantresi/icindekiler",
                            },
                            {
                                language: "cs",
                                title: "Naše suroviny",
                                url: "https://www.lavita.de/mikronutricni-koncentrat/suroviny",
                            },
                        ],
                        id: "zutaten",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Nährwerte",
                                url: "https://www.lavita.de/mikronaehrstoffkonzentrat/naehrwerte",
                            },
                            {
                                language: "en",
                                title: "Nutrition Table",
                                url: "https://www.lavita.de/micronutrient-concentrate/nutrition-table",
                            },
                            {
                                language: "sv",
                                title: "Näringstabell",
                                url: "https://www.lavita.de/koncentration-av-mikronaringsamnen/naringstabell",
                            },
                            {
                                language: "fr",
                                title: "Tableau nutritionnel",
                                url: "https://www.lavita.de/concentration-en-micronutriments/tableau-nutritionnel",
                            },
                            {
                                language: "it",
                                title: "Tabella nutrizionale",
                                url: "https://www.lavita.de/micronutrienti-concentrati/tabella-nutrizionale",
                            },
                            {
                                language: "nl",
                                title: "Voedingstabel",
                                url: "https://www.lavita.de/micronutrient-concentraat/voedingstabel",
                            },
                            {
                                language: "es",
                                title: "Tabla nutricional",
                                url: "https://www.lavita.de/micronutrientes-concentrados/tabla-nutricional",
                            },
                            {
                                language: "no",
                                title: "Ernæringstabell",
                                url: "https://www.lavita.de/mikronaeringsstoff-konsentrasjon/naeringstabell",
                            },
                            {
                                language: "da",
                                title: "Ernæringstabel",
                                url: "https://www.lavita.de/mikronaeringsstof-koncentration/ernaeringstabel",
                            },
                            {
                                language: "tr",
                                title: "Besin Değerleri Tablosu",
                                url: "https://www.lavita.de/mikro-besin-konsantresi/besin-degeri-tablosu",
                            },
                            {
                                language: "cs",
                                title: "Tabulka nutričních hodnot",
                                url: "https://www.lavita.de/mikronutricni-koncentrat/nutricni-hodnoty",
                            },
                        ],
                        id: "naehrwerte",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Anwendung & Zubereitung",
                                url: "https://www.lavita.de/service/anwendung-zubereitung",
                            },
                            {
                                language: "en",
                                title: "Preparation & Usage",
                                url: "https://www.lavita.de/service/how-to-use",
                            },
                            {
                                language: "sv",
                                title: "Beredning och användning",
                                url: "https://www.lavita.de/service/hur-man-anvander",
                            },
                            {
                                language: "fr",
                                title: "Préparation et utilisation",
                                url: "https://www.lavita.de/service/comment-lutiliser",
                            },
                            {
                                language: "it",
                                title: "Preparazione e uso",
                                url: "https://www.lavita.de/servizio/come-si-usa",
                            },
                            {
                                language: "nl",
                                title: "Bereiding en gebruik",
                                url: "https://www.lavita.de/service/hoe-te-gebruiken",
                            },
                            {
                                language: "es",
                                title: "Preparación y uso",
                                url: "https://www.lavita.de/servicio/como-utilizarlo",
                            },
                            {
                                language: "no",
                                title: "Tilberedning og bruk",
                                url: "https://www.lavita.de/service/hvordan-bruke",
                            },
                            {
                                language: "da",
                                title: "Forberedelse og brug",
                                url: "https://www.lavita.de/service/sadan-bruger-du",
                            },
                            {
                                language: "tr",
                                title: "Kullanım",
                                url: "https://www.lavita.de/mikro-besin-konsantresi/kullanim",
                            },
                            {
                                language: "cs",
                                title: "Příprava a užívání",
                                url: "https://www.lavita.de/informace/uzivani",
                            },
                        ],
                        id: "anwendung_zubereitung",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Qualitätsversprechen",
                                url: "https://www.lavita.de/mikronaehrstoffkonzentrat/qualitaetsversprechen",
                            },
                            {
                                language: "en",
                                title: "Quality Promise",
                                url: "https://www.lavita.de/micronutrient-concentrate/quality-promise",
                            },
                            {
                                language: "sv",
                                title: "Löfte om kvalitet",
                                url: "https://www.lavita.de/koncentration-av-mikronaringsamnen/lofte-om-kvalitet",
                            },
                            {
                                language: "fr",
                                title: "Promesse de qualité",
                                url: "https://www.lavita.de/concentration-en-micronutriments/promesse-de-qualite",
                            },
                            {
                                language: "it",
                                title: "Promessa di qualità",
                                url: "https://www.lavita.de/micronutrienti-concentrati/promessa-di-qualita",
                            },
                            {
                                language: "nl",
                                title: "Kwaliteitsbelofte",
                                url: "https://www.lavita.de/micronutrient-concentraat/kwaliteitsbelofte",
                            },
                            {
                                language: "es",
                                title: "Promesa de calidad",
                                url: "https://www.lavita.de/micronutrientes-concentrados/promesa-de-calidad",
                            },
                            {
                                language: "no",
                                title: "Løfte om kvalitet",
                                url: "https://www.lavita.de/mikronaeringsstoff-konsentrasjon/lofte-om-kvalitet",
                            },
                            {
                                language: "da",
                                title: "Løfte om kvalitet",
                                url: "https://www.lavita.de/mikronaeringsstof-koncentration/lofte-om-kvalitet",
                            },
                            {
                                language: "tr",
                                title: "Kalite Taahhüdü",
                                url: "https://www.lavita.de/mikro-besin-konsantresi/kalite-taahhudu",
                            },
                            {
                                language: "cs",
                                title: "Záruka kvality",
                                url: "https://www.lavita.de/mikronutricni-koncentrat/zaruka-kvality",
                            },
                        ],
                        id: "qualitaetsversprechen",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Kundenstimmen",
                                url: "https://www.lavita.de/mikronaehrstoffkonzentrat/kundenstimmen",
                            },
                        ],
                        id: "kundenstimmen",
                        options: [],
                    },
                ],
            },
            {
                translations: [
                    {
                        language: "de",
                        title: "Studien",
                    },
                    {
                        language: "en",
                        title: "Study",
                    },
                    {
                        language: "sv",
                        title: "Studie",
                    },
                    {
                        language: "fr",
                        title: "L'étude",
                    },
                    {
                        language: "it",
                        title: "Studio",
                    },
                    {
                        language: "nl",
                        title: "Studie",
                    },
                    {
                        language: "es",
                        title: "Estudiar",
                    },
                    {
                        language: "no",
                        title: "Studie",
                    },
                    {
                        language: "da",
                        title: "Undersøgelse",
                    },
                    {
                        language: "tr",
                        title: "Bilimsel Çalışmalar",
                    },
                    {
                        language: "cs",
                        title: "Studie",
                    },
                ],
                id: "studien",
                url: null,
                options: [
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Bioverfügbarkeitsstudie",
                                url: "https://www.lavita.de/mikronaehrstoffkonzentrat/studien/bioverfuegbarkeit",
                            },
                            {
                                language: "en",
                                title: "Bioavailability study",
                                url: "https://www.lavita.de/micronutrient-concentrate/study/bioavailability",
                            },
                            {
                                language: "sv",
                                title: "Undersökning av biotillgänglighet",
                                url: "https://www.lavita.de/koncentration-av-mikronaringsamnen/studie/biotillganglighet",
                            },
                            {
                                language: "fr",
                                title: "Étude de biodisponibilité",
                                url: "https://www.lavita.de/concentration-en-micronutriments/etude/biodisponibilite",
                            },
                            {
                                language: "it",
                                title: "Studio di biodisponibilità",
                                url: "https://www.lavita.de/micronutrienti-concentrati/studio/biodisponibilita",
                            },
                            {
                                language: "nl",
                                title: "Onderzoek naar biologische beschikbaarheid",
                                url: "https://www.lavita.de/micronutrient-concentraat/onderzoek/biobeschikbaarheid",
                            },
                            {
                                language: "es",
                                title: "Estudio de biodisponibilidad",
                                url: "https://www.lavita.de/micronutrientes-concentrados/estudiar/biodisponibilidad",
                            },
                            {
                                language: "no",
                                title: "Studie av biotilgjengelighet",
                                url: "https://www.lavita.de/mikronaeringsstoff-konsentrasjon/studie/biotilgjengelighet",
                            },
                            {
                                language: "da",
                                title: "Undersøgelse af biotilgængelighed",
                                url: "https://www.lavita.de/mikronaeringsstof-koncentration/studie/biotilgaengelighed",
                            },
                            {
                                language: "tr",
                                title: "Biyoyararlanım  Çalışmasi",
                                url: "https://www.lavita.de/mikro-besin-konsantresi/biyoyararlanim",
                            },
                            {
                                language: "cs",
                                title: "Studie biodostupnosti",
                                url: "https://www.lavita.de/mikronutricni-koncentrat/studie/biodostupnost",
                            },
                        ],
                        id: "bioverfuegbarkeitsstudie",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "BEV-Analyse",
                                url: "https://www.lavita.de/mikronaehrstoffkonzentrat/studien/bev-analyse",
                            },
                        ],
                        id: "bev_analyse",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Antioxidative Kapazität",
                                url: "https://www.lavita.de/mikronaehrstoffkonzentrat/studien/antioxidative-kapazitaet",
                            },
                        ],
                        id: "antioxidative_kapazitaet",
                        options: [],
                    },
                ],
            },
        ],
    },
    {
        translations: [
            {
                language: "de",
                title: "Service",
            },
            {
                language: "en",
                title: "Service",
            },
            {
                language: "sv",
                title: "Service",
            },
            {
                language: "fr",
                title: "Service",
            },
            {
                language: "it",
                title: "Servizio",
            },
            {
                language: "nl",
                title: "Service",
            },
            {
                language: "es",
                title: "Servicio",
            },
            {
                language: "no",
                title: "Service",
            },
            {
                language: "da",
                title: "Service",
            },
            {
                language: "tr",
                title: "Faydalı Bilgiler",
            },
            {
                language: "cs",
                title: "Užitečné informace",
            },
        ],
        id: "service",
        url: null,
        options: [
            {
                translations: [
                    {
                        language: "de",
                        title: "Hilfe",
                    },
                    {
                        language: "en",
                        title: "Help",
                    },
                    {
                        language: "sv",
                        title: "Hjälp",
                    },
                    {
                        language: "fr",
                        title: "Aide",
                    },
                    {
                        language: "it",
                        title: "Aiuto",
                    },
                    {
                        language: "nl",
                        title: "Help",
                    },
                    {
                        language: "es",
                        title: "Ayuda",
                    },
                    {
                        language: "no",
                        title: "Hjelp",
                    },
                    {
                        language: "da",
                        title: "Hjælp",
                    },
                    {
                        language: "tr",
                        title: "Faydalı Bilgiler",
                    },
                    {
                        language: "cs",
                        title: "Je dobré vědět",
                    },
                ],
                id: "hilfe",
                url: null,
                options: [
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Anwendung & Zubereitung",
                                url: "https://www.lavita.de/service/anwendung-zubereitung",
                            },
                            {
                                language: "en",
                                title: "Preparation & Usage",
                                url: "https://www.lavita.de/service/how-to-use",
                            },
                            {
                                language: "sv",
                                title: "Beredning och användning",
                                url: "https://www.lavita.de/service/hur-man-anvander",
                            },
                            {
                                language: "fr",
                                title: "Préparation et utilisation",
                                url: "https://www.lavita.de/service/comment-lutiliser",
                            },
                            {
                                language: "it",
                                title: "Preparazione e uso",
                                url: "https://www.lavita.de/servizio/come-si-usa",
                            },
                            {
                                language: "nl",
                                title: "Bereiding en gebruik",
                                url: "https://www.lavita.de/service/hoe-te-gebruiken",
                            },
                            {
                                language: "es",
                                title: "Preparación y uso",
                                url: "https://www.lavita.de/servicio/como-utilizarlo",
                            },
                            {
                                language: "no",
                                title: "Tilberedning og bruk",
                                url: "https://www.lavita.de/service/hvordan-bruke",
                            },
                            {
                                language: "da",
                                title: "Forberedelse og brug",
                                url: "https://www.lavita.de/service/sadan-bruger-du",
                            },
                            {
                                language: "tr",
                                title: "Kullanım",
                                url: "https://www.lavita.de/mikro-besin-konsantresi/kullanim",
                            },
                            {
                                language: "cs",
                                title: "Příprava a užívání",
                                url: "https://www.lavita.de/informace/uzivani",
                            },
                        ],
                        id: "anwendung_zubereitung",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Häufige Fragen",
                                url: "https://www.lavita.de/service/faq",
                            },
                            {
                                language: "en",
                                title: "FAQ",
                                url: "https://www.lavita.de/service/faq",
                            },
                            {
                                language: "sv",
                                title: "FAQ",
                                url: "https://www.lavita.de/service/faq",
                            },
                            {
                                language: "fr",
                                title: "FAQ",
                                url: "https://www.lavita.de/service/faq",
                            },
                            {
                                language: "it",
                                title: "FAQ",
                                url: "https://www.lavita.de/servizio/faq",
                            },
                            {
                                language: "nl",
                                title: "FAQ",
                                url: "https://www.lavita.de/service/faq",
                            },
                            {
                                language: "es",
                                title: "FAQ",
                                url: "https://www.lavita.de/servicio/faq",
                            },
                            {
                                language: "no",
                                title: "FAQ",
                                url: "https://www.lavita.de/service/faq",
                            },
                            {
                                language: "da",
                                title: "FAQ",
                                url: "https://www.lavita.de/service/faq",
                            },
                            {
                                language: "tr",
                                title: "Sık Sorulan Sorular",
                                url: "https://www.lavita.de/mikro-besin-konsantresi/sik-sorulan-sorular",
                            },
                            {
                                language: "cs",
                                title: "Časté otázky",
                                url: "https://www.lavita.de/informace/faq",
                            },
                        ],
                        id: "haeufige_fragen",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "tr",
                                title: "Blog",
                                url: "https://blogtr.lavita.com",
                            },
                            {
                                language: "cs",
                                title: "Blog",
                                url: "https://blogcz.lavita.com",
                            },
                        ],
                        id: "blog",
                        options: [],
                    },
                ],
            },
            {
                translations: [
                    {
                        language: "de",
                        title: "LaVita Welt",
                    },
                ],
                id: "lavita_welt",
                url: null,
                options: [
                    {
                        translations: [
                            {
                                language: "de",
                                title: "LaVita App",
                                hiddenRegion: ["LI", "LU"],
                                url: "https://www.lavita.de/service/app",
                            },
                        ],
                        id: "lavita_app",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "LaVita Magazin",
                                url: "https://www.lavita-magazin.de",
                            },
                        ],
                        id: "lavita_magazin",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Kinderbücher",
                                hiddenRegion: [
                                    "CH",
                                    "BE",
                                    "CZ",
                                    "CY",
                                    "DK",
                                    "EE",
                                    "ES",
                                    "FR",
                                    "HR",
                                    "IE",
                                    "IS",
                                    "IT",
                                    "LV",
                                    "LI",
                                    "LT",
                                    "LU",
                                    "HU",
                                    "MT",
                                    "NL",
                                    "NO",
                                    "PL",
                                    "PT",
                                    "RO",
                                    "SI",
                                    "SK",
                                    "FI",
                                    "SE",
                                    "TR",
                                    "GR",
                                    "BG",
                                ],
                                url: "https://www.lavita.de/kinderbuch",
                            },
                        ],
                        id: "kinderbuecher",
                        options: [],
                    },
                ],
            },
            {
                translations: [
                    {
                        language: "de",
                        title: "Gutscheine",
                        hiddenRegion: [
                            "BE",
                            "CZ",
                            "CY",
                            "DK",
                            "EE",
                            "ES",
                            "FR",
                            "HR",
                            "IE",
                            "IS",
                            "IT",
                            "LV",
                            "LI",
                            "LT",
                            "LU",
                            "HU",
                            "MT",
                            "NL",
                            "NO",
                            "PL",
                            "PT",
                            "RO",
                            "SI",
                            "SK",
                            "FI",
                            "SE",
                            "TR",
                            "GR",
                            "BG",
                        ],
                    },
                ],
                id: "gutscheine",
                url: null,
                options: [
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Gutschein verschenken",
                                hiddenRegion: ["CH", "LI", "LU"],
                                url: "https://www.lavita.de/lavita-verschenken",
                            },
                        ],
                        id: "gutschein_verschenken",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Gutschein einlösen",
                                hiddenRegion: ["LI", "LU"],
                                url: "https://www.lavita.de/gutschein",
                            },
                        ],
                        id: "gutschein_einloesen",
                        options: [],
                    },
                ],
            },
            {
                translations: [
                    {
                        language: "de",
                        title: "Kontakt",
                    },
                    {
                        language: "en",
                        title: "Contact",
                    },
                    {
                        language: "sv",
                        title: "Kontakt",
                    },
                    {
                        language: "fr",
                        title: "Contact",
                    },
                    {
                        language: "it",
                        title: "Contatto",
                    },
                    {
                        language: "nl",
                        title: "Contact",
                    },
                    {
                        language: "es",
                        title: "Contacto",
                    },
                    {
                        language: "no",
                        title: "Kontakt",
                    },
                    {
                        language: "da",
                        title: "Kontakt",
                    },
                    {
                        language: "tr",
                        title: "İletişim",
                    },
                    {
                        language: "cs",
                        title: "Kontakt",
                    },
                ],
                id: "kontakt",
                url: null,
                options: [
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Telefonischer Kundenservice",
                                hiddenRegion: ["LI", "LU"],
                                url: "https://www.lavita.de/service/beratung",
                            },
                            {
                                language: "tr",
                                title: "Kişisel Danışmanlık",
                                url: "https://www.lavita.de/mikro-besin-konsantresi/kisisel-danismanlik",
                            },
                        ],
                        id: "telefonischer_kundenservice",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Kontaktformular",
                                url: "https://www.lavita.de/kontakt",
                            },
                            {
                                language: "en",
                                title: "Contact",
                                url: "https://www.lavita.de/contact",
                            },
                            {
                                language: "sv",
                                title: "Kontakt",
                                url: "https://www.lavita.de/kontakt",
                            },
                            {
                                language: "fr",
                                title: "Contact",
                                url: "https://www.lavita.de/contact",
                            },
                            {
                                language: "it",
                                title: "Contatto",
                                url: "https://www.lavita.de/contatti",
                            },
                            {
                                language: "nl",
                                title: "Contact",
                                url: "https://www.lavita.de/contact",
                            },
                            {
                                language: "es",
                                title: "Contacto",
                                url: "https://www.lavita.de/contacto",
                            },
                            {
                                language: "no",
                                title: "Kontakt",
                                url: "https://www.lavita.de/kontakt",
                            },
                            {
                                language: "da",
                                title: "Kontakt",
                                url: "https://www.lavita.de/kontakt",
                            },
                            {
                                language: "tr",
                                title: "İletişim",
                                url: "https://www.lavita.de/iletisim",
                            },
                            {
                                language: "cs",
                                title: "Kontakt",
                                url: "https://www.lavita.de/kontakt",
                            },
                        ],
                        id: "kontaktformular",
                        options: [],
                    },
                ],
            },
        ],
    },
    {
        translations: [
            {
                language: "de",
                title: "Unternehmen",
            },
            {
                language: "en",
                title: "Company",
            },
            {
                language: "sv",
                title: "Företag",
            },
            {
                language: "fr",
                title: "Entreprise",
            },
            {
                language: "it",
                title: "Azienda",
            },
            {
                language: "nl",
                title: "Bedrijf",
            },
            {
                language: "es",
                title: "Empresa",
            },
            {
                language: "no",
                title: "Selskap",
            },
            {
                language: "da",
                title: "Virksomhed",
            },
            {
                language: "cs",
                title: "O nás",
            },
        ],
        id: "unternehmen",
        url: null,
        options: [
            {
                translations: [
                    {
                        language: "de",
                        title: "Familienunternehmen",
                        url: "https://www.lavita.de/familienunternehmen",
                    },
                    {
                        language: "en",
                        title: "Family Business",
                        url: "https://www.lavita.de/family-business",
                    },
                    {
                        language: "sv",
                        title: "Familjeföretag",
                        url: "https://www.lavita.de/familjeforetag",
                    },
                    {
                        language: "fr",
                        title: "Entreprise familiale",
                        url: "https://www.lavita.de/entreprise-familiale",
                    },
                    {
                        language: "it",
                        title: "Azienda familiare",
                        url: "https://www.lavita.de/azienda-familiare",
                    },
                    {
                        language: "nl",
                        title: "Familiebedrijven",
                        url: "https://www.lavita.de/familiebedrijf",
                    },
                    {
                        language: "es",
                        title: "Empresa familiar",
                        url: "https://www.lavita.de/empresa-familiar",
                    },
                    {
                        language: "no",
                        title: "Familiebedrifter",
                        url: "https://www.lavita.de/familiebedrift",
                    },
                    {
                        language: "da",
                        title: "Familievirksomheder",
                        url: "https://www.lavita.de/familievirksomhed",
                    },
                    {
                        language: "cs",
                        title: "Rodinná firma",
                        url: "https://www.lavita.de/rodinna-firma",
                    },
                ],
                id: "familienunternehmen",
                options: [],
            },
            {
                translations: [
                    {
                        language: "de",
                        title: "Arbeiten bei LaVita",
                        hiddenRegion: ["LI", "LU"],
                        url: "https://www.lavita.de/karriere",
                    },
                ],
                id: "arbeiten_bei_lavita",
                options: [],
            },
        ],
    },
    {
        translations: [
            {
                language: "de",
                hiddenRegion: ["LI", "LU"],
                title: "Gesundheitswelt",
            },
        ],
        id: "gesundheitswelt",
        url: null,
        options: [
            {
                translations: [
                    {
                        language: "de",
                        title: "Alles über",
                    },
                ],
                id: "alles_über",
                url: null,
                options: [
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Immunsystem",
                                url: "https://www.lavita.de/gesundheitswelt/immunsystem",
                            },
                        ],
                        id: "immunsystem",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Müdigkeit",
                                url: "https://www.lavita.de/gesundheitswelt/muedigkeit",
                            },
                        ],
                        id: "muedigkeit",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Schwangerschaft",
                                url: "https://www.lavita.de/gesundheitswelt/schwangerschaft",
                            },
                        ],
                        id: "schwangerschaft",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Stress",
                                url: "https://www.lavita.de/wissen-gesundheit/innere-ruhe",
                            },
                        ],
                        id: "stress",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Stoffwechsel",
                                url: "https://www.lavita.de/wissen-gesundheit/stoffwechsel",
                            },
                        ],
                        id: "stoffwechsel",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Haut, Haare, Nägel",
                                url: "https://www.lavita.de/wissen-gesundheit/haut",
                            },
                        ],
                        id: "haut_haare_naegel",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Schilddrüse",
                                url: "https://www.lavita.de/wissen-gesundheit/schilddruese",
                            },
                        ],
                        id: "schilddrüse",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Säure-Basen-Haushalt",
                                url: "https://www.lavita.de/wissen-gesundheit/saeure-basen-haushalt",
                            },
                        ],
                        id: "saeure_basen_haushalt",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Abnehmen",
                                url: "https://www.lavita.de/wissen-gesundheit/abnehmen",
                            },
                        ],
                        id: "abnehmen",
                        options: [],
                    },
                ],
            },
            {
                translations: [
                    {
                        language: "de",
                        title: "LaVita Angebote",
                    },
                ],
                id: "lavita_angebote",
                url: null,
                options: [
                    {
                        translations: [
                            {
                                language: "de",
                                title: "LaVita App",
                                url: "https://www.lavita.de/service/app",
                            },
                        ],
                        id: "lavita_app",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "LaVita Online-Magazin",
                                url: "https://www.lavita-magazin.de",
                            },
                        ],
                        id: "lavita_online_magazin",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Kinderbücher",
                                hiddenRegion: [
                                    "CH",
                                    "BE",
                                    "CZ",
                                    "CY",
                                    "DK",
                                    "EE",
                                    "ES",
                                    "FR",
                                    "HR",
                                    "IE",
                                    "IS",
                                    "IT",
                                    "LV",
                                    "LI",
                                    "LT",
                                    "LU",
                                    "HU",
                                    "MT",
                                    "NL",
                                    "NO",
                                    "PL",
                                    "PT",
                                    "RO",
                                    "SI",
                                    "SK",
                                    "FI",
                                    "SE",
                                    "TR",
                                    "GR",
                                    "BG",
                                ],
                                url: "https://www.lavita.de/kinderbuch",
                            },
                        ],
                        id: "kinderbuecher",
                        options: [],
                    },
                ],
            },
        ],
    },
    {
        translations: [
            {
                language: "de",
                title: "Shop",
            },
            {
                language: "en",
                title: "Shop",
            },
            {
                language: "sv",
                title: "Shop",
            },
            {
                language: "fr",
                title: "Boutique",
            },
            {
                language: "it",
                title: "Shop",
            },
            {
                language: "nl",
                title: "Shop",
            },
            {
                language: "es",
                title: "Shop",
            },
            {
                language: "no",
                title: "Shop",
            },
            {
                language: "da",
                title: "Shop",
            },
            {
                language: "cs",
                title: "E-shop",
            },
        ],
        id: "shop",
        url: null,
        options: [
            {
                translations: [
                    {
                        language: "de",
                        title: "LaVita bestellen",
                    },
                    {
                        language: "en",
                        title: "Buy LaVita",
                    },
                    {
                        language: "sv",
                        title: "Köp LaVita",
                    },
                    {
                        language: "fr",
                        title: "Acheter LaVita",
                    },
                    {
                        language: "it",
                        title: "Acquista LaVita",
                    },
                    {
                        language: "nl",
                        title: "LaVita kopen",
                    },
                    {
                        language: "es",
                        title: "Comprar LaVita",
                    },
                    {
                        language: "no",
                        title: "Kjøp LaVita",
                    },
                    {
                        language: "da",
                        title: "Køb LaVita",
                    },
                    {
                        language: "cs",
                        title: "Koupit LaVitu",
                    },
                ],
                id: "lavita_kaufen",
                url: null,
                options: [
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Einzelflasche",
                                hiddenRegion: ["CH"],
                                url: "https://shop.lavita.de#LV500",
                            },
                            {
                                language: "en",
                                title: "Single bottle",
                                url: "https://shop.lavita.de#LV500",
                            },
                            {
                                language: "sv",
                                title: "En Flaska",
                                url: "https://shop.lavita.de#LV500",
                            },
                            {
                                language: "fr",
                                title: "Bouteille unique",
                                url: "https://shop.lavita.de#LV500",
                            },
                            {
                                language: "it",
                                title: "Bottiglia singola",
                                url: "https://shop.lavita.de#LV500",
                            },
                            {
                                language: "nl",
                                title: "Enkele fles",
                                url: "https://shop.lavita.de#LV500",
                            },
                            {
                                language: "es",
                                title: "Botella individual",
                                url: "https://shop.lavita.de#LV500",
                            },
                            {
                                language: "no",
                                title: "Enkeltflaske",
                                url: "https://shop.lavita.de#LV500",
                            },
                            {
                                language: "da",
                                title: "Enkelt flaske",
                                url: "https://shop.lavita.de#LV500",
                            },
                            {
                                language: "cs",
                                title: "Jednotlivé láhve LaVity",
                                url: "https://shopcz.lavita.com/products/lahev-500ml",
                            },
                        ],
                        id: "einzelflasche_de",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Startpaket",
                                url: "https://shop.lavita.de#LV2START",
                                hiddenRegion: ["CH"],
                            },
                            {
                                language: "en",
                                title: "Starter package",
                                url: "https://shop.lavita.de#LV2START",
                            },
                            {
                                language: "sv",
                                title: "Startpaket",
                                url: "https://shop.lavita.de#LV2START",
                            },
                            {
                                language: "fr",
                                title: "Paquet de départ",
                                url: "https://shop.lavita.de#LV2START",
                            },
                            {
                                language: "it",
                                title: "Pacchetto iniziale",
                                url: "https://shop.lavita.de#LV2START",
                            },
                            {
                                language: "nl",
                                title: "Starterspakket",
                                url: "https://shop.lavita.de#LV2START",
                            },
                            {
                                language: "es",
                                title: "Paquete de inicio",
                                url: "https://shop.lavita.de#LV2START",
                            },
                            {
                                language: "no",
                                title: "Startpakke",
                                url: "https://shop.lavita.de#LV2START",
                            },
                            {
                                language: "da",
                                title: "Startpakke",
                                url: "https://shop.lavita.de#LV2START",
                            },
                        ],
                        id: "startpaket_de",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Abo mit Preisvorteil",
                                hiddenRegion: [
                                    "CH",
                                    "BE",
                                    "CZ",
                                    "CY",
                                    "DK",
                                    "EE",
                                    "ES",
                                    "FR",
                                    "HR",
                                    "IE",
                                    "IS",
                                    "IT",
                                    "LV",
                                    "LI",
                                    "LT",
                                    "LU",
                                    "HU",
                                    "MT",
                                    "NL",
                                    "NO",
                                    "PL",
                                    "PT",
                                    "RO",
                                    "SI",
                                    "SK",
                                    "FI",
                                    "SE",
                                    "TR",
                                    "GR",
                                    "BG",
                                ],
                                url: "https://shop.lavita.de#SLP500",
                            },
                            {
                                language: "en",
                                title: "Subscription",
                                hiddenRegion: [
                                    "CH",
                                    "BE",
                                    "CZ",
                                    "CY",
                                    "DK",
                                    "EE",
                                    "ES",
                                    "FR",
                                    "HR",
                                    "IE",
                                    "IS",
                                    "IT",
                                    "LV",
                                    "LI",
                                    "LT",
                                    "LU",
                                    "HU",
                                    "MT",
                                    "NL",
                                    "NO",
                                    "PL",
                                    "PT",
                                    "RO",
                                    "SI",
                                    "SK",
                                    "FI",
                                    "SE",
                                    "TR",
                                    "GR",
                                    "BG",
                                ],
                                url: "https://shop.lavita.de#SLP500",
                            },
                        ],
                        id: "abo_mit_preisvorteil_de",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Einzelflasche",
                                hiddenRegion: [
                                    "DE",
                                    "AT",
                                    "BE",
                                    "CZ",
                                    "CY",
                                    "DK",
                                    "EE",
                                    "ES",
                                    "FR",
                                    "HR",
                                    "IE",
                                    "IS",
                                    "IT",
                                    "LV",
                                    "LI",
                                    "LT",
                                    "LU",
                                    "HU",
                                    "MT",
                                    "NL",
                                    "NO",
                                    "PL",
                                    "PT",
                                    "RO",
                                    "SI",
                                    "SK",
                                    "FI",
                                    "SE",
                                    "TR",
                                    "GR",
                                    "BG",
                                ],
                                url: "https://shop.lavita-swiss.ch#LV500",
                            },
                        ],
                        id: "einzelflasche_ch",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Startpaket",
                                hiddenRegion: [
                                    "DE",
                                    "AT",
                                    "BE",
                                    "CZ",
                                    "CY",
                                    "DK",
                                    "EE",
                                    "ES",
                                    "FR",
                                    "HR",
                                    "IE",
                                    "IS",
                                    "IT",
                                    "LV",
                                    "LI",
                                    "LT",
                                    "LU",
                                    "HU",
                                    "MT",
                                    "NL",
                                    "NO",
                                    "PL",
                                    "PT",
                                    "RO",
                                    "SI",
                                    "SK",
                                    "FI",
                                    "SE",
                                    "TR",
                                    "GR",
                                    "BG",
                                ],
                                url: "https://shop.lavita-swiss.ch#LV2START",
                            },
                        ],
                        id: "startpaket_ch",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Abo mit Preisvorteil",
                                hiddenRegion: [
                                    "DE",
                                    "AT",
                                    "BE",
                                    "CZ",
                                    "CY",
                                    "DK",
                                    "EE",
                                    "ES",
                                    "FR",
                                    "HR",
                                    "IE",
                                    "IS",
                                    "IT",
                                    "LV",
                                    "LI",
                                    "LT",
                                    "LU",
                                    "HU",
                                    "MT",
                                    "NL",
                                    "NO",
                                    "PL",
                                    "PT",
                                    "RO",
                                    "SI",
                                    "SK",
                                    "FI",
                                    "SE",
                                    "TR",
                                    "GR",
                                    "BG",
                                ],
                                url: "https://shop.lavita-swiss.ch#SLP500",
                            },
                        ],
                        id: "abo_mit_preisvorteil_ch",
                        options: [],
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "LaVita verschenken",
                                url: "https://www.lavita.de/lavita-verschenken",
                                hiddenRegion: [
                                    "CH",
                                    "BE",
                                    "CZ",
                                    "CY",
                                    "DK",
                                    "EE",
                                    "ES",
                                    "FR",
                                    "HR",
                                    "IE",
                                    "IS",
                                    "IT",
                                    "LV",
                                    "LI",
                                    "LT",
                                    "LU",
                                    "HU",
                                    "MT",
                                    "NL",
                                    "NO",
                                    "PL",
                                    "PT",
                                    "RO",
                                    "SI",
                                    "SK",
                                    "FI",
                                    "SE",
                                    "TR",
                                    "GR",
                                    "BG",
                                ],
                            },
                        ],
                        id: "lavita_verschenken",
                    },
                    {
                        translations: [
                            {
                                language: "de",
                                title: "Gutschein einlösen",
                                url: "https://www.lavita.de/gutschein",
                                hiddenRegion: [
                                    "BE",
                                    "CZ",
                                    "CY",
                                    "DK",
                                    "EE",
                                    "ES",
                                    "FR",
                                    "HR",
                                    "IE",
                                    "IS",
                                    "IT",
                                    "LV",
                                    "LI",
                                    "LT",
                                    "LU",
                                    "HU",
                                    "MT",
                                    "NL",
                                    "NO",
                                    "PL",
                                    "PT",
                                    "RO",
                                    "SI",
                                    "SK",
                                    "FI",
                                    "SE",
                                    "TR",
                                    "GR",
                                    "BG",
                                ],
                            },
                        ],
                        id: "gutschein_einloesen",
                    },
                    {
                        translations: [
                            {
                                language: "cs",
                                title: "Hromadná objednávka 7+1",
                                url: "https://shopcz.lavita.com/products/7plus1",
                            },
                        ],
                        id: "7_plus_1",
                    },
                ],
            },
        ],
    },
]

export { menu }
