import React from "react"
import { RenderLeafProps } from "slate-react"

const SuperSized = ({ children, attributes }: RenderLeafProps) => {
    return (
        <span
            {...attributes}
            className="text-6xl md:text-10xl"
        >
            {children}
        </span>
    )
}

export default SuperSized