import type { Product } from "@lavita-io/international-sdk"
import { formatPrice } from "../shared/utils/format"

const price = (language: string, product?: Product) => {
    if (!product) return

    return product.sku === "LV2START"
        ? formatPrice(product.price.amount / (product.splitted?.parts || 1), product.price.currency, language)
        : formatPrice(product.price.amount, product.price.currency, language)
}

export { price }
