import { Icons } from "../../../types/icons"
import React from "react"
import classNames from "classnames"

interface VideoButtonProps {
    playing: boolean
    progress: number
}

const VideoButton = ({ playing, progress }: VideoButtonProps) => {
    return (
        <div className="relative z-2 flex h-10 w-10 items-center justify-center md:h-[50px] md:w-[50px]">
            <svg className="absolute h-10 w-10 rotate-90 md:h-[50px] md:w-[50px]" viewBox="0 0 50 50">
                <circle cx="25" cy="25" r="24" strokeWidth="2" className="fill-none stroke-white opacity-50" />
                <circle
                    cx="25"
                    cy="25"
                    r="24"
                    strokeWidth="2"
                    style={{
                        strokeDashoffset: 2 * Math.PI * 25 * (1 - progress),
                        strokeDasharray: 2 * Math.PI * 25,
                    }}
                    strokeLinecap="round"
                    className="fill-none stroke-white"
                />
            </svg>
            <i
                className={classNames("lavita-icon text-[17px] leading-none text-white md:text-[22px]", {
                    "relative left-[1px] md:left-[2px]": !playing,
                })}
            >
                {playing ? Icons.pause : Icons.play}
            </i>
        </div>
    )
}

interface MuteButtonProps {
    muted: boolean
}

const MuteButton = ({ muted }: MuteButtonProps) => {
    return (
        <div className="z-2 flex h-10 w-10 items-center justify-center md:h-[50px] md:w-[50px]">
            <i className="lavita-icon text-[17px] leading-none text-white md:text-[22px]">
                {muted ? Icons.mute : Icons.unmute}
            </i>
        </div>
    )
}

export { VideoButton, MuteButton }
