import React from "react"
import classNames from "classnames"

export enum CircleColor {
    "Green" = "#bfc5a3",
    "Orange" = "#d39672",
}

interface CircleProps {
    color?: CircleColor
}

const Circle = ({ color = CircleColor.Orange }: CircleProps) => {
    return (
        <div className="aspect-h-square aspect-w-square">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 770.301 795.918">
                <g transform="matrix(0.259, -0.966, 0.966, 0.259, -2222.299, 1036.723)">
                    <path
                        d="M782.241-431.738c-73.976-110.588-39.013-156.879-233.19-156.7s-267.58,37.771-312.347,86.5-84.882,101.184-39.276,205.392S129.53-52.346,340.627-2.748s399.925-21.821,427.11-104.543S856.217-321.15,782.241-431.738Z"
                        transform="translate(782.688 2636.889)"
                        fill="none"
                        className={classNames({
                            "stroke-border-copperfield": color === CircleColor.Orange,
                            "stroke-swamp-green-70": color === CircleColor.Green,
                        })}
                        strokeWidth="1"
                    />
                    <path
                        d="M782.241-431.738c-73.976-110.588-39.013-156.879-233.19-156.7s-267.58,37.771-312.347,86.5-84.882,101.184-39.276,205.392S129.53-52.346,340.627-2.748s399.925-21.821,427.11-104.543S856.217-321.15,782.241-431.738Z"
                        transform="translate(801.58 2650.114)"
                        fill="none"
                        className={classNames({
                            "stroke-border-copperfield": color === CircleColor.Orange,
                            "stroke-swamp-green-70": color === CircleColor.Green,
                        })}
                        strokeWidth="1"
                    />
                    <path
                        d="M782.241-431.738c-73.976-110.588-39.013-156.879-233.19-156.7s-267.58,37.771-312.347,86.5-84.882,101.184-39.276,205.392S129.53-52.346,340.627-2.748s399.925-21.821,427.11-104.543S856.217-321.15,782.241-431.738Z"
                        transform="translate(801.58 2627.443)"
                        fill="none"
                        className={classNames({
                            "stroke-border-copperfield": color === CircleColor.Orange,
                            "stroke-swamp-green-70": color === CircleColor.Green,
                        })}
                        strokeWidth="1"
                    />
                </g>
            </svg>
        </div>
    )
}

export default Circle
