import React from "react"
import Stars from "./stars"
import classNames from "classnames"
import { useLocalesState } from "../../../shared/context/locales"
import { useTranslation } from "react-i18next"

export type BadgeSize = "sm" | "md"

interface BadgeProps {
    center?: boolean
    size?: BadgeSize
}

const Badge = ({ center, size = "md" }: BadgeProps) => {
    const { t } = useTranslation("translations")
    const { language } = useLocalesState()

    const AVERAGE_RATING: number = 4.9
    const REVIEWS: number = 40000

    return size === "sm" ? (
        <div className="flex flex-col items-center gap-3">
            <div className="flex items-center gap-1.5">
                <Stars rating={AVERAGE_RATING} size="md" />
                <strong className="text-sm font-semibold md:text-base">
                    {AVERAGE_RATING.toLocaleString(language)}
                </strong>
                <div className="text-xs md:text-sm">
                    {t(
                        AVERAGE_RATING < 1.5
                            ? "truststripe_very_poor"
                            : AVERAGE_RATING < 2.5
                            ? "truststripe_poor"
                            : AVERAGE_RATING < 3.5
                            ? "truststripe_fair"
                            : AVERAGE_RATING < 4.5
                            ? "truststripe_good"
                            : "truststripe_excellent"
                    )}
                </div>
            </div>
            <div className="flex items-center justify-center gap-1.5">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 28 28"
                    className="h-3.5 w-3.5 shrink-0"
                >
                    <defs>
                        <linearGradient
                            id="trust-icon-linear-gradient"
                            x1="0.5"
                            x2="0.5"
                            y2="1"
                            gradientUnits="objectBoundingBox"
                        >
                            <stop offset="0" stopColor="#4c97d5" />
                            <stop offset="1" stopColor="#0d497a" />
                        </linearGradient>
                    </defs>
                    <g transform="translate(-1276 -1326)">
                        <g transform="translate(1276 1326)">
                            <g transform="translate(0 0)">
                                <path
                                    d="M25.544,12.48l1.138-4.247a1.9,1.9,0,0,0-1.34-2.33h-.006L21.088,4.763,18.887.95a1.9,1.9,0,0,0-2.6-.7l-3.811,2.2L8.232,1.317A1.9,1.9,0,0,0,5.9,2.657v.007L4.762,6.911.95,9.112a1.9,1.9,0,0,0-.7,2.6l2.2,3.811L1.317,19.768a1.9,1.9,0,0,0,1.34,2.33l.005,0L6.91,23.237l2.2,3.812a1.9,1.9,0,0,0,2.6.7l3.812-2.2,4.247,1.138a1.9,1.9,0,0,0,2.33-1.339l0-.007,1.138-4.247,3.812-2.2a1.9,1.9,0,0,0,.7-2.6Z"
                                    transform="translate(0 0)"
                                    fill="url(#trust-icon-linear-gradient)"
                                />
                            </g>
                        </g>
                        <g transform="translate(1284 1335.528)">
                            <g transform="translate(0)">
                                <path
                                    d="M3.446,8.63H3.44L.349,5.539a1.2,1.2,0,0,1,0-1.693A1.186,1.186,0,0,1,1.2,3.5H1.2a1.172,1.172,0,0,1,.838.346L4.254,6.054l5.7-5.7A1.2,1.2,0,0,1,11.594.3h0l.052.052a1.2,1.2,0,0,1,0,1.693L5.1,8.592a1.2,1.2,0,0,1-1.654.037Z"
                                    fill="#fff"
                                />
                            </g>
                        </g>
                    </g>
                </svg>
                <div className="text-xs md:text-sm">
                    {t("truststripe_reviews_over")}{" "}
                    <strong className="font-semibold">
                        {t("truststripe_reviews", {
                            countString: REVIEWS.toLocaleString(language),
                        })}
                    </strong>
                </div>
            </div>
        </div>
    ) : (
        <div className="flex justify-center gap-2.5 md:gap-3">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 28 28"
                className="h-5 w-5 shrink-0 lg:h-7 lg:w-7"
            >
                <defs>
                    <linearGradient
                        id="trust-icon-linear-gradient"
                        x1="0.5"
                        x2="0.5"
                        y2="1"
                        gradientUnits="objectBoundingBox"
                    >
                        <stop offset="0" stopColor="#4c97d5" />
                        <stop offset="1" stopColor="#0d497a" />
                    </linearGradient>
                </defs>
                <g transform="translate(-1276 -1326)">
                    <g transform="translate(1276 1326)">
                        <g transform="translate(0 0)">
                            <path
                                d="M25.544,12.48l1.138-4.247a1.9,1.9,0,0,0-1.34-2.33h-.006L21.088,4.763,18.887.95a1.9,1.9,0,0,0-2.6-.7l-3.811,2.2L8.232,1.317A1.9,1.9,0,0,0,5.9,2.657v.007L4.762,6.911.95,9.112a1.9,1.9,0,0,0-.7,2.6l2.2,3.811L1.317,19.768a1.9,1.9,0,0,0,1.34,2.33l.005,0L6.91,23.237l2.2,3.812a1.9,1.9,0,0,0,2.6.7l3.812-2.2,4.247,1.138a1.9,1.9,0,0,0,2.33-1.339l0-.007,1.138-4.247,3.812-2.2a1.9,1.9,0,0,0,.7-2.6Z"
                                transform="translate(0 0)"
                                fill="url(#trust-icon-linear-gradient)"
                            />
                        </g>
                    </g>
                    <g transform="translate(1284 1335.528)">
                        <g transform="translate(0)">
                            <path
                                d="M3.446,8.63H3.44L.349,5.539a1.2,1.2,0,0,1,0-1.693A1.186,1.186,0,0,1,1.2,3.5H1.2a1.172,1.172,0,0,1,.838.346L4.254,6.054l5.7-5.7A1.2,1.2,0,0,1,11.594.3h0l.052.052a1.2,1.2,0,0,1,0,1.693L5.1,8.592a1.2,1.2,0,0,1-1.654.037Z"
                                fill="#fff"
                            />
                        </g>
                    </g>
                </g>
            </svg>
            <div
                className={classNames({
                    "flex flex-wrap items-center gap-x-1.5 gap-y-3 md:gap-x-3 md:gap-y-3.5 lg:gap-x-5 lg:gap-y-4":
                        center,
                })}
            >
                <div
                    className={classNames("text-sm sm:text-xs md:text-sm lg:text-base", {
                        "mb-3 md:mb-3.5 lg:mb-4": !center,
                    })}
                >
                    {t("truststripe_reviews_over")}{" "}
                    <strong className="text-base font-semibold sm:text-sm md:text-base lg:text-md">
                        {t("truststripe_reviews", {
                            countString: REVIEWS.toLocaleString(language),
                        })}
                    </strong>
                </div>
                <div className="flex items-center gap-1.5 md:gap-3 lg:gap-5">
                    <Stars rating={AVERAGE_RATING} size="lg" />
                    <div className="flex items-center gap-1.5 lg:gap-3">
                        <strong className="text-base font-semibold sm:text-sm md:text-md lg:text-lg">
                            {AVERAGE_RATING.toLocaleString(language)}
                        </strong>
                        <div className="text-sm sm:text-xs md:text-sm lg:text-base">
                            {t(
                                AVERAGE_RATING < 1.5
                                    ? "truststripe_very_poor"
                                    : AVERAGE_RATING < 2.5
                                    ? "truststripe_poor"
                                    : AVERAGE_RATING < 3.5
                                    ? "truststripe_fair"
                                    : AVERAGE_RATING < 4.5
                                    ? "truststripe_good"
                                    : "truststripe_excellent"
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Badge
