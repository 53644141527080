import { useLayoutEffect, useState } from "react"

const useScrollPosition = id => {
    const [scrollTop, setScrollTop] = useState(0)
    const [scrollDirection, setScrollDirection] = useState("up")

    useLayoutEffect(() => {
        const scrollElement = id ? document.getElementById(id) : document.documentElement
        const listener = id ? document.getElementById(id) : window

        let lastScrollTop = scrollElement.scrollTop

        const handleWindowScroll = () => {
            setScrollTop(scrollElement.scrollTop)
            setScrollDirection(scrollElement.scrollTop > lastScrollTop ? "down" : "up")
            lastScrollTop = scrollElement.scrollTop
        }
        handleWindowScroll()
        listener.addEventListener("scroll", handleWindowScroll)
        return () => listener.removeEventListener("scroll", handleWindowScroll)
    }, [id])

    return { scrollTop, scrollDirection }
}

export { useScrollPosition }
