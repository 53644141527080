import Badge from "../../components/trust/badge"
import React from "react"
import TrustedShopBadge from "../../components/trust/trusted-shop-badge"
import { types } from "react-bricks/frontend"
import { useLocalesState } from "../../../shared/context/locales"

const Trust: types.Brick = () => {
    const { language, region } = useLocalesState()

    return (
        <div className="justify-center sm:flex">
            <div className="rounded-base border border-background-tide px-7 py-5">
                {language === "de" && region === "DE" ? <TrustedShopBadge size="sm" /> : <Badge size="sm" />}
            </div>
        </div>
    )
}

Trust.schema = {
    name: "Trust",
    label: "Trust Badge",
    hideFromAddMenu: true,
}

export default Trust
