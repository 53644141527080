import {
    ContainerVariant,
    Grid,
    GridColumns,
    GridGap,
    gridColumnTwoThreeOptions,
    gridGapOptions,
} from "../components/layout"
import { Repeater, types } from "react-bricks/frontend"

import { ConditionalRenderer } from "../components/util"
import { IconTextProps } from "./IconText"
import React from "react"

export interface IconTextGridProps {
    columns: GridColumns
    gapX: GridGap
    gapY: GridGap
    visible?: number
}

const IconTextGrid: types.Brick<IconTextGridProps> = ({ columns, gapX, gapY, visible }) => {
    return (
        <Grid columns={columns} gapY={gapY} gapX={gapX}>
            <Repeater
                propName="repeater"
                itemProps={
                    {
                        variant: columns === GridColumns.Three ? ContainerVariant.Small : ContainerVariant.Base,
                    } as IconTextProps
                }
                renderItemWrapper={(item, index) => (
                    <ConditionalRenderer condition={!visible || index < visible} key={index}>{item}</ConditionalRenderer>
                )}
            />
        </Grid>
    )
}

IconTextGrid.schema = {
    name: "IconTextGrid",
    label: "Icon Text Grid",
    hideFromAddMenu: true,
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "IconText",
                    label: "Icon Text",
                    min: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "columns",
            label: "Columns",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: gridColumnTwoThreeOptions,
                display: types.OptionsDisplay.Select,
            },
        },
        {
            name: "gapX",
            label: "Gap X",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: gridGapOptions,
                display: types.OptionsDisplay.Select,
            },
        },
        {
            name: "gapY",
            label: "Gap Y",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: gridGapOptions,
                display: types.OptionsDisplay.Select,
            },
        },
    ],
    getDefaultProps: (): IconTextGridProps => ({
        columns: GridColumns.Three,
        gapX: GridGap.Base,
        gapY: GridGap.Base,
    }),
}

export default IconTextGrid
