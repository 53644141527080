import Typography, { TypographyElement } from "../Typography"

import { Color } from "../components/typography"
import React from "react"
import { types } from "react-bricks/frontend"

interface SmallprintItemProps {
    subText: TypographyElement[]
    id: string
}

const SmallprintItem: types.Brick<SmallprintItemProps> = ({ id }) => {
    return (
        <div id={id}>
            <Color>
                <Typography allowList={["small", "sup", "link", "bold"]} propName="subText" />
            </Color>
        </div>
    )
}

SmallprintItem.schema = {
    name: "SmallprintItem",
    label: "Smallprint Item",
    hideFromAddMenu: true,
    sideEditProps: [
        {
            name: "id",
            label: "Anchor Id",
            type: types.SideEditPropType.Text,
        },
    ],
    getDefaultProps: (): SmallprintItemProps => ({
        id: "",
        subText: [
            {
                type: "small",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
                    },
                ],
            },
        ],
    }),
}

export default SmallprintItem
