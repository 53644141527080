import { useEffect, useState } from "react"

import client from "../utils/client"
import { useMigrateLegacyStorage } from "./use-migrate-legacy-storage"

const useClientConfig = () => {
    useMigrateLegacyStorage("customerWeakToken", "api_auth_tokens_customer_weak")
    useMigrateLegacyStorage("customerStrongToken", "api_auth_tokens_customer_strong")

    useMigrateLegacyStorage("email", "api_params_email")

    useMigrateLegacyStorage("cart_id", "api_params_cart_id")
    useMigrateLegacyStorage("cart_access_token", "api_params_cart_access_token")
    useMigrateLegacyStorage("historic_cart_id", "api_params_historic_cart_id")
    useMigrateLegacyStorage("historic_cart_access_token", "api_params_historic_cart_access_token")

    const [config, setConfig] = useState({})

    useEffect(() => {
        const updateConfig = () => {
            setConfig({
                customer_id: client.config.customer_id,
                email: client.config.email,
                consumerToken: client.config.consumerToken,
                customerWeakToken: client.config.customerWeakToken,
                customerStrongToken: client.config.customerStrongToken,
                cart_id: client.config.cart_id,
                cart_access_token: client.config.cart_access_token,
                historic_cart_id: client.config.historic_cart_id,
                historic_cart_access_token: client.config.historic_cart_access_token,
            })
        }

        updateConfig()
        window.addEventListener("legacy_sdk_config_update", updateConfig)
        window.addEventListener("visibilitychange", updateConfig)
        window.addEventListener("focus", updateConfig)

        return () => {
            window.removeEventListener("legacy_sdk_config_update", updateConfig)
            window.removeEventListener("visibilitychange", updateConfig)
            window.removeEventListener("focus", updateConfig)
        }
    }, [])

    return config
}

export { useClientConfig }
