import { Icons, Pictograms, iconOptions, pictogramOptions } from "../../../types"
import { Repeater, Text, types } from "react-bricks/frontend"

import { Icon } from "../../components/icon"
import React from "react"
import { TypographyElement } from "../../Typography"

export interface PDPIconTextGridItemProps {
    icon: Pictograms | Icons
    text: TypographyElement[]
    subText: TypographyElement[]
}

export const PDPIconTextGridItem: types.Brick<PDPIconTextGridItemProps> = ({ icon }) => {
    return (
        <div className="flex gap-x-1.5 md:gap-x-3">
            <Icon
                icon={icon}
                className="w-[34px] text-center text-[25px] leading-none text-copperfield md:w-[42px] md:text-[35px]"
            />
            <div>
                <Text
                    propName="text"
                    renderBlock={({ children }) => <p className="text-md font-bold">{children}</p>}
                    placeholder="Lorem ipsum dolor"
                />
                <Text
                    propName="subText"
                    renderBlock={({ children }) => <p className="mt-1.5 text-base">{children}</p>}
                    placeholder="Lorem ipsum dolor sit amet"
                />
            </div>
        </div>
    )
}

PDPIconTextGridItem.schema = {
    name: "PDPIconTextGridItem",
    label: "Icon Text",
    hideFromAddMenu: true,
    sideEditProps: [
        {
            name: "icon",
            label: "Icon",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: [...pictogramOptions, ...iconOptions],
                display: types.OptionsDisplay.Select,
            },
        },
    ],
    getDefaultProps: (): PDPIconTextGridItemProps => ({
        icon: Pictograms.vitality,
        text: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor",
                    },
                ],
            },
        ],
        subText: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet",
                    },
                ],
            },
        ],
    }),
}

export interface PDPIconTextGridProps {
    index?: number
}

const PDPIconTextGrid: types.Brick<PDPIconTextGridProps> = ({ index }) => {
    return (
        <div key={index} className="grid grid-cols-1 sm:grid-cols-2 gap-5 md:gap-y-7 lg:gap-10">
            <Repeater propName="repeater" />
        </div>
    )
}

PDPIconTextGrid.schema = {
    name: "PDPIconTextGrid",
    label: "Icon Text Grid",
    hideFromAddMenu: true,
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "PDPIconTextGridItem",
                    label: "Icon Text",
                    min: 1,
                },
            ],
        },
    ],
}

export default PDPIconTextGrid
