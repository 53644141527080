const findTranslation = (obj, language, field) => {
    if (!obj) return

    let translation = "translation"
    if (obj.translations) {
        translation = "translations"
    }

    if (!obj[translation]) return

    // directus use another format for languages
    if (obj[translation].find(t => t.language?.includes("_"))) {
        language = language.replace("-", "_")
    }

    if (field) {
        const firstTry = obj[translation].find(t => t.language === language)
        if (firstTry && (Array.isArray(firstTry[field]) ? firstTry[field].length : firstTry[field])) {
            return firstTry[field]
        }

        const secondTry = obj[translation].find(t => t.language === language.split(/-|_/)[0])
        if (secondTry && (Array.isArray(secondTry[field]) ? secondTry[field].length : secondTry[field])) {
            return secondTry[field]
        }

        return undefined
    }

    return (
        obj[translation].find(t => t.language === language) ||
        obj[translation].find(t => t.language === language.split(/-|_/)[0])
    )
}

const findTranslationsDirectus9 = (translations, language) => {
    if (!translations) return

    return (
        translations.find(t => t.languages_code.code === language) ||
        translations.find(t => t.languages_code.code.split("-")[0] === language.split("-")[0])
    )
}

export { findTranslation, findTranslationsDirectus9 }
