import { AuthReducer, initialState } from "./reducer"
import React, { useEffect, useReducer } from "react"
import { useQueryCustomer, useQueryCustomerId } from "../../hooks/customer/use-query-customer"

import { hash } from "../../utils/hash"
import { setCookie } from "../../utils/cookie"
import { updateIsInitialLoad } from "./actions"
import { useClientState } from "../../context/client"

const AuthStateContext = React.createContext()
const AuthDispatchContext = React.createContext()

const useAuthState = () => {
    const context = React.useContext(AuthStateContext)
    if (context === undefined) {
        throw new Error("useAuthState must be used within a AuthProvider")
    }

    return context
}

const useAuthDispatch = () => {
    const context = React.useContext(AuthDispatchContext)
    if (context === undefined) {
        throw new Error("useAuthDispatch must be used within a AuthProvider")
    }

    return context
}

const AuthProvider = props => {
    const [state, dispatch] = useReducer(AuthReducer, initialState)
    const { consumerToken, customerWeakToken } = useClientState()

    useEffect(() => {
        if (!consumerToken) return
        updateIsInitialLoad(dispatch)
    }, [consumerToken])

    useEffect(() => {
        dispatch({ type: "REQUEST_RE_AUTH" })
        if (!customerWeakToken) {
            dispatch({ type: "LOGOUT" })
            return
        }
        dispatch({ type: "RE_AUTH_SUCCESS" })
    }, [customerWeakToken])

    useQueryCustomerId({ background: true })

    const { data: customerData, error } = useQueryCustomer(undefined, { background: true })

    useEffect(() => {
        if (!error) return

        dispatch({ type: "ERROR" })
    }, [error])

    useEffect(() => {
        if (!customerData) return

        window.tracker?.setUserID(customerData.email)

        window.exponea?.identify({ client_id: customerData.ref })

        const asyncFunction = async () => {
            const sha256_email = await hash(customerData.email.toLowerCase())

            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: "email",
                sha256_email: sha256_email,
            })
        }

        asyncFunction()

        dispatch({ type: "UPDATE_IS_PARTNER", payload: { isPartner: !!customerData.features?.length } })

        setCookie("__is_partner__", !!customerData.features?.length)
    }, [customerData, customerWeakToken])

    return (
        <AuthStateContext.Provider value={state}>
            <AuthDispatchContext.Provider value={dispatch}>{props.children}</AuthDispatchContext.Provider>
        </AuthStateContext.Provider>
    )
}

export { useAuthState, useAuthDispatch, AuthProvider }
