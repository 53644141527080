import React, { useEffect, useState } from "react"

import SidebarStyles from "./sidebar.module.css"
import classNames from "classnames"
import { useDelayedUnmounting } from "../../hooks/use-delayed-unmounting"
import { useOnClickOutside } from "../../hooks/use-on-click-outside"

const Sidebar = props => {
    const [showSidebar, setShowSidebar] = useState(false)
    const [referenceElement, setReferenceElement] = useState(null)
    const [sidebarElement, setSidebarElement] = useState(null)

    const shouldRenderChild = useDelayedUnmounting(showSidebar)

    useOnClickOutside(isInside => setShowSidebar(isInside), [referenceElement, sidebarElement])

    useEffect(() => {
        if (showSidebar) {
            document.body.classList.add("noScrollTablet")
        } else {
            document.body.classList.remove("noScrollTablet")
        }
    }, [showSidebar])

    return (
        <>
            {props.customInput instanceof Function ? (
                props.customInput({
                    ref: setReferenceElement,
                    onClick: () => setShowSidebar(showSidebar => !showSidebar),
                })
            ) : (
                <button
                    type="button"
                    ref={setReferenceElement}
                    onClick={() => setShowSidebar(showSidebar => !showSidebar)}
                    className={SidebarStyles.button}
                >
                    <i className="lavita-icon"></i>
                </button>
            )}
            {shouldRenderChild && (
                <div
                    ref={setSidebarElement}
                    className={classNames(SidebarStyles.sidebar, {
                        [SidebarStyles.mounted]: showSidebar,
                    })}
                >
                    <div className={SidebarStyles.top}>
                        <button
                            type="button"
                            className={SidebarStyles.close}
                            onClick={() => setShowSidebar(false)}
                            aria-label="close"
                        ></button>
                    </div>
                    <div className={SidebarStyles.content}>
                        {props.children instanceof Function
                            ? props.children(() => setShowSidebar(false))
                            : props.children}
                    </div>
                </div>
            )}
        </>
    )
}

export default Sidebar
