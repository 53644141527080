import { getPayload } from "./utils/payload"

export class Authentication {
    constructor(options, config, api) {
        this.options = options
        this.config = config
        this.api = api

        this.init()
    }

    async init() {
        const { consumerToken } = this.config

        const consumerTokenValid = this.validateToken(consumerToken)

        if (!consumerToken || !consumerTokenValid) {
            this.authConsumer()
        }
    }

    validateToken(token, interval = 30000) {
        if (!token) {
            return false
        }

        const payload = getPayload(token)

        if (!payload || !payload.exp) {
            return false
        }

        const timeDiff = payload.exp - Date.now()

        if (timeDiff < interval) {
            return false
        }

        return true
    }

    async login(credentials, language) {
        let body = {
            login: credentials.email,
            password: credentials.password,
        }

        const response = await this.api.post("/auth/customer", "consumerToken", language, body)

        if (response) {
            this.config.customerWeakToken = response.token.weak.value
            this.config.customerStrongToken = response.token.strong.value
            this.config.customer_id = response.customer_id

            await Promise.all([
                this.api.get(`/cookies/set/${this.options.storageKey}-customerWeakToken/${response.token.weak.value}`),
                this.api.get(`/cookies/set/${this.options.storageKey}-customerStrongToken/${response.token.strong.value}`),
                this.api.get(`/cookies/set/${this.options.storageKey}-customer_id/${response.customer_id}`),
            ])
        }

        return response
    }

    logout() {
        this.config.customerWeakToken = undefined
        this.config.customerStrongToken = undefined
        this.config.customer_id = undefined
        this.config.email = undefined
        this.config.cart_id = undefined
        this.config.cart_access_token = undefined

        return
    }

    async authConsumer() {
        const body = {
            uid: this.options.uid,
            password: this.options.password,
        }
        try {
            const response = await this.api.post(
                "/auth/consumer",
                null,
                undefined,
                body,
            )

            this.config.consumerToken = response.token
        } catch (error) {
            return
        }
        return
    }
}
