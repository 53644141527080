const initialState = {
    isInitialLoad: true,
    isCustomer: false,
    isPartner: false,
    loading: false,
    reAuthLoading: false,
    error: null,
}

const AuthReducer = (initialState, action) => {
    switch (action.type) {
        case "REQUEST_LOGIN":
            return {
                ...initialState,
                loading: true,
            }
        case "LOGIN_SUCCESS":
            return {
                ...initialState,
                isCustomer: true,
                loading: false,
            }
        case "REQUEST_RE_AUTH":
            return {
                ...initialState,
                reAuthLoading: true,
            }
        case "RE_AUTH_SUCCESS":
            return {
                ...initialState,
                isInitialLoad: false,
                isCustomer: true,
            }
        case "LOGOUT":
            return {
                ...initialState,
                isCustomer: false,
                isPartner: false,
                loading: false,
                reAuthLoading: false,
            }
        case "SWITCH_TO_CUSTOMER":
            return {
                ...initialState,
                isCustomer: true,
            }
        case "UPDATE_IS_INITIAL_LOAD":
            return {
                ...initialState,
                isInitialLoad: false,
            }
        case "UPDATE_IS_PARTNER":
            return {
                ...initialState,
                reAuthLoading: false,
                isPartner: action.payload.isPartner,
            }
        case "ERROR":
            return {
                ...initialState,
                loading: false,
                reAuthLoading: false,
                error: action.error,
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}

export { AuthReducer, initialState }
