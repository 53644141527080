import React from "react"
import { RenderLeafProps } from "slate-react"

const Strong = ({ children, attributes }: RenderLeafProps) => {
    return (
        <strong {...attributes} className="font-semibold">
            {children}
        </strong>
    )
}

export default Strong
