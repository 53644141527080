import { Circle } from "../components/circle"
import React from "react"
import { Spacer } from "../components/layout"
import classNames from "classnames"
import { options } from "../../types"
import { types } from "react-bricks/frontend"

enum Position {
    "Left" = "left",
    "Right" = "right",
}

interface DecorativeCircleProps {
    position: Position
}

const DecorativeCircle: types.Brick<DecorativeCircleProps> = ({ position }) => {
    return (
        <div>
            <Spacer />
            <div className="relative mx-auto max-w-[70%]">
                <div
                    className={classNames("absolute top-1/2 hidden w-[614px] -translate-y-1/2 md:block", {
                        "-right-[602px] rotate-[60deg]": position === Position.Right,
                        "-left-[602px]": position === Position.Left,
                    })}
                >
                    <Circle />
                </div>
            </div>
        </div>
    )
}

DecorativeCircle.schema = {
    name: "DecorativeCircle",
    label: "Decorative Circle",
    sideEditProps: [
        {
            name: "position",
            label: "Position",
            type: types.SideEditPropType.Select,
            selectOptions: {
                display: types.OptionsDisplay.Select,
                options: options<typeof Position>(Position),
            },
        },
    ],
    getDefaultProps: (): DecorativeCircleProps => ({
        position: Position.Right,
    }),
}

export default DecorativeCircle
