import { Badges, ProductImage } from "../components/product"
import { Button, ButtonStyle } from "../components/button"
import { Icons, iconOptions, pictogramOptions } from "../../types"
import { Repeater, types, useAdminContext, usePageValues } from "react-bricks/frontend"
import { additional as additionalHelper, smallprint as smallprintHelper } from "../../util"

import { IconTextListProps } from "./IconTextList"
import type { Product } from "@lavita-io/international-sdk"
import React from "react"
import { formatPrice } from "../../shared/utils/format"
import { usePageContext } from "../../context/page"
import { useTranslation } from "react-i18next"

export interface TabProductProps {
    products: Product[]
    sku: string
    badge?: string
    secondaryBadge?: string
    activeSku?: string
    index?: number
    repeaterBottom?: {
        id: string
        props: IconTextListProps
        type: "IconTextList"
    }[]
    href?: string
    title?: string
    icon?: Icons
    disabled?: boolean
}

const TabProduct: types.Brick<TabProductProps> = ({
    index,
    sku,
    products,
    activeSku,
    badge,
    secondaryBadge,
    repeaterBottom,
    href,
    title,
    icon,
    disabled,
}) => {
    const { t } = useTranslation("translations")
    const { page: pageContext } = usePageContext()

    const [pageValues] = usePageValues()

    const page = pageContext || pageValues

    const product: Product | undefined = products?.find(p => p.sku === sku)

    const { isAdmin, previewMode } = useAdminContext()

    if ((!product && !isAdmin && !previewMode) || activeSku !== sku || !page) return null

    const additional = additionalHelper(page.language, product)
    const smallprint = smallprintHelper(page.language, product)

    return (
        <div
            key={index}
            className="relative rounded-base bg-white px-3.75 pb-3.75 text-base sm:rounded-lg sm:px-7.5 sm:pb-7.5 lg:px-24 lg:pb-24"
        >
            <div className="mx-auto block h-fit max-w-full rounded-t-lg bg-white p-5 pt-3.75 sm:hidden">
                <ProductImage product={product} />
                {(badge || secondaryBadge) && <Badges badge={badge} secondaryBadge={secondaryBadge} />}
            </div>
            <div className="pb-10 text-center lg:pb-16">
                <h4 className="mb-3 text-xl font-semibold text-blue sm:pt-16 sm:text-3xl lg:pt-24 lg:text-5xl">
                    {product?.short_name}
                </h4>
                <p className="pb-5 text-md text-light-blue sm:pb-7 sm:text-lg lg:pb-10 lg:text-xl">
                    {product?.short_description}
                </p>
                <span className="flex flex-row flex-wrap items-center justify-center text-md font-semibold sm:text-xl lg:text-2xl">
                    {product?.price.unit && <p className="mr-1.5 leading-none">{product.price.unit}</p>}
                    {product?.price.regular && product.price.regular !== product.price.amount && (
                        <del className="relative mr-2.5 text-lg font-normal leading-none text-light-grey no-underline after:absolute after:-left-1 after:top-2 after:h-0.5 after:w-11 after:rotate-[-27deg] after:bg-dark-grey">
                            {formatPrice(product.price.regular, product.price.currency, page.language)}
                        </del>
                    )}
                    {product && formatPrice(product.price.amount, product.price.currency, page.language)}
                    {additional && `, ${additional}`}
                </span>
                <span className="block">
                    <small className="mb-10 mt-3.5 block text-xs font-normal text-light-grey lg:text-sm">
                        {smallprint}
                    </small>
                </span>
                <Button
                    className="sm:w-full"
                    title={
                        title || (!product?.subscription ? t("icon_action_add_to_cart") : t("icon_action_configure"))
                    }
                    icon={icon || (!product?.subscription ? Icons.cart : Icons.interval)}
                    href={
                        href ||
                        (!product?.subscription
                            ? `${process.env.SHOP_URL}/cart?add_products=[{"sku":"${sku}","quantity":1}]`
                            : `${process.env.SHOP_URL}/configure/subscription?sku=${sku}`)
                    }
                    disabled={disabled}
                    style={disabled ? ButtonStyle.Disabled : undefined}
                />
            </div>
            <div>
                <div className="space-y-5 lg:space-y-7">
                    <Repeater propName="repeaterTop" />
                </div>
                {!!repeaterBottom?.length && (
                    <>
                        <hr className="my-7 border-tide sm:my-10 lg:my-16"></hr>
                        <div className="space-y-5 lg:space-y-7">
                            <Repeater
                                propName="repeaterBottom"
                                itemProps={{ icon: Icons.lv_usp } as IconTextListProps}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

TabProduct.schema = {
    name: "SingleProduct",
    label: "Tab Product",
    hideFromAddMenu: true,
    repeaterItems: [
        {
            name: "repeaterTop",
            items: [
                {
                    type: "IconTextList",
                    label: "Icon Text List Top",
                    min: 1,
                },
                {
                    type: "RepeaterText",
                    label: "Text Top",
                    min: 0,
                },
                {
                    type: "ProductRepeaterImage",
                    label: "Image Top",
                    min: 0,
                },
            ],
        },
        {
            name: "repeaterBottom",
            items: [
                {
                    type: "IconTextList",
                    label: "Icon Text List Bottom",
                    min: 0,
                },
                {
                    type: "RepeaterText",
                    label: "Text Bottom",
                    min: 0,
                },
                {
                    type: "ProductRepeaterImage",
                    label: "Image Bottom",
                    min: 0,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "sku",
            label: "SKU",
            type: types.SideEditPropType.Text,
        },
        {
            name: "badge",
            label: "Badge",
            type: types.SideEditPropType.Text,
        },
        {
            name: "secondaryBadge",
            label: "Secondary badge",
            type: types.SideEditPropType.Text,
        },
        {
            groupName: "Button",
            props: [
                {
                    name: "disabled",
                    label: "Disabled",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "href",
                    label: "Button Link",
                    type: types.SideEditPropType.Text,
                },
                {
                    name: "title",
                    label: "Title",
                    type: types.SideEditPropType.Text,
                },
                {
                    name: "icon",
                    label: "Icon",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: [...pictogramOptions, ...iconOptions],
                        display: types.OptionsDisplay.Select,
                    },
                },
            ],
        },
    ],
    getDefaultProps: (): Omit<TabProductProps, "products"> => ({
        sku: "LV500",
        disabled: false,
    }),
}

export default TabProduct
