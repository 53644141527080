import React, { useCallback, useEffect, useRef, useState } from "react"

type srcSet = { w?: number; h?: number; default?: boolean; fit?: string }
type url = "assets"

interface ImageProps {
    src?: string
    srcSet: srcSet[]
    sizes: string
    alt?: string
    url?: url
    path: string
    className?: string
    lazy?: boolean
    quality?: number
}

interface GenerateSrc {
    url: string
    path: string
    src: string
}

const Image = ({ src, srcSet, sizes, alt = "", url = "assets", path, className, lazy, quality= 75 }: ImageProps) => {
    const imgRef = useRef<HTMLImageElement>(null)
    const [intersected, setIntersected] = useState(false)

    useEffect(() => {
        if (!lazy || !imgRef.current) return

        const observer = new IntersectionObserver(
            entries => {
                const image = entries[0]
                if (image.isIntersecting) {
                    setIntersected(true)
                    observer.disconnect()
                }
            },
            {
                rootMargin: "256px 256px 256px 256px",
            }
        )

        observer.observe(imgRef.current)
        return () => {
            observer.disconnect()
        }
    }, [lazy])

    const generateSrc = (url: url, path: string, src?: srcSet) => {
        const hostnames = {
            assets: process.env.IMG_CDN_URL_ASSETS,
        }
        return `${hostnames[url] ? hostnames[url] : url}${src ? process.env.IMG_CDN_PATH : ""}${
            src
                ? `/${src.fit ? `fit=${src.fit}` : "fit=scale-down"},${src.w ? `w=${src.w},` : ""}${
                      src.h ? `h=${src.h},` : ""
                  }q=${quality},f=auto`
                : ""
        }${path}`
    }

    const generateSrcSet = useCallback(
        (url: url, path: string, srcSet: srcSet[]) => {
            let srcSets = ""
            srcSet.map(
                (src, i) =>
                    (srcSets += `${generateSrc(url, path, src)}${
                        src.h && !src.w ? ` ${i + 1}x` : src.w ? ` ${src.w}w` : ""
                    }, `)
            )
            return srcSets
        },
        [generateSrc]
    )

    let generatedSrc = ""
    if (Array.isArray(srcSet) && srcSet.find(src => src.default)) {
        generatedSrc = generateSrc(
            url,
            path,
            srcSet.find(src => src.default)
        )
    } else if (!src) {
        generatedSrc = generateSrc(url, path)
    } else {
        generatedSrc = src
    }

    const generatedSrcSet = Array.isArray(srcSet) ? generateSrcSet(url, path, srcSet) : srcSet

    return (
        <img
            src={intersected || !lazy ? generatedSrc : ""}
            srcSet={intersected || !lazy ? generatedSrcSet : ""}
            sizes={sizes}
            alt={alt}
            ref={imgRef}
            className={className}
        />
    )
}

const LazyImage = (props: ImageProps) => <Image {...props} lazy />

export { Image, LazyImage }
