import React from "react"
import { useClientConfig } from "../../hooks/use-client-config"

const ClientContext = React.createContext()
const ClientStateContext = React.createContext()

const useClientState = () => {
    const context = React.useContext(ClientStateContext)
    if (context === undefined) {
        throw new Error("useClientState must be used within a ClientProvider")
    }

    return context
}

const ClientProvider = props => {
    const { client, children } = props

    const state = useClientConfig()

    return (
        <ClientContext.Provider value={client}>
            <ClientStateContext.Provider value={state}>{children}</ClientStateContext.Provider>
        </ClientContext.Provider>
    )
}

export { ClientProvider, useClientState }
