import ImageEditor, { AspectRatio } from "../../Image"
import { Repeater, types } from "react-bricks/frontend"
import Typography, { TypographyElement } from "../../Typography"

import { Color } from "../../components/typography"
import React from "react"
import { TextLinkBrickProps } from "../../text/TextLink"
import { TextLinkSize } from "../../components/text"

interface TwoColumnImageTextPropsItem {
    text: TypographyElement[]
    index?: number
}

const TwoColumnImageTextItem: types.Brick<TwoColumnImageTextPropsItem> = ({ index }) => {
    return (
        <div key={index} className="flex flex-col space-y-5 sm:space-y-7">
            <ImageEditor aspectRatio={AspectRatio["3/2"]} maxWidth={717} className="overflow-hidden rounded-base" />
            <Color>
                <Typography block="copytext-small" allowList={["h5", "small", "sup", "link", "bold"]} />
            </Color>
            <Repeater propName="repeater" itemProps={{ size: TextLinkSize.Small } as TextLinkBrickProps} />
        </div>
    )
}

TwoColumnImageTextItem.schema = {
    name: "TwoColumnImageTextItem",
    label: "Two Column Image Text Item",
    hideFromAddMenu: true,
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "TextLink",
                    label: "Text Link",
                    max: 1,
                },
                {
                    type: "Button",
                    label: "Button",
                    max: 1,
                },
            ],
        },
    ],
    getDefaultProps: (): TwoColumnImageTextPropsItem => ({
        text: [
            {
                type: "h5",
                children: [
                    {
                        bold: true,
                        text: "Lorem ipsum dolor sit amet, consetetur",
                    },
                ],
            },
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
                    },
                ],
            },
        ],
    }),
}

export default TwoColumnImageTextItem
