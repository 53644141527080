import React, { useCallback } from "react"
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query"

import ErrorToast from "../components/miscellaneous/error-toast"
import { toast } from "react-toastify"
import { useClientState } from "../context/client"

const useQueryWithErrorHandling = (queryKey, queryFn, queryOptions) => {
    const { consumerToken } = useClientState()

    const query = useQuery(queryKey, queryFn, {
        enabled: !!consumerToken,
        ...queryOptions,
        onError: error => {
            typeof queryOptions?.onError === "function"
                ? queryOptions.onError(error)
                : toast.error(<ErrorToast error={error} />, { toastId: error.code })
        },
    })

    return query
}

const useInfiniteQueryWithErrorHandling = (queryKey, queryFn, queryOptions) => {
    const { consumerToken } = useClientState()

    const query = useInfiniteQuery(queryKey, queryFn, {
        enabled: !!consumerToken,
        ...queryOptions,
        onError: error => {
            typeof queryOptions?.onError === "function"
                ? queryOptions.onError(error)
                : toast.error(<ErrorToast error={error} />, { toastId: error.code })
        },
    })

    return query
}

const useMutationWithErrorHandling = (mutationFn, options, mutationKey) => {
    const mutation = useMutation({ mutationFn, mutationKey, ...options })

    const { mutateAsync } = mutation

    return {
        ...mutation,
        mutateAsync: useCallback(
            async (variables, args) => {
                try {
                    return await mutateAsync(variables, args)
                } catch (error) {
                    if (!(error.loc && args?.hideToast) && !args?.forceHideToast) {
                        toast.error(<ErrorToast error={error} />, { toastId: error.code })
                    }
                    if (args?.throwError) throw error
                }
            },
            [mutateAsync]
        ),
    }
}

export { useQueryWithErrorHandling, useMutationWithErrorHandling, useInfiniteQueryWithErrorHandling }
