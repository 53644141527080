import client from "../../utils/client"
import { deleteCookie } from "../../utils/cookie"

const login = async (dispatch, body, options) => {
    try {
        dispatch({ type: "REQUEST_LOGIN" })
        const response = await client.login(body)
        dispatch({ type: "LOGIN_SUCCESS" })
        options && options.onSuccess(response)
        return
    } catch (error) {
        options && options.onError(error)
        dispatch({ type: "ERROR", error: error })
        if (client.config.customerWeakToken) logout(dispatch)
    }
}

const logout = dispatch => {
    try {
        dispatch({ type: "LOGOUT" })
        client.logout()
        deleteCookie("__is_partner__")
        return
    } catch (error) {
        dispatch({ type: "ERROR", error: error })
    }
}

const switchToCustomer = dispatch => {
    return dispatch({ type: "SWITCH_TO_CUSTOMER" })
}

const updateIsInitialLoad = dispatch => {
    return dispatch({ type: "UPDATE_IS_INITIAL_LOAD" })
}

export { login, logout, switchToCustomer, updateIsInitialLoad }
