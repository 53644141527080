import { Background, BackgroundColor, Container, Spacer, SpacerVariant } from "../components/layout"
import React, { useRef } from "react"
import { Repeater, Text, types } from "react-bricks/frontend"

import { ConditionalWrapper } from "../components/util"
import { Icons } from "../../types"
import { PDPImageProps } from "./pdp/Image"
import { Product } from "@lavita-io/international-sdk"
import { TypographyElement } from "../Typography"
import classNames from "classnames"
import { formatPrice } from "../../shared/utils/format"
import i18n from "../../i18n/config"
import { price } from "../../util/price"
import { smallprint } from "../../util"
import { twMerge } from "tailwind-merge"
import { useCheckForEmptyText } from "../../hooks/use-check-for-empty-text"
import { useLocalesState } from "../../shared/context/locales"
import { useSlider } from "../../hooks/use-slider"
import { useWindowSize } from "../../hooks/use-window-size-admin"

export interface PDPProps {
    spacerTop: boolean
    spacerBottom: boolean
    id?: string
    image?: {
        id: string
        props: PDPImageProps
        type: "PDPImage"
    }[]
    subHeadline?: TypographyElement[]
    label?: TypographyElement[]
    unit?: TypographyElement[]
    badge?: TypographyElement[]
    sku?: string
    activeTab?: number
    inline?: boolean
    index?: number
    products?: Product[]
}

const customAdditional = (language: string, product?: Product) => {
    if (!product) return

    return product.sku === "LV2START"
        ? i18n.t("lvstart_pricing_addition_extended", {
              splits: product.splitted?.parts || 1,
              price: formatPrice(
                  product.price.amount / (product.splitted?.parts || 1),
                  product.price.currency,
                  language
              ),
              total_price: formatPrice(product.price.amount, product.price.currency, language),
          })
        : undefined
}

const PDP: types.Brick<PDPProps> = ({
    spacerTop,
    spacerBottom,
    id,
    image,
    badge,
    unit,
    subHeadline,
    label,
    sku,
    products,
    activeTab,
    inline,
    index,
}) => {
    const { language } = useLocalesState()
    const sliderRef = useRef<null | HTMLDivElement>(null)

    const length = image ? image.length : 0

    const {
        handleDragStart,
        handleDragMove,
        handleDragEnd,
        index: sliderIndex,
        lastIndex,
        transition,
        goTo,
    } = useSlider(length, sliderRef, 0)

    const emptySubHeadline = useCheckForEmptyText(subHeadline)
    const emptyLabel = useCheckForEmptyText(label)
    const emptyUnit = useCheckForEmptyText(unit)
    const emptyBadge = useCheckForEmptyText(badge)

    const { width } = useWindowSize({ initializeWithValue: false })

    if (typeof index !== "undefined" && activeTab !== index) return null

    const product = products?.find(p => p.sku === sku)

    const additional = customAdditional(language, product)

    return (
        <div key={index}>
            <ConditionalWrapper
                condition={!inline}
                wrapper={children => (
                    <Background color={BackgroundColor.White} className="overflow-hidden">
                        <Container>
                            <Spacer variant={!spacerTop ? SpacerVariant.None : SpacerVariant.Small} />
                            {children}
                            <Spacer variant={!spacerBottom ? SpacerVariant.None : SpacerVariant.Small} />
                        </Container>
                    </Background>
                )}
            >
                <div
                    className="grid grid-cols-1 sm:grid-cols-[320px_auto] sm:gap-x-10 md:grid-cols-[462px_auto] lg:grid-cols-[530px_auto] lg:gap-x-16 xl:grid-cols-[708px_auto] xl:gap-x-20"
                    id={id}
                >
                    <div>
                        <div className="-mx-3 sm:mx-0 sm:overflow-hidden">
                            <ConditionalWrapper
                                wrapper={children => (
                                    <div
                                        className={classNames("grid gap-x-1.5 sm:gap-x-6", {
                                            "transition-transform duration-[250ms] ease-in-out": transition,
                                            "cursor-grab active:cursor-grabbing": length > 1,
                                        })}
                                        style={
                                            {
                                                width: `calc(var(--child-width) * ${length} + var(--gap) * ${
                                                    length - 1
                                                })`,
                                                transform: `translateX(calc(${
                                                    -1 * sliderIndex
                                                } * (100% + var(--gap)) / ${length}))`,
                                                gridTemplateColumns: `repeat(${length}, 1fr)`,
                                                "--child-width": "100%",
                                                // @ts-ignore
                                                "--gap": `${width < 768 ? 0.375 : 1.5}rem`,
                                            } as React.CSSProperties
                                        }
                                        onTouchStart={handleDragStart}
                                        onMouseDown={handleDragStart}
                                        onTouchMove={handleDragMove}
                                        onMouseMove={handleDragMove}
                                        onTouchEnd={handleDragEnd}
                                        onMouseUp={handleDragEnd}
                                        onMouseLeave={handleDragEnd}
                                        role="none"
                                        ref={sliderRef}
                                    >
                                        {children}
                                    </div>
                                )}
                                condition={length > 1}
                            >
                                <Repeater propName="image" />
                            </ConditionalWrapper>
                        </div>
                        {length > 1 && (
                            <div className="mt-3">
                                <div className="flex justify-center">
                                    {[...Array(length)].map((_, i) => (
                                        // eslint-disable-next-line
                                        <button
                                            type="button"
                                            className={classNames(
                                                "mr-1.5 h-[9px] w-[9px] rounded-full bg-disabled last:mr-0",
                                                {
                                                    "bg-light-grey": lastIndex === i,
                                                }
                                            )}
                                            onClick={() => goTo(i)}
                                            key={i}
                                        ></button>
                                    ))}
                                </div>
                                <div className="mt-5 hidden justify-center sm:flex lg:mt-7">
                                    <button
                                        className={classNames("mr-3.5 hidden select-none text-blue sm:block lg:mr-5", {
                                            "pointer-events-none text-disabled": lastIndex < 1,
                                        })}
                                        type="button"
                                        onClick={() => goTo(lastIndex - 1)}
                                    >
                                        <i className="lavita-icon text-[42px] lg:text-[52px]">{Icons.back}</i>
                                    </button>
                                    <button
                                        className={classNames("hidden select-none text-blue sm:block", {
                                            "pointer-events-none text-disabled": lastIndex + 1 >= length,
                                        })}
                                        type="button"
                                        onClick={() => goTo(lastIndex + 1)}
                                    >
                                        <i className="lavita-icon scale-x-[-1] text-[42px] lg:text-[52px]">
                                            {Icons.back}
                                        </i>
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="mt-7 flex flex-col sm:mt-5">
                        <div className="flex justify-between">
                            {!emptySubHeadline && (
                                <div className="flex-grow">
                                    <Text
                                        propName="subHeadline"
                                        renderBlock={({ children }) => (
                                            <h2 className="mb-2 text-xs sm:mb-3 lg:text-sm">{children}</h2>
                                        )}
                                        placeholder="Lorem ipsum"
                                    />
                                </div>
                            )}
                            {!emptyLabel && (
                                <div className="flex-shrink-0 flex-grow">
                                    <Text
                                        propName="label"
                                        renderBlock={({ children }) => (
                                            <p className="mb-2 ml-auto text-right text-xs sm:mb-3 lg:text-sm">
                                                {children}
                                            </p>
                                        )}
                                        placeholder="Lorem ipsum"
                                    />
                                </div>
                            )}
                        </div>
                        <div className="flex justify-between border-b border-background-tide pb-5 sm:pb-7 lg:pb-10">
                            <div className="flex flex-grow flex-col">
                                <Text
                                    propName="headline"
                                    renderBlock={({ children }) => (
                                        <h1 className="text-xl font-bold md:text-3xl lg:text-5xl">{children}</h1>
                                    )}
                                    placeholder="Lorem ipsum"
                                />
                                <Text
                                    propName="description"
                                    renderBlock={({ children }) => (
                                        <p className="mt-2 text-lg sm:mt-3 md:text-xl lg:mt-3.5 lg:text-2xl">
                                            {children}
                                        </p>
                                    )}
                                    placeholder="Lorem ipsum dolor sit amet"
                                />
                            </div>
                            <div className="flex flex-shrink-0 flex-grow flex-col text-right">
                                {product ? (
                                    <>
                                        <strong className="text-xl font-bold md:text-3xl lg:text-5xl">
                                            {!emptyUnit && (
                                                <Text
                                                    propName="unit"
                                                    renderBlock={({ children }) => (
                                                        <span className="text-sm sm:text-base md:text-md lg:text-lg">
                                                            {children}
                                                        </span>
                                                    )}
                                                    placeholder="Lorem"
                                                />
                                            )}
                                            {price(language, product)}
                                        </strong>
                                        {additional ? (
                                            <div className="mt-2 sm:mt-3 lg:mt-3.5">
                                                <span className="inline-flex flex-wrap whitespace-pre-wrap rounded-sm bg-background-tide p-2 text-xs font-bold sm:p-2.5 lg:px-3.5 lg:py-3 lg:text-sm">
                                                    {additional}
                                                </span>
                                            </div>
                                        ) : !emptyBadge ? (
                                            <div className="mt-2 sm:mt-3 lg:mt-3.5">
                                                <span
                                                    className={twMerge(
                                                        classNames(
                                                            "flex-wrap whitespace-pre-wrap rounded-full bg-background-tide px-2.5 py-1.5 text-xs font-bold sm:px-3 lg:text-sm",
                                                            {
                                                                "rounded-sm p-2 sm:p-2.5 lg:px-3.5 lg:py-3":
                                                                    badge && badge.length > 1,
                                                                "inline-flex":
                                                                    badge?.length &&
                                                                    badge[0].children.length &&
                                                                    badge[0].children[0].text,
                                                            }
                                                        )
                                                    )}
                                                >
                                                    <Text
                                                        propName="badge"
                                                        multiline
                                                        renderBlock={({ children }) => <p>{children}</p>}
                                                        placeholder="Lorem ipsum dolor"
                                                    />
                                                </span>
                                            </div>
                                        ) : null}
                                        <small className="mt-2 text-xs text-light-grey sm:mt-3 lg:mt-3.5 lg:text-sm">
                                            {smallprint(language, product)}
                                        </small>
                                    </>
                                ) : (
                                    <>
                                        <strong className="text-xl font-bold md:text-3xl lg:text-5xl">
                                            {!emptyUnit && (
                                                <Text
                                                    propName="unit"
                                                    renderBlock={({ children }) => (
                                                        <span className="text-sm sm:text-base md:text-md lg:text-lg">
                                                            {children}
                                                        </span>
                                                    )}
                                                    placeholder="Lorem"
                                                />
                                            )}
                                            <Text
                                                propName="price"
                                                renderBlock={({ children }) => <>{children}</>}
                                                placeholder="Lorem"
                                            />
                                        </strong>
                                        {!emptyBadge && (
                                            <div className="mt-2 sm:mt-3 lg:mt-3.5">
                                                <span
                                                    className={twMerge(
                                                        classNames(
                                                            "flex-col whitespace-pre-wrap rounded-full bg-background-tide px-2.5 py-1.5 text-xs font-bold sm:px-3 lg:text-sm",
                                                            {
                                                                "rounded-sm p-2 sm:p-2.5 lg:px-3.5 lg:py-3":
                                                                    badge && badge.length > 1,
                                                                "inline-flex":
                                                                    badge?.length &&
                                                                    badge[0].children.length &&
                                                                    badge[0].children[0].text,
                                                            }
                                                        )
                                                    )}
                                                >
                                                    <Text
                                                        propName="badge"
                                                        multiline
                                                        renderBlock={({ children }) => <p>{children}</p>}
                                                        placeholder="Lorem ipsum dolor"
                                                    />
                                                </span>
                                            </div>
                                        )}
                                        <Text
                                            propName="smallprint"
                                            renderBlock={({ children }) => (
                                                <small className="mt-2 text-xs text-light-grey sm:mt-3 lg:mt-3.5 lg:text-sm">
                                                    {children}
                                                </small>
                                            )}
                                            placeholder="Lorem ipsum dolor sit"
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                        <Repeater
                            propName="repeater"
                            renderWrapper={children => (
                                <div className="mt-5 flex flex-col gap-5 sm:mt-7 lg:mt-10 lg:gap-7">{children}</div>
                            )}
                            itemProps={{ fullWidth: true }}
                        />
                    </div>
                </div>
            </ConditionalWrapper>
        </div>
    )
}

PDP.schema = {
    name: "PDP",
    label: "PDP",
    repeaterItems: [
        {
            name: "image",
            items: [
                {
                    type: "PDPImage",
                    label: "Image",
                    min: 1,
                },
            ],
        },
        {
            name: "repeater",
            items: [
                {
                    type: "PDPIconTextGrid",
                    label: "Icon Text Grid",
                    min: 0,
                },
                {
                    type: "Button",
                    label: "Button",
                    min: 0,
                },
                {
                    type: "GreenText",
                    label: "Green Text",
                    min: 0,
                },
                {
                    type: "List",
                    label: "List",
                    min: 0,
                },
                {
                    type: "Trust",
                    label: "Trust Badge",
                    min: 0,
                    max: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "sku",
            label: "SKU",
            type: types.SideEditPropType.Text,
        },
        {
            name: "id",
            label: "ID",
            type: types.SideEditPropType.Text,
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: (): PDPProps => ({
        spacerTop: true,
        spacerBottom: true,
    }),
}

export default PDP
