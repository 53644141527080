import { Button, ButtonStyle } from "../components/button"
import { Icons, iconOptions, pictogramOptions } from "../../types"
import ImageEditor, { AspectRatio } from "../Image"
import { Repeater, Text, types } from "react-bricks/frontend"

import { Badges } from "../components/product"
import { EqualHeightElement } from "react-equal-height/clean"
import React from "react"
import classNames from "classnames"
import { twMerge } from "tailwind-merge"
import { useWindowSize } from "../../hooks/use-window-size-admin"

export interface ColumnCustomProductProps {
    badge?: string
    secondaryBadge?: string
    index?: number
    wide?: boolean
    deletedPrice?: string
    href: string
    title: string
    icon?: Icons
    disabled?: boolean
}

const ColumnCustomProduct: types.Brick<ColumnCustomProductProps> = ({
    index,
    badge,
    secondaryBadge,
    disabled,
    deletedPrice,
    wide,
    title,
    icon,
    href,
}) => {
    const { width } = useWindowSize({ initializeWithValue: false })

    return (
        <div
            key={index}
            className={twMerge(
                classNames("relative rounded-base bg-white p-3.75 sm:p-5 lg:p-7.5", {
                    "sm:px-7.5 lg:px-15": wide,
                })
            )}
        >
            <div className="mx-auto mb-5 w-[200px] max-w-full sm:w-[181px] lg:w-[235px] xl:w-[300px]">
                <div className="aspect-h-1 aspect-w-1">
                    <ImageEditor propName="image" alt="Product" maxWidth={464} aspectRatio={AspectRatio["1/1"]} />
                </div>
            </div>
            <div>
                {/* @ts-ignore */}
                <EqualHeightElement name="name" disable={width < 768}>
                    <Text
                        propName="headline"
                        renderBlock={({ children }) => (
                            <h4 className="mb-3 text-center text-md font-semibold text-blue md:text-lg lg:text-xl">
                                {children}
                            </h4>
                        )}
                        placeholder="headline"
                    />
                    <Text
                        propName="subheadline"
                        renderBlock={({ children }) => (
                            <p className="text-center text-base text-light-blue md:text-md">{children}</p>
                        )}
                        placeholder="subheadline"
                    />
                </EqualHeightElement>
                {/* @ts-ignore */}
                <EqualHeightElement name="price" disable={width < 768}>
                    <span className="mb-5 mt-5 block text-center text-2xl font-semibold lg:mb-10">
                        {deletedPrice && (
                            <del className="relative mr-2.5 mt-1 text-sm font-normal text-light-grey no-underline after:absolute after:-left-1 after:top-2 after:h-0.5 after:w-11 after:rotate-[-27deg] after:bg-dark-grey sm:text-lg">
                                {deletedPrice}
                            </del>
                        )}
                        <Text propName="price" renderBlock={({ children }) => <>{children}</>} placeholder="price" />
                        <Text
                            propName="smallPrint"
                            renderBlock={({ children }) => (
                                <small className="mt-2.5 block whitespace-pre-wrap text-xs font-normal text-light-grey lg:text-sm">
                                    {children}
                                </small>
                            )}
                            placeholder="smallPrint"
                        />
                    </span>
                </EqualHeightElement>
                <Button
                    className="sm:w-full"
                    title={title}
                    icon={icon}
                    href={href}
                    disabled={disabled}
                    style={disabled ? ButtonStyle.Disabled : undefined}
                />
                <div className="mt-5 lg:mt-10">
                    <div className="space-y-3 lg:space-y-5">
                        <Repeater propName="repeaterTop" />
                    </div>
                </div>
            </div>
            {(badge || secondaryBadge) && <Badges badge={badge} secondaryBadge={secondaryBadge} />}
        </div>
    )
}

ColumnCustomProduct.schema = {
    name: "ProductCustomTR",
    label: "Column Custom Product",
    hideFromAddMenu: true,
    repeaterItems: [
        {
            name: "repeaterTop",
            items: [
                {
                    type: "IconTextList",
                    label: "Icon Text List Top",
                    min: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "badge",
            label: "Badge",
            type: types.SideEditPropType.Text,
        },
        {
            name: "secondaryBadge",
            label: "Secondary badge",
            type: types.SideEditPropType.Text,
        },
        {
            name: "deletedPrice",
            label: "Deleted Price",
            type: types.SideEditPropType.Text,
        },
        {
            groupName: "Button",
            props: [
                {
                    name: "disabled",
                    label: "Disabled",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "href",
                    label: "Button Link",
                    type: types.SideEditPropType.Text,
                },
                {
                    name: "title",
                    label: "Title",
                    type: types.SideEditPropType.Text,
                },
                {
                    name: "icon",
                    label: "Icon",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: [...pictogramOptions, ...iconOptions],
                        display: types.OptionsDisplay.Select,
                    },
                },
            ],
        },
    ],
}

export default ColumnCustomProduct
