import {
    Align,
    Color,
    MaxWidth,
    TypographyAlign,
    TypographyColor,
    TypographyMaxWidth,
    alignOptions,
    colorOptions,
} from "../components/typography"
import { Container, Spacer, SpacerVariant } from "../components/layout"
import ImageEditor, { AspectRatio as ImageAspectRatio } from "../Image"
import React, { useEffect, useRef } from "react"
import { Repeater, types } from "react-bricks/frontend"
import Typography, { TypographyElement } from "../Typography"

import { ButtonBrickProps } from "../button/Button"
import { ButtonStyle } from "../components/button"
import { CloudflarePlayer } from "../components/video"
import { StreamPlayerApi } from "@cloudflare/stream-react"
import classNames from "classnames"
import { options } from "../../types"
import { twMerge } from "tailwind-merge"
import { useCheckForEmptyText } from "../../hooks/use-check-for-empty-text"
import { useIntersectionObserver } from "usehooks-ts"

export enum AspectRatio {
    "5/2" = "2.5",
    "16/9" = "1.77",
}

interface FullWidthVideoBreakProps {
    cloudflareVideoId: string
    cloudflareVideoIdMobile: string
    overlap: TypographyElement[]
    text: TypographyElement[]
    align: TypographyAlign
    color: { color: TypographyColor } | TypographyColor
    aspectRatio: AspectRatio
    textBelowVideo: boolean
}

interface PlayerProps {
    id: string
}

const Player = ({ id }: PlayerProps) => {
    const player = useRef<StreamPlayerApi>()

    const playerRef = useRef(null)

    const playerEntry = useIntersectionObserver(playerRef, {
        rootMargin: "1256px 256px 1256px 256px",
        freezeOnceVisible: true,
    })
    const playerIntersectionObserver = useIntersectionObserver(playerRef, { rootMargin: "256px 256px 256px 256px" })

    useEffect(() => {
        if (!playerIntersectionObserver || !player.current) return

        if (!playerIntersectionObserver.isIntersecting) {
            player.current.pause()
        } else {
            try {
                player.current.play()
            } catch (e) {
                player.current.muted = true
                player.current.play()
            }
        }
    }, [playerIntersectionObserver?.isIntersecting, player.current])

    return (
        <div ref={playerRef} className="child:h-full">
            {playerEntry?.isIntersecting && <CloudflarePlayer id={id} streamRef={player} />}
        </div>
    )
}

const FullWidthVideoBreak: types.Brick<FullWidthVideoBreakProps> = ({
    cloudflareVideoId,
    cloudflareVideoIdMobile,
    align,
    color,
    aspectRatio,
    textBelowVideo,
    overlap,
    text,
}) => {
    const emptyOverlap = useCheckForEmptyText(overlap)
    const emptyText = useCheckForEmptyText(text)

    const txtColor = typeof color === "object" ? color.color : color

    return (
        <div>
            <div className="relative">
                <div className="relative">
                    <div
                        className={classNames("w-full sm:hidden", {
                            "aspect-h-5 aspect-w-4": aspectRatio === AspectRatio["16/9"],
                            "aspect-h-4 aspect-w-5": aspectRatio === AspectRatio["5/2"],
                        })}
                    >
                        <ImageEditor
                            aspectRatio={
                                aspectRatio === AspectRatio["16/9"] ? ImageAspectRatio["4/5"] : ImageAspectRatio["5/4"]
                            }
                            maxWidth={768}
                            className={classNames("w-full", { "z-2": !cloudflareVideoIdMobile })}
                            propName="image_mobile"
                        />
                        <Player id={cloudflareVideoIdMobile} />
                    </div>
                    <div
                        className={classNames("hidden sm:block", {
                            "aspect-h-9 aspect-w-16": aspectRatio === AspectRatio["16/9"],
                            "aspect-h-2 aspect-w-5": aspectRatio === AspectRatio["5/2"],
                        })}
                    >
                        <ImageEditor
                            aspectRatio={
                                aspectRatio === AspectRatio["16/9"] ? ImageAspectRatio["16/9"] : ImageAspectRatio["5/2"]
                            }
                            maxWidth={1920}
                            className={classNames("w-full", { "z-2": !cloudflareVideoId })}
                            propName="image"
                        />
                        <Player id={cloudflareVideoId} />
                    </div>
                </div>
                {(!emptyText || !emptyOverlap) && (
                    <div className="absolute top-1/2 w-full -translate-y-1/2 justify-center">
                        <Container>
                            <Align align={align}>
                                <MaxWidth
                                    smMaxWidth={TypographyMaxWidth.Full}
                                    mdMaxWidth={
                                        align === TypographyAlign.Center
                                            ? TypographyMaxWidth["70%"]
                                            : TypographyMaxWidth.Half
                                    }
                                >
                                    <Color color={txtColor}>
                                        {!emptyOverlap && (
                                            <Typography
                                                allowList={["h2", "h3", "sup", "link", "bold"]}
                                                propName="overlap"
                                            />
                                        )}
                                        {!emptyText && (
                                            <div
                                                className={classNames({
                                                    "mt-5 lg:mt-7": !emptyOverlap,
                                                    "hidden sm:block": !textBelowVideo,
                                                    "hidden md:block": textBelowVideo,
                                                })}
                                            >
                                                <Typography
                                                    allowList={[
                                                        "h2",
                                                        "h3",
                                                        "ul",
                                                        "small",
                                                        "sup",
                                                        "link",
                                                        "bold",
                                                        "checkList",
                                                    ]}
                                                />
                                            </div>
                                        )}
                                    </Color>
                                </MaxWidth>
                                <div
                                    className={twMerge(
                                        classNames({
                                            "hidden sm:block": !textBelowVideo,
                                            "hidden md:block": textBelowVideo,
                                            block: emptyText,
                                        })
                                    )}
                                >
                                    <Repeater
                                        propName="repeater"
                                        renderWrapper={children => <div className="mt-7 md:mt-10">{children}</div>}
                                    />
                                </div>
                            </Align>
                        </Container>
                    </div>
                )}
            </div>
            {!emptyText && (
                <div className={classNames({ "sm:hidden": !textBelowVideo, "md:hidden": textBelowVideo })}>
                    <Container>
                        <Spacer variant={SpacerVariant.Small} />
                        <Align align={TypographyAlign.Left}>
                            <Color>
                                <Typography
                                    allowList={["h2", "h3", "ul", "small", "sup", "link", "bold", "checkList"]}
                                />
                            </Color>
                        </Align>
                        <Repeater
                            propName="repeater"
                            renderWrapper={children => <div className="mt-7">{children}</div>}
                            itemProps={{ style: ButtonStyle.Primary } as ButtonBrickProps}
                        />
                        <Spacer variant={SpacerVariant.Small} />
                    </Container>
                </div>
            )}
        </div>
    )
}

FullWidthVideoBreak.schema = {
    name: "FullWidthVideoBreak",
    label: "Full Width Video Break",
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "Button",
                    label: "Button",
                    max: 1,
                },
                {
                    type: "TextLink",
                    label: "Text Link",
                    max: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "align",
            label: "Align",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: alignOptions,
                display: types.OptionsDisplay.Select,
            },
        },
        {
            name: "color",
            label: "Color",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: colorOptions,
                display: types.OptionsDisplay.Color,
            },
        },
        {
            name: "aspectRatio",
            label: "Aspect Ratio",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: options<typeof AspectRatio>(AspectRatio),
                display: types.OptionsDisplay.Select,
            },
        },
        {
            name: "cloudflareVideoId",
            label: "Cloudflare Video Id",
            type: types.SideEditPropType.Text,
        },
        {
            name: "cloudflareVideoIdMobile",
            label: "Cloudflare Video Id Mobile",
            type: types.SideEditPropType.Text,
        },
        {
            name: "textBelowVideo",
            label: "Text below Video on Tablet Portrait",
            type: types.SideEditPropType.Boolean,
        },
    ],
    getDefaultProps: (): FullWidthVideoBreakProps => ({
        overlap: [
            {
                type: "h2",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet",
                    },
                ],
            },
        ],
        text: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                    },
                ],
            },
        ],
        cloudflareVideoId: "",
        cloudflareVideoIdMobile: "",
        align: TypographyAlign.Center,
        color: { color: TypographyColor.White },
        aspectRatio: AspectRatio["16/9"],
        textBelowVideo: false,
    }),
}

export default FullWidthVideoBreak
