import React from "react"
import { RenderLeafProps } from "slate-react"

const Italic = ({ children, attributes }: RenderLeafProps) => {
    return (
        <em {...attributes} className="font-normal">
            {children}
        </em>
    )
}

export default Italic
