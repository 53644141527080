import React from "react"
import SpacerStyles from "./spacer.module.css"
import classNames from "classnames"

const Spacer = props => {
    const sizeClasses = {}

    for (const size in props.size) {
        sizeClasses[SpacerStyles[`${props.size[size]}${size[0].toUpperCase()}${size.slice(1)}`]] =
            props.size[size] !== "default"
    }

    return (
        <div
            className={classNames(SpacerStyles.spacer, {
                ...sizeClasses,
                [SpacerStyles.large]: props.large,
                [SpacerStyles.medium]: props.medium,
                [SpacerStyles.small]: props.small,
                [SpacerStyles.notMobile]: props.notMobile,
            })}
        />
    )
}

export default Spacer
