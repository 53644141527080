import ImageEditor, { AspectRatio } from "../Image"
import { Link, Text, types, useAdminContext } from "react-bricks/frontend"
import React, { useLayoutEffect, useRef, useState } from "react"

import classNames from "classnames"

export interface StickyProductBoxProps {
    href: string
    button: string
    identifier?: string
}

const StickyProductBox: types.Brick<StickyProductBoxProps> = ({ href, button, identifier }) => {
    const ref = useRef<HTMLDivElement>(null)
    const [scrollTop, setScrollTop] = useState(0)
    const [offset, setOffset] = useState(0)

    useLayoutEffect(() => {
        const currentRef = ref.current
        if (!currentRef) return

        const handleWindowScroll = () => {
            setScrollTop(document.documentElement.scrollTop)
        }
        const handleResize = () => {
            setOffset(currentRef.offsetTop)
        }
        handleWindowScroll()
        handleResize()
        window.addEventListener("scroll", handleWindowScroll)
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("scroll", handleWindowScroll)
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    const { isAdmin } = useAdminContext()

    return (
        <div ref={ref}>
            <div
                className={classNames(
                    "bottom-0 z-7 w-full items-center justify-between gap-2.5 bg-white p-2.5 shadow-[0_0_6px_0_rgba(0,0,0,0.16)] sm:hidden",
                    {
                        flex: scrollTop > offset || isAdmin,
                        hidden: scrollTop <= offset && !isAdmin,
                        fixed: !isAdmin,
                    }
                )}
            >
                <div className="h-15 w-15 shrink-0">
                    <ImageEditor aspectRatio={AspectRatio["1/1"]} maxWidth={60} className="w-full" />
                </div>
                <div className="flex-grow">
                    <div className="flex w-full justify-between">
                        <div className="flex flex-col">
                            <Text
                                propName="headline"
                                renderBlock={({ children }) => <h1 className="text-xs font-bold">{children}</h1>}
                                placeholder="Lorem ipsum"
                            />
                            <Text
                                propName="description"
                                renderBlock={({ children }) => (
                                    <p className="mt-1 text-[10px] leading-none">{children}</p>
                                )}
                                placeholder="Lorem ipsum"
                            />
                        </div>
                        <div className="flex shrink-0 flex-col text-right">
                            <Text
                                propName="price"
                                renderBlock={({ children }) => (
                                    <strong className="text-xs font-bold">{children}</strong>
                                )}
                                placeholder="Lorem"
                            />
                            <Text
                                propName="badge"
                                renderBlock={({ children }) => (
                                    <span className="mt-1 text-[10px] font-bold leading-none">{children}</span>
                                )}
                                placeholder="Lorem ipsum"
                            />
                        </div>
                    </div>
                    <Text
                        propName="smallprint"
                        renderBlock={({ children }) => (
                            <small className="mt-1.5 block text-right text-[8px] leading-none">{children}</small>
                        )}
                        placeholder="Lorem ipsum dolor sit"
                    />
                </div>
                <Link
                    href={href}
                    onClick={() => {
                        if (!identifier) return

                        window.exponea?.track("calltoaction", {
                            action: "clicked",
                            title: button,
                            identifier: identifier,
                        })
                    }}
                    className="shrink-0 rounded-[6px] bg-blue px-3 py-1.5 text-xs font-bold text-white"
                >
                    {button}
                </Link>
            </div>
        </div>
    )
}

StickyProductBox.schema = {
    name: "StickyProductBox",
    label: "Sticky Product Box",
    sideEditProps: [
        {
            name: "href",
            label: "Button Link",
            type: types.SideEditPropType.Text,
        },
        {
            name: "button",
            label: "Button",
            type: types.SideEditPropType.Text,
        },
        {
            groupName: "Tracking",
            props: [
                {
                    name: "identifier",
                    label: "Identifier Bloomreach",
                    type: types.SideEditPropType.Text,
                },
            ],
        },
    ],
    getDefaultProps: (): StickyProductBoxProps => ({
        href: process.env.SITE_URL,
        button: "LaVita",
    }),
}

export default StickyProductBox
