import ImageEditor, { AspectRatio } from "../Image"
import Typography, { TypographyElement } from "../Typography"

import { Color } from "../components/typography"
import React from "react"
import classNames from "classnames"
import { types } from "react-bricks/frontend"

interface HeroImageBoxProps {
    headline: TypographyElement[]
    spacerTop: boolean
    spacerBottom: boolean
}

const HeroImageBox: types.Brick<HeroImageBoxProps> = ({ spacerTop, spacerBottom }) => {
    return (
        <div
            className={classNames("sm:flex sm:items-center", {
                "sm:pt-16 lg:pt-28 xl:pt-40": spacerTop,
                "sm:pb-16 lg:pb-28 xl:pb-40": spacerBottom,
            })}
        >
            <div className="w-full sm:flex sm:justify-center">
                <div className="relative z-0 order-2 sm:-ml-15 sm:w-1/2 sm:max-w-[693px] md:-ml-28">
                    <ImageEditor aspectRatio={AspectRatio["5/4"]} maxWidth={693} className="w-full" propName="image" />
                </div>
                <div className="relative z-1 order-1 -mt-6 px-6.5 sm:mt-15 sm:w-[387px] sm:px-0 md:w-[522px] lg:mt-28 lg:w-[570px]">
                    <div className="bg-background-tide p-5 sm:p-10 lg:p-15">
                        <Color>
                            <Typography
                                allowList={["small", "sup", "link", "bold", "checkList", "h4"]}
                                propName="headline"
                                block="copytext-small"
                            />
                        </Color>
                    </div>
                </div>
            </div>
        </div>
    )
}

HeroImageBox.schema = {
    name: "HeroImageBox",
    label: "Hero Image Box",
    sideEditProps: [
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: (): HeroImageBoxProps => ({
        headline: [
            {
                type: "h4",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet",
                        bold: true,
                    },
                ],
            },
            {
                type: "checkList",
                children: [
                    {
                        type: "CheckList Item",
                        children: [
                            {
                                text: "Lorem ipsum dolor",
                            },
                        ],
                    },
                    {
                        type: "CheckList Item",
                        children: [
                            {
                                text: "Lorem ipsum dolor",
                            },
                        ],
                    },
                ],
            },
        ],
        spacerTop: true,
        spacerBottom: true,
    }),
}

export default HeroImageBox
