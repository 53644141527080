exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-app-settings-js": () => import("./../../../src/pages/admin/app-settings.js" /* webpackChunkName: "component---src-pages-admin-app-settings-js" */),
  "component---src-pages-admin-editor-js": () => import("./../../../src/pages/admin/editor.js" /* webpackChunkName: "component---src-pages-admin-editor-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-media-js": () => import("./../../../src/pages/admin/media.js" /* webpackChunkName: "component---src-pages-admin-media-js" */),
  "component---src-pages-admin-playground-js": () => import("./../../../src/pages/admin/playground.js" /* webpackChunkName: "component---src-pages-admin-playground-js" */),
  "component---src-pages-opt-out-exponea-js": () => import("./../../../src/pages/opt-out-exponea.js" /* webpackChunkName: "component---src-pages-opt-out-exponea-js" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-unsupported-browser-js": () => import("./../../../src/pages/unsupported-browser.js" /* webpackChunkName: "component---src-pages-unsupported-browser-js" */),
  "component---src-templates-abnehmen-stoffwechsel-js": () => import("./../../../src/templates/abnehmen-stoffwechsel.js" /* webpackChunkName: "component---src-templates-abnehmen-stoffwechsel-js" */),
  "component---src-templates-energie-js": () => import("./../../../src/templates/energie.js" /* webpackChunkName: "component---src-templates-energie-js" */),
  "component---src-templates-gesundheitswelt-js": () => import("./../../../src/templates/gesundheitswelt.js" /* webpackChunkName: "component---src-templates-gesundheitswelt-js" */),
  "component---src-templates-immunsystem-2-js": () => import("./../../../src/templates/immunsystem2.js" /* webpackChunkName: "component---src-templates-immunsystem-2-js" */),
  "component---src-templates-instagram-js": () => import("./../../../src/templates/instagram.js" /* webpackChunkName: "component---src-templates-instagram-js" */),
  "component---src-templates-lavitastories-js": () => import("./../../../src/templates/lavitastories.js" /* webpackChunkName: "component---src-templates-lavitastories-js" */),
  "component---src-templates-martina-leukert-js": () => import("./../../../src/templates/martina-leukert.js" /* webpackChunkName: "component---src-templates-martina-leukert-js" */),
  "component---src-templates-mikronaehrstoffkonzentrat-2-js": () => import("./../../../src/templates/mikronaehrstoffkonzentrat2.js" /* webpackChunkName: "component---src-templates-mikronaehrstoffkonzentrat-2-js" */),
  "component---src-templates-partnerprogramm-js": () => import("./../../../src/templates/partnerprogramm.js" /* webpackChunkName: "component---src-templates-partnerprogramm-js" */),
  "component---src-templates-subpages-bricks-tsx": () => import("./../../../src/templates/subpages/bricks.tsx" /* webpackChunkName: "component---src-templates-subpages-bricks-tsx" */),
  "component---src-templates-subpages-gesundheitswelt-js": () => import("./../../../src/templates/subpages/gesundheitswelt.js" /* webpackChunkName: "component---src-templates-subpages-gesundheitswelt-js" */),
  "component---src-templates-subpages-lavitastories-js": () => import("./../../../src/templates/subpages/lavitastories.js" /* webpackChunkName: "component---src-templates-subpages-lavitastories-js" */),
  "component---src-templates-subpages-umfrage-gut-js": () => import("./../../../src/templates/subpages/umfrage/gut.js" /* webpackChunkName: "component---src-templates-subpages-umfrage-gut-js" */),
  "component---src-templates-subpages-umfrage-okay-js": () => import("./../../../src/templates/subpages/umfrage/okay.js" /* webpackChunkName: "component---src-templates-subpages-umfrage-okay-js" */),
  "component---src-templates-subpages-umfrage-onboarding-einzelflasche-js": () => import("./../../../src/templates/subpages/umfrage/onboarding/einzelflasche.js" /* webpackChunkName: "component---src-templates-subpages-umfrage-onboarding-einzelflasche-js" */),
  "component---src-templates-subpages-umfrage-sehr-gut-js": () => import("./../../../src/templates/subpages/umfrage/sehr-gut.js" /* webpackChunkName: "component---src-templates-subpages-umfrage-sehr-gut-js" */),
  "component---src-templates-subpages-umfrage-weniger-gut-js": () => import("./../../../src/templates/subpages/umfrage/weniger-gut.js" /* webpackChunkName: "component---src-templates-subpages-umfrage-weniger-gut-js" */),
  "component---src-templates-subpages-wissen-gesundheit-js": () => import("./../../../src/templates/subpages/wissen-gesundheit.js" /* webpackChunkName: "component---src-templates-subpages-wissen-gesundheit-js" */)
}

