import Typography, { TypographyElement } from "../Typography"

import { Color } from "../components/typography"
import React from "react"
import { types } from "react-bricks/frontend"

export interface DecorativeTextProps {
    text: TypographyElement[]
    index?: number
}

const DecorativeText: types.Brick<DecorativeTextProps> = ({ index }) => {
    return (
        <div key={index}>
            <div className="inline-block rounded-base border border-solid border-border-tide p-5 sm:rounded-lg lg:p-7.5">
                <Color>
                    <Typography allowList={["big", "sup", "link"]} />
                </Color>
            </div>
        </div>
    )
}

DecorativeText.schema = {
    name: "DecorativeText",
    label: "Decorative Text",
    hideFromAddMenu: true,
    getDefaultProps: (): DecorativeTextProps => ({
        text: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ",
                    },
                    {
                        text: "ipsum",
                        big: true,
                    },
                    {
                        text: " dolor",
                    },
                ],
            },
        ],
    }),
}

export default DecorativeText
