const initialState = {
    showPopper: false,
    error: null,
}

const PopperReducer = (initialState, action) => {
    switch (action.type) {
        case "OPEN_POPPER":
            return {
                ...initialState,
                showPopper: true,
            }
        case "CLOSE_POPPER":
            return {
                ...initialState,
                showPopper: false,
            }
        case "ERROR":
            return {
                ...initialState,
                error: action.error,
            }

        default:
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}

export { PopperReducer, initialState }
