import React, { useRef } from "react"

import LoginForm from "../forms/login"
import OverlayStyles from "./overlay.module.css"
import ReAuthStyles from "./re-auth.module.css"
import classNames from "classnames"
import { useAuthState } from "../../context/auth"
import { useDimensions } from "../../hooks/use-dimensions"
import { useOnClickOutside } from "../../hooks/use-on-click-outside"
import { useTranslation } from "react-i18next"

const ReAuth = () => {
    const { t } = useTranslation("shared")
    const overlayRef = useRef(null)

    const { height, width } = useDimensions()

    useOnClickOutside(isInside => !isInside && onClose(), [overlayRef])

    const { loading } = useAuthState()

    const onSuccess = () => {
        window.dispatchEvent(
            new CustomEvent("token_too_old_for_include_critical", {
                detail: { success: true },
            })
        )
    }

    const onClose = () => {
        window.dispatchEvent(
            new CustomEvent("token_too_old_for_include_critical", {
                detail: { success: false },
            })
        )
    }

    /* magic Numbers from CSS mostly paddings from the parents */
    const maxHeight = height - (width >= 1366 ? 330 : width >= 992 ? 290 : 200)

    return (
        <div className={OverlayStyles.container} style={{ height: height || undefined, zIndex: 12 }} id="re-auth">
            <div className={OverlayStyles.overlay} ref={overlayRef}>
                <button className={OverlayStyles.close} type="button" onClick={() => onClose()}>
                    <i className="lavita-icon"></i>
                </button>
                <div className={OverlayStyles.content} style={{ maxHeight: maxHeight || undefined }}>
                    <section className={ReAuthStyles.block}>
                        <h4>{t("re_auth_headline")}</h4>
                        <p>{t("re_auth_paragraph")}</p>
                        <LoginForm onSuccess={onSuccess} />
                    </section>
                </div>
                <div className={classNames({ [ReAuthStyles.loading]: loading })}>
                    <div className={ReAuthStyles.spinner} />
                </div>
            </div>
        </div>
    )
}

export default ReAuth
