import React from "react"

interface BadgesProps {
    badge?: string
    secondaryBadge?: string
}

const Badges = ({ badge, secondaryBadge }: BadgesProps) => {
    return (
        <div className="absolute right-0 top-5 flex flex-col items-end lg:top-10">
            {badge && (
                <span className="max-w-[80px] whitespace-pre-wrap rounded-l-[5px] bg-orange p-[5px] py-2 text-right text-xs font-semibold text-white md:max-w-[90px] md:px-2.5 lg:max-w-[124px] lg:px-3 lg:text-base">
                    {badge}
                </span>
            )}
            {secondaryBadge && (
                <span className="mt-[5px] max-w-[120px] whitespace-pre-wrap rounded-l-[5px] bg-tide p-[5px] py-2 text-right text-xs font-semibold text-white first:mt-0 md:mt-2 md:max-w-[130px] md:px-2.5 lg:mt-2.5 lg:max-w-[160px] lg:px-3 lg:text-base">
                    {secondaryBadge}
                </span>
            )}
        </div>
    )
}

export default Badges
