import React from "react"
import { RenderElementProps } from "slate-react"

const Small = ({ children, attributes }: RenderElementProps) => {
    return (
        <small {...attributes} className="mt-5 block text-xs first:mt-0 sm:mt-7 lg:mt-10 lg:text-sm">
            {children}
        </small>
    )
}

export default Small
