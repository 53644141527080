import { Text, types } from "react-bricks/frontend"

import React from "react"
import classNames from "classnames"

export interface TableCellProps {
    cellText: string
    cellTextBold?: boolean
}

const TableCell: types.Brick<TableCellProps> = ({ cellTextBold }) => {
    return (
        <td className="whitespace-pre-wrap py-2.5 text-left first:pl-6.5 first:pr-6.5 last:pr-6.5 sm:py-5 sm:first:pl-5 sm:last:pr-5 md:first:pl-10 md:first:pr-10 md:last:pr-10 lg:first:pl-15 lg:first:pr-15 lg:last:pr-15 xl:first:pl-20 xl:first:pr-20 xl:last:pr-20">
            <Text
                propName="cellText"
                placeholder="Insert text"
                renderBlock={({ children }) => (
                    <span className={classNames({ "font-bold": cellTextBold })}>{children}</span>
                )}
            />
        </td>
    )
}

TableCell.schema = {
    name: "TableCell",
    label: "Cell",
    category: "Multilevel",
    hideFromAddMenu: true,
    sideEditProps: [
        {
            name: "cellTextBold",
            label: "Cell Text Bold",
            type: types.SideEditPropType.Boolean,
        },
    ],
}

export default TableCell
