import { A } from "../../utils/router"
import React from "react"
import TopLevelStyles from "./top-level.module.css"
import classNames from "classnames"
import { useAuthState } from "../../context/auth"
import { useScrollPosition } from "../../hooks/use-scroll-position"
import { useTranslation } from "react-i18next"

const TopLevel = props => {
    const { t } = useTranslation("shared")
    const { isPartner } = useAuthState()
    const { scrollDirection, scrollTop } = useScrollPosition()

    return (
        <section
            className={classNames(TopLevelStyles.block, {
                [TopLevelStyles.sticky]: scrollDirection === "up" || scrollTop <= 0,
            })}
        >
            <div className={TopLevelStyles.container}>
                {props.contact && (
                    <A href={`${process.env.WEB_URL}/kontakt`} className={TopLevelStyles.option}>
                        <i className="lavita-icon"></i>
                        <span>{t("top_level_contact")}</span>
                    </A>
                )}
                <div className={TopLevelStyles.option}>
                    <i className="lavita-icon"></i>
                    <span>
                        {props.customContact?.phone_title || t("top_level_consulting")}{" "}
                        <a
                            href={`tel:${
                                props.customContact
                                    ? props.customContact.phone_url
                                    : t(isPartner ? "top_level_consulting_partner_url" : "top_level_consulting_url")
                            }`}
                        >
                            {props.customContact
                                ? props.customContact.phone_number
                                : t(isPartner ? "top_level_consulting_partner_number" : "top_level_consulting_number")}
                        </a>
                    </span>
                </div>
            </div>
        </section>
    )
}

export default TopLevel
