import React, { ReactNode } from "react"
import ShadowCard, { CardBackgroundColor } from "../layout/shadow-card"

import Icon from "./icon"
import { Pictograms } from "../../../types/icons"
import classNames from "classnames"

interface IconShadowCardProps {
    children: ReactNode
    icon: Pictograms
    background?: CardBackgroundColor
}

const IconShadowCard = ({ icon, background, children }: IconShadowCardProps) => {
    return (
        <ShadowCard className="items-center space-y-4 sm:space-y-6 lg:space-y-7" background={background}>
            <Icon icon={icon} className={classNames({ "text-white": background === CardBackgroundColor.Green })} />
            {children}
        </ShadowCard>
    )
}

export default IconShadowCard
