import BigRadioStyles from "./big.module.css"
import React from "react"
import classNames from "classnames"

const BigRadio = props => (
    <div
        className={classNames(BigRadioStyles.block, {
            [BigRadioStyles.active]: props.active === props.id,
            [BigRadioStyles.disabled]: props.disabled,
        })}
    >
        {/* eslint-disable-next-line */}
        <input
            type="radio"
            name={props.name}
            required={typeof props.required === "boolean" ? props.required : true}
            id={props.id}
            value={props.id}
            checked={props.active === props.id}
            onChange={e => e.preventDefault()}
            onClick={props.onClick}
            disabled={props.disabled}
        />
        <label htmlFor={props.id} className={BigRadioStyles.label}>
            {props.block}
        </label>
    </div>
)

export default BigRadio
