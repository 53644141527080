import { Circle, CircleColor } from "../circle"
import React, { ReactNode } from "react"

interface TextCircleProps {
    children: ReactNode
    color: CircleColor
}

const TextCircle = ({ children, color }: TextCircleProps) => {
    return (
        <div className="relative">
            <div className="absolute -bottom-10 left-[-90px] w-[320px] rotate-[60deg] sm:bottom-auto sm:left-[-70px] sm:top-[-63px] sm:w-[283px] sm:rotate-0 md:left-[-85px] lg:left-[-165px] lg:top-[-172px] lg:w-[506px]">
                <Circle color={color} />
            </div>
            {children}
        </div>
    )
}

export default TextCircle
