import React from "react"

const germany = (
    <svg xmlns="http://www.w3.org/2000/svg" width="1200" height="600" viewBox="0 0 60 30">
        <rect id="black_stripe" width="60" height="30" y="0" x="0" fill="#000" />
        <rect id="red_stripe" width="60" height="20" y="10" x="0" fill="#D00" />
        <rect id="gold_stripe" width="60" height="10" y="20" x="0" fill="#FFCE00" />
    </svg>
)

const austria = (
    <svg xmlns="http://www.w3.org/2000/svg" width="900" height="600" viewBox="0 0 9 6">
        <rect fill="#ed2939" width="9" height="6" />
        <rect fill="#fff" y="2" width="9" height="2" />
    </svg>
)

const switzerland = (
    <svg width="512" height="512" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <path d="m0 0h32v32h-32z" fill="#f00" />
        <path d="m13 6h6v7h7v6h-7v7h-6v-7h-7v-6h7z" fill="#fff" />
    </svg>
)

export { germany, austria, switzerland }
