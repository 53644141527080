import { Color, TypographyColor } from "../components/typography"
import Image, { AspectRatio } from "../Image"
import { Repeater, types } from "react-bricks/frontend"
import Typography, { TypographyElement } from "../Typography"

import React from "react"
import classNames from "classnames"

export interface TabContentItemProps {
    index?: number
    activeTab?: number
    text: TypographyElement[]
    color?: TypographyColor
    mobileTextLeft: boolean
}

const TabContentItem: types.Brick<TabContentItemProps> = ({ index, activeTab, color, mobileTextLeft }) => {
    if (activeTab !== index) return null

    return (
        <div
            key={index}
            className="mx-auto grid grid-cols-1 gap-10 sm:max-w-[680px] sm:grid-cols-2 md:max-w-[784px] md:gap-16 lg:max-w-[1020px] lg:grid-cols-[460px_480px] lg:gap-20"
        >
            <div className="flex flex-col justify-center space-y-7 sm:order-2 md:space-y-10">
                <div
                    className={classNames("sm:text-left", {
                        "text-left": mobileTextLeft,
                        "text-center": !mobileTextLeft,
                    })}
                >
                    <Color color={color}>
                        <Typography
                            allowList={["h4", "h5", "ul", "small", "sup", "link", "bold", "italic", "checkList"]}
                        />
                    </Color>
                </div>
                <Repeater
                    propName="repeater"
                    renderWrapper={children => <div className="flex flex-col space-y-5 sm:space-y-7">{children}</div>}
                />
            </div>
            <Image maxWidth={687} aspectRatio={AspectRatio["4/5"]} className="rounded-base sm:order-1" />
        </div>
    )
}

TabContentItem.schema = {
    name: "TabContentItem",
    label: "Tab Content Item",
    hideFromAddMenu: true,
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "Button",
                    label: "Button",
                    max: 1,
                },
                {
                    type: "TextLink",
                    label: "Text Link",
                    max: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "mobileTextLeft",
            label: "Mobile Text Left",
            type: types.SideEditPropType.Boolean,
        },
    ],
    getDefaultProps: (): TabContentItemProps => ({
        text: [
            {
                type: "h4",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consetetur sadipscing.",
                        bold: true,
                    },
                ],
            },
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc placerat sagittis faucibus.",
                    },
                ],
            },
        ],
        mobileTextLeft: false,
    }),
}

export default TabContentItem
