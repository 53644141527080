import { Background, BackgroundColor, Container, backgroundOptions } from "../components/layout"
import { Repeater, types } from "react-bricks/frontend"

import { ConditionalWrapper } from "../components/util"
import React from "react"
import { StripeIconTextProps } from "./StripeIconText"
import classNames from "classnames"
import { twMerge } from "tailwind-merge"

interface StripeBrickProps {
    overlap: boolean
    background?: { color: BackgroundColor } | BackgroundColor
    repeater?: {
        id: string
        props: StripeIconTextProps
        type: "TrustStripeIconText"
    }[]
}

const StripeBrick: types.Brick<StripeBrickProps> = ({ overlap, background, repeater }) => {
    const bgColor = typeof background === "object" ? background.color : background

    return (
        <ConditionalWrapper
            condition={overlap}
            wrapper={children => <Background color={bgColor}>{children}</Background>}
        >
            <Container>
                <ConditionalWrapper
                    condition={overlap}
                    wrapper={children => (
                        <div className="relative pb-10 sm:pb-[68px] md:pb-[54px] lg:pb-[64px] xl:pb-[74px]">
                            <div className="z-2 flex items-center justify-center rounded-base bg-white shadow-base sm:absolute sm:w-full sm:-translate-y-[30px] sm:rounded-lg md:-translate-y-1/2 lg:shadow-lg">
                                {children}
                            </div>
                        </div>
                    )}
                >
                    <div
                        className={twMerge(
                            classNames(
                                "box-content grid grow grid-cols-1 gap-y-10 p-5 sm:justify-center sm:p-3.5 xl:p-6",
                                { "sm:grid-cols-3": repeater?.length === 3 },
                                { "sm:grid-cols-2": repeater?.length === 2 }
                            )
                        )}
                    >
                        <Repeater propName="repeater" />
                    </div>
                </ConditionalWrapper>
            </Container>
        </ConditionalWrapper>
    )
}

StripeBrick.schema = {
    name: "Stripe",
    label: "Stripe",
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "TrustStripeIconText",
                    label: "Icon Text",
                    max: 3,
                    min: 2,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "overlap",
            label: "Overlap",
            type: types.SideEditPropType.Boolean,
        },
        {
            name: "background",
            label: "Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: backgroundOptions,
                display: types.OptionsDisplay.Color,
            },
            show: props => props.overlap,
        },
    ],
    getDefaultProps: (): StripeBrickProps => ({
        overlap: false,
        background: { color: BackgroundColor.White },
    }),
}

export default StripeBrick
