import { A, Link } from "../../utils/router"

import IconActionStyles from "./icon-action.module.css"
import React from "react"
import classNames from "classnames"

const IconAction = React.forwardRef((props, ref) => {
    const {
        button,
        white,
        red,
        center,
        big,
        bigIcon,
        underline,
        hideTextMobile,
        hideText,
        fullWidth,
        disabled,
        notAllowed,
        variant,
        to,
        state,
        id,
        withOnClick,
        url,
        action,
        submit,
        icon,
        title,
        ...rest
    } = props

    const classes = classNames(IconActionStyles.block, {
        [IconActionStyles.button]: button,
        [IconActionStyles.white]: white,
        [IconActionStyles.red]: red,
        [IconActionStyles.center]: center,
        [IconActionStyles.big]: big,
        [IconActionStyles.bigIcon]: bigIcon,
        [IconActionStyles.underline]: underline,
        [IconActionStyles.hideTextMobile]: hideTextMobile,
        [IconActionStyles.hideText]: hideText,
        [IconActionStyles.fullWidth]: fullWidth,
        [IconActionStyles.disabled]: disabled,
        [IconActionStyles.notAllowed]: notAllowed,
        [variant]: variant,
    })

    return to ? (
        <Link to={to} state={state} id={id} className={classes} onClick={withOnClick} ref={ref} {...rest}>
            {icon && <i className="lavita-icon">{icon}</i>}
            <span>{title}</span>
        </Link>
    ) : url ? (
        <A href={url} state={state} id={id} className={classes} onClick={withOnClick} ref={ref} {...rest}>
            {icon && <i className="lavita-icon">{icon}</i>}
            <span>{title}</span>
        </A>
    ) : action ? (
        <button type="button" id={id} className={classes} onClick={action} ref={ref} {...rest}>
            {icon && <i className="lavita-icon">{icon}</i>}
            <span>{title}</span>
        </button>
    ) : submit ? (
        <button type="submit" id={id} className={classes} ref={ref} {...rest}>
            {icon && <i className="lavita-icon">{icon}</i>}
            <span>{title}</span>
        </button>
    ) : disabled ? (
        <div className={classes} ref={ref} {...rest}>
            {icon && <i className="lavita-icon">{icon}</i>}
            <span>{title}</span>
        </div>
    ) : null
})

export default IconAction
