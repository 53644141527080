import { Circle, CircleColor } from "../circle"
import React, { ReactNode } from "react"

import classNames from "classnames"

export enum PositionCircle {
    "Left" = "left",
    "Right" = "right",
}

interface ImageTextCircleProps {
    children: ReactNode
    color: CircleColor
    positionCircle: PositionCircle
}

const ImageTextCircle = ({ children, color, positionCircle }: ImageTextCircleProps) => {
    return (
        <div className="relative">
            <div
                className={classNames(
                    "absolute right-[-55px] top-[-58px] w-[250px] sm:-top-14 sm:bottom-auto sm:w-[253px] md:w-[280px] lg:top-[-175px] lg:w-[506px] xl:top-[-222px] xl:w-[602px]",
                    {
                        "sm:right-10 md:right-[-85px] lg:right-[-121px] xl:right-[-138px]":
                            positionCircle === PositionCircle.Right,
                        "sm:left-[-75px] md:left-[-85px] lg:left-[-121px] xl:left-[-138px]":
                            positionCircle === PositionCircle.Left,
                    }
                )}
            >
                <Circle color={color} />
            </div>
            {children}
        </div>
    )
}

export default ImageTextCircle
