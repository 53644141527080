import BiathlonFormStyles from "./biathlon.module.css"
import BigRadio from "../../../components/fields/radio/big"
import Checkbox from "../../../shared/components/fields/checkbox"
import FlexWrapper from "../../../shared/components/fields/flex-wrapper"
import IconAction from "../../../shared/components/universal/icon-action"
import Input from "../../../shared/components/fields/input"
import LinkButtonWrapper from "../universal/link-button-wrapper"
import Radio from "../../../shared/components/fields/radio"
import React from "react"
import ReactMarkdown from "react-markdown"
import { genders } from "../../../shared/config/genders"
import { toast } from "react-toastify"
import { useForm } from "react-hook-form"
import { useState } from "react"
import { useTranslation } from "react-i18next"

/* global exponea */

const BiathlonForm = () => {
    const { t } = useTranslation("translations")

    const [isSuccess, setIsSuccess] = useState(false)
    const [date, setDate] = useState()

    const {
        handleSubmit,
        control,
        reset,
        register,
        formState: { errors },
    } = useForm({
        mode: "onTouched",
        defaultValues: {
            gender: "",
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            checkbox: false,
        },
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
    })

    const submit = values => {
        if (!date) return toast.error(t("form_error_required_date"), { autoClose: 5000 })
        const wrapper = document.getElementById("wrapper")

        if (exponea.isLoaded) {
            exponea.track("biathlon_gewinnspiel", {
                action: "new",
                salutation: values.gender,
                firstname: values.firstname,
                lastname: values.lastname,
                email: values.email,
                phone: values.phone,
                date: date,
            })

            toast.success(t("advent_calender_form_success"), { autoClose: 5000 })
            reset()
            setDate()
            setIsSuccess(true)
            wrapper.scrollIntoView({ behavior: "smooth", block: "center" })
        } else {
            toast.error(t("advent_calender_form_error"), { autoClose: 5000 })
            setIsSuccess(false)
        }
    }

    const dateOptions = [
        {
            id: "Hochfilzen",
            label: t("biathlon_date_option_hochfilzen"),
            disabled: true,
        },
        {
            id: "Lenzerheide",
            label: t("biathlon_date_option_lenzerheide"),
            disabled: true,
        },
        {
            id: "Oberhof",
            label: t("biathlon_date_option_oberhof"),
            disabled: new Date() > new Date("2023-12-21T00:00:00+01:00"),
        },
        {
            id: "Ruhpolding",
            label: t("biathlon_date_option_ruhpolding"),
            disabled: new Date() > new Date("2023-12-28T00:00:00+01:00"),
        },
        {
            id: "Antholz",
            label: t("biathlon_date_option_antholz"),
            disabled: new Date() > new Date("2024-01-04T00:00:00+01:00"),
        },
    ]

    return (
        <div className={BiathlonFormStyles.wrapper} id="wrapper">
            {!isSuccess ? (
                <form onSubmit={handleSubmit(submit)} name="biathlon" className={BiathlonFormStyles.form}>
                    <h3>{t("biathlon_heading_contact")}</h3>
                    <Radio
                        register={register("gender", {
                            required: { value: true, message: t("form_error_required") },
                        })}
                        error={errors}
                        options={genders.map(g => ({
                            label: t(g.name),
                            value: g.id,
                        }))}
                        flex
                        label={t("biathlon_form_gender_label")}
                    />
                    <FlexWrapper flex={["2 1 auto", "2 1 auto"]} wrap>
                        <Input
                            control={control}
                            rules={{ required: { value: true, message: t("form_error_required") } }}
                            type="text"
                            name="firstname"
                            label={t("contact_form_firstname_label")}
                            autocomplete="given-name"
                        />
                        <Input
                            control={control}
                            rules={{ required: { value: true, message: t("form_error_required") } }}
                            type="text"
                            name="lastname"
                            label={t("contact_form_lastname_label")}
                            autocomplete="family-name"
                        />
                    </FlexWrapper>
                    <Input
                        control={control}
                        rules={{ required: { value: true, message: t("form_error_required") } }}
                        type="email"
                        name="email"
                        label={t("contact_form_email_label")}
                        autocomplete="email"
                    />
                    <Input
                        control={control}
                        rules={{ required: { value: true, message: t("form_error_required") } }}
                        type="tel"
                        name="phone"
                        label={t("biathlon_form_phone_label")}
                        autocomplete="tel"
                    />
                    <h3>{t("biathlon_heading_date")}</h3>
                    {dateOptions.map(d => (
                        <BigRadio
                            id={d.id}
                            name="date"
                            key={d.id}
                            active={date}
                            block={
                                <div className={BiathlonFormStyles.option}>
                                    <ReactMarkdown>{t(d.label)}</ReactMarkdown>
                                </div>
                            }
                            onClick={() => setDate(d.id)}
                            required={false}
                            disabled={d.disabled}
                        />
                    ))}
                    <div className={BiathlonFormStyles.checkbox}>
                        <Checkbox
                            register={register("checkbox", {
                                required: { value: true, message: t("form_error_required") },
                            })}
                            error={errors}
                            label={t("biathlon_form_label_checkbox")}
                        />
                    </div>
                    <LinkButtonWrapper center>
                        <IconAction title={t("biathlon_form_submit")} button submit />
                    </LinkButtonWrapper>
                </form>
            ) : (
                <div className={BiathlonFormStyles.success}>{t("advent_calender_form_success")}</div>
            )}
        </div>
    )
}

export default BiathlonForm
