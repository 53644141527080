import { eeAddToCart, eeRemovalFromCart } from "../../utils/analytics"

import { APIError } from "../../sdk/api"
import client from "../../utils/client"
import { useClientState } from "../../context/client"
import { useLocalesState } from "../../context/locales"
import { useMutationWithErrorHandling } from "../use-query-with-error-handling"
import { useQueryClient } from "@tanstack/react-query"
import { useQueryCustomer } from "../customer/use-query-customer"
import { useQueryLoading } from "../use-query-loading"

const useQueryCart = (queryOptions, loadingOptions) => {
    const queryClient = useQueryClient()
    const { cart_id, cart_access_token } = useClientState()

    const cartData = queryClient.getQueryData(["getCart", { id: cart_id }])

    return useQueryLoading(
        ["getCart", { id: cart_id }],
        () => client.getCart(cart_id, { access_token: cart_access_token }),
        {
            enabled: !!(cart_id && cart_access_token && !cartData?.products.filter(p => p.error).length),
            ...queryOptions,
        },
        loadingOptions
    )
}

const useQueryHistoricCart = queryOptions => {
    const { historic_cart_id, historic_cart_access_token } = useClientState()

    return useQueryLoading(
        ["getCart", { id: historic_cart_id }],
        () => client.getCart(historic_cart_id, { access_token: historic_cart_access_token }),
        {
            enabled: !!(historic_cart_id && historic_cart_access_token),
            ...queryOptions,
        }
    )
}

const useMutationCart = queryOptions => {
    const queryClient = useQueryClient()
    const { language, region } = useLocalesState()
    const { cart_id, cart_access_token } = useClientState()

    const { data: customerData } = useQueryCustomer()

    const { data: cartData } = useQueryCart()

    const bundleInCart = cartData?.meta.product_data.some(p => p.category_type === "bundle")
    const subscriptionInCart = cartData?.meta.product_data.some(p => p.category_type === "subscription")
    const giftInCart = cartData?.meta.product_data.some(p => p.category_type === "gift")

    const validateCart = category => {
        if (!category) return

        if (category === "bundle" && subscriptionInCart) {
            throw new APIError("illegal_combination", { code: "illegal_combination_bundle" })
        } else if (category === "subscription" && bundleInCart) {
            throw new APIError("illegal_combination", { code: "illegal_combination_subscription" })
        } else if (
            (category !== "gift" && giftInCart) ||
            (category === "gift" && !giftInCart && (cartData?.products.reduce((a, b) => a + b.quantity, 0) || 0) !== 0)
        ) {
            throw new APIError("illegal_combination", { code: "illegal_combination_gift" })
        }
    }

    return useMutationWithErrorHandling(
        body => {
            const category = body.category
            delete body.category
            if (body.product_id) {
                body.product_id = parseInt(body.product_id)
            }
            if (!cart_id || !cart_access_token)
                return client.createCart(
                    {
                        pricelist: customerData?.pricelist || parseInt(process.env.CUSTOMER_PRICELIST_ID),
                        country: region,
                        ...body,
                    },
                    {},
                    language
                )

            validateCart(category)

            return client.createCart(
                {
                    order_id: parseInt(cart_id),
                    access_token: cart_access_token,
                    ...body,
                },
                {},
                language
            )
        },
        {
            ...queryOptions,
            onSuccess: data => {
                const prevData = queryClient.getQueryData(["getCart", { id: client.config.cart_id }])
                data.products.forEach(p => {
                    const prevProduct = prevData?.products.find(pp => pp.id === p.id)
                    if (!prevProduct) return eeAddToCart(p, p.quantity, p.price.currency, data)

                    const quantityDif = p.quantity - prevProduct.quantity
                    if (quantityDif < 0) {
                        eeRemovalFromCart(p, -quantityDif, p.price.currency, data)
                    } else if (quantityDif > 0) {
                        eeAddToCart(p, quantityDif, p.price.currency, data)
                    }
                })
                if (!prevData) return
                prevData.products.forEach(pp => {
                    const product = data.products.find(p => p.id === pp.id)
                    if (product) return
                    eeRemovalFromCart(pp, pp.quantity, pp.price.currency, data)
                })
                queryClient.setQueryData(["getCart", { id: client.config.cart_id }], data)
            },
            retry: (failureCount, error) =>
                error.type !== "ValidationError" &&
                error.type !== "AccessError" &&
                error.message !== "illegal_combination" &&
                failureCount <= 2
                    ? true
                    : false,
            retryDelay: attempt => Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
        },
        ["mutateCart", { id: cart_id }]
    )
}

const usePostCartCoupon = () => {
    const queryClient = useQueryClient()
    const { cart_id, cart_access_token } = useClientState()

    return useMutationWithErrorHandling(
        body => {
            if (!cart_id) return client.createCartRedeem(body)

            return client.putCartRedeem(parseInt(cart_id), body, { access_token: cart_access_token })
        },
        {
            onSuccess: data => queryClient.setQueryData(["getCart", { id: client.config.cart_id }], data),
        }
    )
}

const useCheckCartCoupon = () => {
    return useMutationWithErrorHandling(body => {
        return client.checkCartRedeem(body)
    })
}

export { useQueryCart, useQueryHistoricCart, useMutationCart, usePostCartCoupon, useCheckCartCoupon }
