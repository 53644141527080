import React from "react"
import { RenderElementProps } from "slate-react"

const QuoteTextSmall = ({ children, attributes }: RenderElementProps) => {
    return (
        <p {...attributes} className="text-base md:text-md lg:text-lg">
            {children}
        </p>
    )
}

export default QuoteTextSmall
