import { Link } from "../../../shared/utils/router"
import React from "react"
import ReactMarkdown from "react-markdown"
import TextStyles from "./text.module.css"
import classNames from "classnames"
import rehypeRaw from "rehype-raw"
import rehypeSanitize from "rehype-sanitize"
import remarkGfm from "remark-gfm"
import { scrollTo } from "../../../shared/utils/smooth-scroll"

const Text = props => {
    const LinkRenderer = ({ href, children }) => {
        const anchor = (e, id) => {
            e.preventDefault()
            scrollTo(id)
        }
        if (props.actions && Object.keys(props.actions).find(key => key === href)) {
            return (
                <button type="button" onClick={props.actions[href]}>
                    {children}
                </button>
            )
        }
        if (href.indexOf("#") === 0) {
            return (
                <Link to={href} className={TextStyles.footnote} onClick={e => anchor(e, href.replace("#", ""))}>
                    {children}
                </Link>
            )
        } else if (href.indexOf(process.env.REPLACE_MENU_PART_WEB) === 0) {
            return <Link to={href.replace(process.env.REPLACE_MENU_PART_WEB, "")}>{children}</Link>
        } else {
            return <a href={href}>{children}</a>
        }
    }

    if (!props.source) return null

    const colorClasses = {}

    for (const size in props.color) {
        colorClasses[TextStyles[`${props.color[size]}${size[0].toUpperCase()}${size.slice(1)}`]] =
            props.color[size] !== "black"
    }

    const maxWidthClasses = {}

    for (const size in props.maxWidth) {
        maxWidthClasses[
            TextStyles[
                `maxWidth${props.maxWidth[size].replace("%", "percent")}${size[0].toUpperCase()}${size.slice(1)}`
            ]
        ] = !!props.maxWidth[size]
    }

    const alignClasses = {}

    for (const size in props.align) {
        alignClasses[TextStyles[`${props.align[size]}${size[0].toUpperCase()}${size.slice(1)}`]] =
            props.align[size] !== "left"
    }

    const regex = /<small>([^]*)<\/small>/g

    const smallprints = [...props.source.matchAll(regex)]

    return (
        <div
            className={classNames(TextStyles.text, {
                ...colorClasses,
                ...maxWidthClasses,
                ...alignClasses,
                [TextStyles.extraSmall]: props.extraSmall,
                [TextStyles.small]: props.small,
                [TextStyles.medium]: props.medium,
                [TextStyles.center]: props.center,
                [TextStyles.twoColumnList]: props.twoColumnList,
                [TextStyles.checkList]: props.checkList,
                [props.variant]: props.variant,
            })}
        >
            <ReactMarkdown
                rehypePlugins={[rehypeRaw, rehypeSanitize]}
                remarkPlugins={[remarkGfm]}
                components={{ a: ({ node, ...props }) => <LinkRenderer {...props} /> }}
            >
                {props.source.replace(regex, "")}
            </ReactMarkdown>
            {regex.test(props.source) &&
                smallprints.map((smallprint, i) => (
                    <small key={i} dangerouslySetInnerHTML={{ __html: smallprint[1] }} />
                ))}
        </div>
    )
}

Text.defaultProps = {
    color: {},
}

export default Text
