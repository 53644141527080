const colorOptions = <T>(t: T) => {
    if (typeof t !== "object" || t === null) return []

    return Object.keys(t).map(key => ({
        value: { color: t[key as keyof typeof t] },
        label: key,
    }))
}

const options = <T>(t: T) => {
    if (typeof t !== "object" || t === null) return []

    return Object.keys(t).map(key => ({
        value: t[key as keyof typeof t],
        label: key,
    }))
}

export { options, colorOptions }
