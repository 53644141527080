import React from "react"
import { RenderElementProps } from "slate-react"

const H3 = ({ children, attributes }: RenderElementProps) => {
    return (
        <h3 {...attributes} className="mb-5 text-3xl font-semibold last:mb-0 sm:text-4xl lg:mb-7 lg:text-6xl">
            {children}
        </h3>
    )
}

export default H3
