import Typography, { TypographyElement } from "../Typography"

import { Color } from "../components/typography"
import React from "react"
import { types } from "react-bricks/frontend"

interface TextProps {
    index?: number
    text: TypographyElement[]
}

const Text: types.Brick<TextProps> = ({ index }) => {
    return (
        <div key={index}>
            <Color>
                <Typography allowList={["h2", "h3", "h4", "ul", "small", "sup", "link", "bold", "checkList"]} />
            </Color>
        </div>
    )
}

Text.schema = {
    name: "RepeaterText",
    label: "Text",
    hideFromAddMenu: true,
    getDefaultProps: () => ({
        text: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc placerat sagittis faucibus.",
                    },
                ],
            },
        ],
    }),
}

export default Text
