import client from "../utils/client"
import { useEffect } from "react"
import { useLocalstorage } from "./use-localstorage"

/*
    All posible legacy-keys

    api_auth_tokens_consumer
    api_auth_tokens_customer_weak
    api_auth_tokens_customer_strong

    customer
    customer_id
    historic_customer_id
    customer_ref
    customer_access_token
    cart_id
    customer_name
    historic_cart_name
    historic_cart_id
    cart_name
    cart_access_token
    historic_cart_access_token
    cart_items
    email
    public
    upselling
    subscription_in_cart
    splitable_in_cart
    gift_in_cart
*/

const useMigrateLegacyStorage = (key, legacyKey) => {
    const [legacyStorage, setLegacyStorage] = useLocalstorage(legacyKey)

    useEffect(() => {
        if (!legacyStorage) return

        if (!client.config[key]) {
            client.config[key] = legacyStorage
        }

        setLegacyStorage(null)
    }, [key, legacyStorage, setLegacyStorage])
}

export { useMigrateLegacyStorage }
