import BreadcrumbsStyles from "./breadcrumbs.module.css"
import { Link } from "../../utils/router"
import React from "react"
import classNames from "classnames"

const Breadcrumbs = props => {
    if (!props.breadcrumbs) return null
    return (
        <div className={classNames(BreadcrumbsStyles.block, { [BreadcrumbsStyles.monochrome]: props.monochrome })}>
            {props.breadcrumbs.map((crumb, i) => (
                <div key={i} className={BreadcrumbsStyles.crumb}>
                    {crumb.link ? (
                        <>
                            <Link to={crumb.link} onClick={() => props.closePopper && props.closePopper()}>
                                {crumb.label}
                            </Link>
                            <i className="lavita-icon"></i>
                        </>
                    ) : (
                        <span>{crumb.label}</span>
                    )}
                </div>
            ))}
        </div>
    )
}

export default Breadcrumbs
