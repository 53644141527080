import "./base.css"

import React from "react"
import TextareaStyles from "./textarea.module.css"
import WrapWithError from "./wrap-with-error"
import classNames from "classnames"
import { useController } from "react-hook-form"
import { useTranslation } from "react-i18next"

const Textarea = ({
    name,
    control,
    rules,
    defaultValue,
    shouldUnregister,
    length,
    showLengthInfo,
    type,
    transform,
    placeholder,
    label,
    cols,
    rows,
    wrap,
    className,
}) => {
    const {
        field: { onChange, onBlur, value, ref, ...rest },
        fieldState: { error, isTouched },
    } = useController({
        name,
        control,
        rules,
        defaultValue,
        shouldUnregister,
    })

    const { t } = useTranslation("shared")

    const info =
        showLengthInfo && length && rows
            ? {
                  message:
                      t("remaining_chars", {
                          count:
                              length -
                              value
                                  .split("\n")
                                  .map((v, i) => (i < value.split("\n").length - 1 ? 45 : v.length))
                                  .reduce((a, b) => a + b, 0),
                      }) +
                      "; " +
                      t("remaining_lines", { count: rows - value.split("\n").length }),
              }
            : length
            ? {
                  message: t("remaining_chars", { count: length - value.length }),
              }
            : rows
            ? {
                  message: t("remaining_lines", { count: rows - value.split("\n").length }),
              }
            : null

    return (
        <div
            className={classNames(TextareaStyles.block, {
                error: error,
                wasInFocus: (isTouched && value) || value || placeholder,
                [className]: className,
            })}
        >
            <WrapWithError error={error} info={info}>
                <textarea
                    type={type}
                    name={name}
                    id={name}
                    value={value}
                    onBlur={onBlur}
                    onChange={e => onChange(transform ? transform.output(e.target.value) : e)}
                    className="input"
                    ref={ref}
                    maxLength={length + (rows ? rows - 1 : 0)}
                    cols={cols}
                    rows={rows}
                    wrap={wrap}
                    placeholder={placeholder}
                    {...rest}
                />
                <label htmlFor={name} className={"label"}>
                    {label}
                </label>
            </WrapWithError>
        </div>
    )
}

export default Textarea
